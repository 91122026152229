
    import { defineComponent } from 'vue';
    import { purchaseGroupService, PurchaseGroupContract } from '@/services/PurchaseGroupService';
    import { customerContractService, CustomerContract } from '@/services/ContractService';
    import { Lookup, TreeNode } from '@/services/Types';
    import PurchaseGroupContractItem from '@/components/PurchaseGroups/PurchaseGroupContractItem.vue';
    import { siteService, Site } from '@/services/SiteService';

    interface Data {
        loading: boolean,
        sites: Site[],
        purchaseGroupContracts: PurchaseGroupContract[],
        purchaseGroupContractIds: number[],
        customerContracts: CustomerContract[],
        statuses: Lookup[],
        suppliers: Lookup[] | null,
        newPurchaseGroupContract: PurchaseGroupContract | null,
        selectedPurchaseGroupContract: any,
        addCustomerContractDialog: boolean,
        deleteCustomerContractDialog: boolean,
    }

    export default defineComponent({
        props: {
            purchaseGroupId: {
                type: Number,
                required: true
            },
        },
        data(): Data {
            return {
                loading: true,
                sites: [],
                purchaseGroupContracts: [],
                purchaseGroupContractIds: [],
                customerContracts: [],
                statuses: [],
                suppliers: [],
                newPurchaseGroupContract: null,
                selectedPurchaseGroupContract: null,
                addCustomerContractDialog: false,
                deleteCustomerContractDialog: false,
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        components: {
            PurchaseGroupContractItem
        },
        //watch: {
        //    // call again the method if the route changes
        //    '$route': 'fetchData' //todo: what does this do?
        //},
        methods: {
            async fetchData() {

                console.log("fetch data...")

                this.purchaseGroupContracts = await purchaseGroupService.getPurchaseGroupContracts(this.purchaseGroupId);
                this.customerContracts = await this.getCustomerContractsFromPurchaseGroups(this.purchaseGroupContracts);

                this.loading = false;
                
            },
            async getCustomerContractsFromPurchaseGroups(pgcs: PurchaseGroupContract[]) {
                this.purchaseGroupContractIds = pgcs.map(pgc => pgc.customerContractId.valueOf());

                this.sites = await siteService.getSites();
                this.customerContracts = await customerContractService.getSelectedCustomerContracts(this.purchaseGroupContractIds);
                return this.customerContracts.map(cc => (
                    {
                        ...cc,
                        supplyPoints: cc.supplyPoints.map(sp => (
                            {
                                ...sp,
                                siteName: this.sites.find(s => s.id == sp.siteId)?.name
                            }
                        ))
                    }
                ));
            },
            getPurchaseGroupContractFromCustomerContractId(id: number) {
                var filtered = this.purchaseGroupContracts.find(pgc => pgc.customerContractId.valueOf() == id);
                return filtered;
            },
            onRowSelect(node: TreeNode) {
                this.$router.push({ name: 'editContract', params: { customerId: node.data.customerId, contractId: node.data.id } })
            },
            addNewCustomerContract() {
                console.log("add new customer contract!");

                this.newPurchaseGroupContract = {
                    id: 0,
                    purchaseGroupId: this.purchaseGroupId,
                    customerContractId: 0,
                    customerId: 0,
                };

                this.addCustomerContractDialog = true;
            },
            async onAddPurchaseGroupContract(addCustomerContractEvent: any) {

                await purchaseGroupService.addPurchaseGroupContract(addCustomerContractEvent);

                this.$emit('add:purchaseGroupContract', addCustomerContractEvent);

                console.log("save successful!")

                // now refresh main list of purchase groups...
                this.fetchData();

                this.addCustomerContractDialog = false;
            },
            editCustomerContract(contract: CustomerContract) {
                this.selectedPurchaseGroupContract = this.getPurchaseGroupContractFromCustomerContractId(contract.id);
                this.$router.push({ name: 'editPurchaseGroupContract', params: { purchaseGroupContractId: this.selectedPurchaseGroupContract.id.valueOf() } })
            },
            confirmDeleteCustomerContract(contract: CustomerContract) {
                this.selectedPurchaseGroupContract = this.getPurchaseGroupContractFromCustomerContractId(contract.id);
                this.deleteCustomerContractDialog = true;
            },
            async deleteCustomerContract() {
                if (this.selectedPurchaseGroupContract) {
                    await purchaseGroupService.deletePurchaseGroupContract(this.selectedPurchaseGroupContract.id);

                    this.purchaseGroupContracts = this.purchaseGroupContracts.filter(pgc => pgc.id !== this.selectedPurchaseGroupContract.id);
                    this.customerContracts = await this.getCustomerContractsFromPurchaseGroups(this.purchaseGroupContracts);
                }
                this.deleteCustomerContractDialog = false;
                this.selectedPurchaseGroupContract = null;
            },
        }
    });
