
   
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: {
            purchaseGroupId: {
                type: Number,
                required: true
            },
        },
        data() {
            return {
                items: [
                    { label: 'Details', to: { name: 'editPurchaseGroup', params: { purchaseGroupId: this.purchaseGroupId }}},
                    { label: 'Volumes', to: { name: 'tradeableVolumes', params: { purchaseGroupId: this.purchaseGroupId }}},
                //    { label: 'Triggers', to: { name: 'triggers', params: { purchaseGroupId: this.purchaseGroupId }}},
                //    { label: 'Strategies', to: { name: 'strategies', params: { purchaseGroupId: this.purchaseGroupId }}},
                    { label: 'Trades', to: { name: 'tradeHistory', params: { purchaseGroupId: this.purchaseGroupId }}}
                ]
            }
        },      
        created() {
        }
    });
