
    import { defineComponent } from 'vue';
    import { siteService, Site } from '@/services/SiteService';

    import SiteItem from '@/components/Sites/SiteItem.vue';
    import SiteSupplyPoints from '@/components/Sites/SiteSupplyPoints.vue';
    import ClimateChangeAgreement from '@/components/Sites/ClimateChangeAgreement.vue';
    import EnergyIntensiveIndustry from '@/components/Sites/EnergyIntensiveIndustry.vue';
    import SiteContacts from '@/components/Sites/SiteContacts.vue';
    

    interface Data {
        site: Site | null,
        selectedSiteContactIds: number[],
        isSaving: boolean,
    }

    export default defineComponent({

        props: {
            customerId: {
                type: Number,
                required: true
            },
            siteId: {
                type: Number,
                required: true
            }
        },
        components: {
            SiteItem,
            SiteSupplyPoints,
            ClimateChangeAgreement,
            EnergyIntensiveIndustry,
            SiteContacts
        },
        data(): Data {

            return {
                site: null,
                selectedSiteContactIds: [],
                isSaving: false,
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {
                this.site = await siteService.getSite(this.siteId);
                var siteContacts = await siteService.getSiteContactsById(this.siteId);
                this.selectedSiteContactIds = siteContacts.map(sc => sc.id);
            },
            onUpdateSiteContacts(event: number[]) {
                this.selectedSiteContactIds = event;
            },
            async onSubmit(event: any) {               

                console.log(this.site);

                this.isSaving = true;

                const updateSite = {
                    ...event,
                    selectedSiteContactIds: this.selectedSiteContactIds,
                };

                console.log(updateSite);

                await siteService.updateSite(updateSite);
                    
                this.$emit('update:site', updateSite);

                // refresh docs etc
                await this.fetchData();

                this.isSaving = false;

                console.log("save successful!")
            },
        }
    });
