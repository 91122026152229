
    import { Hub } from 'aws-amplify'
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'App',
        components: {
        },
        data() {
            return {
            }
        },
        beforeCreate() {

            Hub.listen('auth', data => {
                console.log('data:', data)
                const { payload } = data
                if (payload.event === 'signIn') {
                    this.$router.push('/')
                }
                if (payload.event === 'signOut') {
                    this.$router.push('/auth')
                }
            })
        }
    });
