
    import { defineComponent, PropType } from 'vue';
    import { triggerService, Trigger } from '@/services/TriggerService';
    import { periodService } from '@/services/PeriodService';
    import { Lookup } from '@/services/Types';
    import { FilterMatchMode } from 'primevue/api';
    import MultiSelect from 'primevue/multiselect';
    import TriggerItem from '@/components/Settings/Triggers/TriggerItem.vue';

    interface Data {
        loading: boolean,
        triggerListFilters: {},
        newTrigger: Trigger | null,
        addTriggerDialog: boolean,
        commodityTypes: Lookup[],
        triggerTypes: Lookup[],
        periods:Lookup[],
        deleteTriggerDialog: boolean,
        deleting: boolean,

    }

    export default defineComponent({
        props: {
            triggers: {
                type: Array as PropType<Array<Trigger>>,
                required: true
            }
        },
        emits: [
            "add:trigger",
            "delete:trigger",
        ],
        data(): Data {
            return {
                loading: true,
                newTrigger: null,
                addTriggerDialog: false,
                deleteTriggerDialog: false,
                deleting: false,
                commodityTypes: [],
                triggerTypes: [],
                periods:[],
                triggerListFilters: {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    //'supplierId': { value: null, matchMode: FilterMatchMode.IN },
                    //'utilityTypeId': { value: null, matchMode: FilterMatchMode.IN },
                    //'contractTypeId': { value: null, matchMode: FilterMatchMode.IN },
                },
            };
        },
        components: {
            TriggerItem,
            MultiSelect
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        computed: {
        },
        methods: {
            async fetchData() {
                this.commodityTypes = await triggerService.getCommodityTypes();
                this.triggerTypes = await triggerService.getTriggerTypes();
                this.periods = await periodService.getCurrentAndFuturePeriods();
                this.loading = false;

            },
            onRowSelect(event: any) {
                console.log(event);
                this.$router.push({ name: 'editTrigger', params: { triggerId: event.data.id } })
            },
            addNewTrigger() {
                this.newTrigger = {
                    id: 0,
                    name: "",
                    customer: "",
                    commodityTypeId: 1,
                    Value: 0,
                    TriggerTypeId: 1,
                };

                this.addTriggerDialog = true;
            },
            async onAddTrigger(addTriggerEvent: any) {
                console.log("Creating TRIGGER...");
                await triggerService.addTrigger(addTriggerEvent)

                this.$emit('add:trigger', addTriggerEvent);

                console.log("created successfully!");

                this.addTriggerDialog = false;
            },
            confirmDeleteTrigger(trigger: Trigger) {
                this.newTrigger = trigger;
                this.deleteTriggerDialog = true;
            },
            async deleteTrigger() {
                this.deleting = true;
                if (this.newTrigger) {
                    await triggerService.deleteTrigger(this.newTrigger.id);

                    this.$emit('delete:trigger');
                }
                this.deleting = false;
                this.deleteTriggerDialog = false;
                this.newTrigger = null;
            },
            lookupcommodityType(commodityId: number) {
                let commodity = this.commodityTypes.find(ct => ct.id == commodityId);
                if (commodity == undefined) return "unknown";
                else return commodity.name;
            },
            lookupTriggerType(triggerTypeId: number) {
                let trigger = this.triggerTypes.find(ct => ct.id == triggerTypeId);
                if (trigger == undefined) return "unknown";
                else return trigger.name;
            },
            lookupPeriod(periodId: number) {
                let period = this.periods.find(ct => ct.id == periodId);
                if (period == undefined) return "unknown";
                else return period.name;
            }
        }
    });
