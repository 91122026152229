
    import { defineComponent, PropType } from 'vue';
    import { Lookup } from '@/services/Types';
    import { User, userService } from '@/services/UserService';
    //    import { Customer, customerService } from '@/services/CustomerService';
    import { Period } from '@/services/PeriodService';
    import { Trade, tradeService } from '@/services/TradeService';
    import { formatDate } from '@/composables/UtilityFunctions';
    import { FilterMatchMode, FilterOperator } from 'primevue/api';
    import Badge from 'primevue/badge';
    import MultiSelect from 'primevue/multiselect';
    import { CustomFilterMatchMode } from '@/composables/Filters';
    //    import { PurchaseGroup } from '@/services/PurchaseGroupService';
    import TradeAcceptance from '@/components/Trading/PreviousTrading/TradeAcceptance.vue';
    import {VolumeService} from "../../../services/VolumeService";

    interface Data {
        loading: boolean,
        statuses: Lookup[],
        //customers: Customer[],
        users: User[],
        uniquePeriods: Period[],
        tradingListFilters: {},
        selectedTrade: Trade | null,
        showTradeAcceptanceDialog: boolean,
        buySellOptions: Lookup[],
    }

    export default defineComponent({
        props: {
            trades: {
                type: Array as PropType<Array<Trade>>,
                required: true
            },
            commodityTypeId: {
                type: Number,
                required: true
            },
        },
        components: {
            MultiSelect,
            TradeAcceptance,
            Badge
        },
        data(): Data {
            return {
                loading: true,
                statuses: [],
                //customers: [],
                users: [],
                uniquePeriods: [],
                //users: [],
                tradingListFilters: {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'purchaseGroups': { value: null, matchMode: CustomFilterMatchMode.ANY_ID },
                    'periods': { value: null, matchMode: CustomFilterMatchMode.ANY_ID },
                    //'customers': { value: null, matchMode: CustomFilterMatchMode.ANY_ID },
                    'tradeDateTime': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                    'supplierOfferPrice': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'totalTradeVolume': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'user': { value: null, matchMode: FilterMatchMode.IN },
                    'status': { value: null, matchMode: FilterMatchMode.IN },
                },
                buySellOptions: [
                    { id: 1, name: "Buy" },
                    { id: 2, name: "Sell" }
                ],
                selectedTrade: null,
                showTradeAcceptanceDialog: false,
            };
        },
        created() {
            this.fetchData();
        },
        watch: {
            trades(newTrades: Trade[]) {
                //Populate unique periods from trades for filtering purposes
                newTrades.forEach(t => {
                    t.periods.forEach(tp => {
                        if (this.uniquePeriods.findIndex(p => tp.id == p.id) == -1) {
                            this.uniquePeriods.push(tp);
                        }
                    })
                });
                return newTrades;
            }
        },
        methods: {
            async fetchData() {

                this.users = await userService.getUsers();
                //this.customers = await customerService.getCustomers(); // gets customers with contracts
                this.statuses = await tradeService.getTradeStatuses();


                this.loading = false;

            },
            async onRowSelect(event: any) {
                console.log(event);

                this.selectedTrade = event.data;
                this.showTradeAcceptanceDialog = true;
            },
            onTradeStatusChange() {

                this.showTradeAcceptanceDialog = false;


                this.$emit('update:trade', this.selectedTrade);
            },
            formatDate(dateToFormat: string) {
                return formatDate(dateToFormat, 'DD/MM/YYYY HH:mm');
            },
            formatCurrency(value: number) {
                return value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });
            },
            //    filterCustomersArray(purchaseGroups: PurchaseGroup[]) {

            //        if (purchaseGroups.length == 0) {
            //            return [];
            //        }

            //        // get any customers which have a pg contract
            //        const pgContractIds = purchaseGroups.flatMap(pg => pg.contracts.map(c => c.customerContractId));
            //        const filteredCustomers = this.customers.filter(c => pgContractIds.includes(c.id));

            //        return filteredCustomers;
            //    },
        },
        computed: {
          VolumeService() {
            return VolumeService
          },
            tradesWithInfo() {

                const tradesWithInfo = this.trades ? this.trades.map(t => ({

                    ...t,
                    status: this.statuses.find(s => s.id === t.tradeStatusId),
                    user: this.users.find(u => u.userId === t.creatorUserId),
                    //customers: this.filterCustomersArray(t.purchaseGroups),
                    purchaseGroupNames: t.purchaseGroups.map(pg => pg.name).join(', '),
                    tradeDateTime: new Date(t.tradeDateTime ?? "")
                })) : [];

                return tradesWithInfo;
            },
            selectedTradeWithInfo(): Trade | null {

                const tradeWithInfo = this.selectedTrade ? {
                    ...this.selectedTrade,
                    status: this.statuses.find(s => s.id === this.selectedTrade?.tradeStatusId),
                    tradeDateTime: new Date(this.selectedTrade?.tradeDateTime ?? ""),
                    buySell: (this.selectedTrade?.totalTradeVolume ?? 0) > 0 ? this.buySellOptions[0] : this.buySellOptions[1]
                } : null;

                return tradeWithInfo;
            },
            priceHeader() {
                return this.commodityTypeId === 1 ? 'Price (p/therm)' : 'Price (&pound;/MWh)';
            },
            volumeHeader() {
                return this.commodityTypeId === 1 ? 'Volume (therm/day)' : 'Volume (MW)';
            },
        }
    });
