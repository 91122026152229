import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "mb-8 mt-8" }
const _hoisted_3 = { class: "grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SiteItem = _resolveComponent("SiteItem")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ClimateChangeAgreement = _resolveComponent("ClimateChangeAgreement")!
  const _component_EnergyIntensiveIndustry = _resolveComponent("EnergyIntensiveIndustry")!
  const _component_SiteContacts = _resolveComponent("SiteContacts")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_SiteSupplyPoints = _resolveComponent("SiteSupplyPoints")!

  return (_ctx.site)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SiteItem, {
          editSite: _ctx.site,
          "onSubmit:site": _ctx.onSubmit
        }, null, 8, ["editSite", "onSubmit:site"]),
        _createVNode(_component_Button, {
          type: "submit",
          form: "site-form",
          icon: "pi pi-save",
          label: "Save",
          class: "p-button-lg",
          disabled: _ctx.isSaving
        }, null, 8, ["disabled"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.siteId > 0)
              ? (_openBlock(), _createBlock(_component_ClimateChangeAgreement, {
                  key: 0,
                  siteId: _ctx.siteId
                }, null, 8, ["siteId"]))
              : _createCommentVNode("", true),
            (_ctx.siteId > 0)
              ? (_openBlock(), _createBlock(_component_EnergyIntensiveIndustry, {
                  key: 1,
                  siteId: _ctx.siteId
                }, null, 8, ["siteId"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createVNode(_component_SiteContacts, {
          customerId: _ctx.customerId,
          siteId: _ctx.siteId,
          "onUpdate:siteContacts": _ctx.onUpdateSiteContacts,
          class: "mb-3"
        }, null, 8, ["customerId", "siteId", "onUpdate:siteContacts"]),
        _createVNode(_component_Divider),
        _createVNode(_component_SiteSupplyPoints, {
          customerId: _ctx.customerId,
          siteId: _ctx.siteId
        }, null, 8, ["customerId", "siteId"])
      ]))
    : _createCommentVNode("", true)
}