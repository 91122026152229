import { useCRMApi } from '@/composables/CRMApi'
import { Lookup, Document } from './Types';
import { getFormData } from '@/composables/UtilityFunctions'

export interface ConsumptionImport {
    id: number,
    name: string,
    importedById?: string,
    utilityTypeId: number,
    statusId?: number,
    errorMessage?: string,
    advice?: string,
    rawFile?: Document,
    creationTime: Date | null,
}

export interface ConsumptionReading {
    id: number,
    consumptionImportId?: number,
    mpanCore: string,
    utilityTypeId: number,
    readTime?: Date | null,
    duration?: number,
    usage?: number,
}

export interface ConsumptionReadLite {
    readTime: Date,
    usage: number,
}



export default class ConsumptionService {
   
    async getImports() {

        const data = await useCRMApi(`/crm/consumption/imports`) as ConsumptionImport[];
        return data ?? [];
    }

    //async getProductById(id: Number) {

    //    const data = await useCRMApi(`/crm/products/${id}`) as Product;
    //    return data ?? null;
    //}

    async getHHConsumptionByMeterRef(meterRef: string | null | undefined) {

        const data = await useCRMApi(`/crm/supplypoints/halfhourly/consumption/${meterRef}`) as ConsumptionReadLite[];
        return data ?? null;
    }

    async getNHHConsumptionByMeterRef(meterRef: string | null | undefined) {

        const data = await useCRMApi(`/crm/supplypoints/halfhourly/consumption/${meterRef}`) as ConsumptionReadLite[];
        return data ?? null;
    }

    async getGasConsumptionByMeterRef(meterRef: string | null | undefined) {

        const data = await useCRMApi(`/crm/supplypoints/halfhourly/consumption/${meterRef}`) as ConsumptionReadLite[];
        return data ?? null;
    }

    //async getProductChargeTypes() {

    //    const data = await useCRMApi(`/crm/productchargetypes`) as Lookup[];
    //    return data ?? [];
    //}

    //async getSuppliers() {

    //    const data = await useCRMApi('/crm/suppliers') as Lookup[];
    //    return data ?? [];
    //}

    //async getProfileClasses() {

    //    const data = await useCRMApi(`/crm/profileclasses`) as Lookup[];
    //    return data ?? [];
    //}

    //async getContractTypes() {

    //    const data = await useCRMApi(`/crm/contracttypes`) as Lookup[];
    //    return data ?? [];
    //}

    //async getUtilityTypes() {

    //    const data = await useCRMApi(`/crm/utilitytypes`) as Lookup[];
    //    return data ?? [];
    //}

    async addImport(consumptionImport: any | null) {

        const formData = getFormData(consumptionImport);

        const newOptions = {
            method: 'POST',
            //headers: { 'Content-Type': 'application/json' },
            body: formData //JSON.stringify(consumptionImport)
        };

        const data = await useCRMApi(`/crm/consumption/import`, newOptions) as ConsumptionImport;
        return data ?? null;
    }

    async addBulkReading(bulkRead: ConsumptionReading[]) {
        //const readings = bulkRead.map(({ supplyPointId, readTime, duration }) => ({ supplyPointId, readTime, duration }));
        //const formData = getFormData({ schedule: addSchedule });
        const payload = { bulkReading: bulkRead };
        console.log(payload);

        const newOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };

        const data = await useCRMApi(`/crm/consumption/readings`, newOptions);
        return data ?? [];
    }

    async deleteImport(id: Number) {
        const newOptions = {
            method: 'DELETE'
        };

        const data = await useCRMApi(`/crm/consumption/import/${id}`, newOptions);
        return data ?? null;
    }

    async updateImport(consumptionImport: any | null) {

        const formData = getFormData(consumptionImport);

        const newOptions = {
            method: 'PUT',
            //headers: { 'Content-Type': 'application/json' },
            body: formData //JSON.stringify(consumptionImport)
        };

        const data = await useCRMApi(`/crm/consumption/import`, newOptions);
        return data ?? null;
    }

}


export const consumptionService = new ConsumptionService();


