import { useCRMApi } from '@/composables/CRMApi'
import { Lookup } from './Types';

export interface Product {
    id: number,
    name: string,
    utilityTypeId: number,
    supplierId: number,
    profileClassId?: number,
    contractTypeId?: number,
    isShapeFee: boolean,
    shapeFeeTypeId?: number,
    isNCC: boolean,
    nccTypeId?: number,
    isCapacityCharge: boolean,
    isDailyStandingCharge: boolean,
    deliveryWindow: number,
}



export default class ProductService {
   
    async getProducts() {

        const data = await useCRMApi(`/crm/products`) as Product[];
        return data ?? [];
    }

    async getProductById(id: Number) {

        const data = await useCRMApi(`/crm/products/${id}`) as Product;
        return data ?? null;
    }

    async getProductsBySupplierId(id: Number) {

        const data = await useCRMApi(`/crm/products/supplier/${id}`) as Product[];
        return data ?? null;
    }

    async getProductChargeTypes() {

        const data = await useCRMApi(`/crm/productchargetypes`) as Lookup[];
        return data ?? [];
    }

    async getSuppliers() {

        const data = await useCRMApi('/crm/suppliers') as Lookup[];
        return data ?? [];
    }

    async getProfileClasses() {

        const data = await useCRMApi(`/crm/profileclasses`) as Lookup[];
        return data ?? [];
    }

    async getContractTypes() {

        const data = await useCRMApi(`/crm/contracttypes`) as Lookup[];
        return data ?? [];
    }

    async getUtilityTypes() {

        const data = await useCRMApi(`/crm/utilitytypes`) as Lookup[];
        return data ?? [];
    }

    async addProduct(product: any | null) {

        const newOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(product)
        };

        const data = await useCRMApi(`/crm/products/`, newOptions);
        return data ?? null;
    }

    async updateProduct(product: any | null) {

        const newOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(product)
        };

        const data = await useCRMApi(`/crm/products/`, newOptions);
        return data ?? null;
    }

}


export const productService = new ProductService();


