
    import { defineComponent, PropType } from 'vue';
    import { Site } from '@/services/SiteService';

    interface Data {
        loading: boolean,
    }

    export default defineComponent({
        props: {
            sites: {
                type: Array as PropType<Array<Site>>,
                required: true
            }
        },
        data(): Data {
            return {
                loading: false,
            };
        },
        components: {
        },
        created() {
            // fetch on init
        },
        methods: {
            onRowSelect(event: any) {

                this.$router.push({ name: 'editSite', params: { siteId: event.data.id } })
            },
        }
    });
