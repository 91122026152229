
    import { defineComponent, PropType } from 'vue';
    import * as yup from "yup";
    import { Form, Field } from 'vee-validate';
    import { formatDate } from '@/composables/UtilityFunctions';

    import { customerContractService, CustomerContract, DeliveryWindow } from '@/services/ContractService';

    interface Data {
        loading: boolean,
        validationSchema: any,
        contract: CustomerContract,
        schedule: DeliveryWindow[],
}

    export default defineComponent({

        props: {
            editContract: {
                type: Object as PropType<CustomerContract>,
                required: true
            },
            editMode: Boolean,
            isActiveStep: Boolean,
            saveMe: {
                type: Boolean,
                required: false
            },
        },
        components: {
            Form,
            Field,
            //ErrorMessage,
        },
        data(): Data {

            const validationSchema = yup.object({
                customerContractId: yup.number().required(),
            })
                .typeError("Please select ${value} and ${type}");

            return {
                loading: true,
                validationSchema: validationSchema,
                contract: this.editContract,
                schedule: this.editContract.schedule,
            };
        },
        created() {
            // fetch on init
            //this.fetchData();
        },
        watch: {          
            editContract(newValue) {
                if (this.isActiveStep) {
                    console.log("contract changed");
                    this.contract = newValue;
                    this.fetchData();
                }
            },
            saveMe(shouldSave) {
                //Forces save if in edit mode
                if (this.editMode && shouldSave) {
                    this.onSubmit(true);
                }
            },
        },
        methods: {
            async fetchData() {

                if (this.contract.id > 0) this.schedule = await customerContractService.getScheduleByContractId(this.contract.id);
                
                this.loading = false;

                console.log("contract schedule details loaded");
            },
            async onSubmit(values: any) {
                console.log("Submitting:", values);

                const editedContract = {
                    ...this.contract,
                    schedule: this.schedule,
                }

                console.log("Contract:", editedContract);

                editedContract.schedule = await customerContractService.updateContractSchedule(this.schedule);

                this.$emit('submit:contract', editedContract);
            },
            formatDate(dateToFormat: string) {
                return formatDate(dateToFormat);
            },
            onCellEditComplete(event: any) {
                let { data, newValue, field } = event;

                switch (field) {
                    case 'shapeFee':
                        data[field] = newValue;
                        break;

                    default:
                        if (newValue.trim().length > 0) data[field] = newValue;
                        else event.preventDefault();
                        break;
                }
            },
        }
    });
