
    import { defineComponent, PropType } from 'vue';
    import { Lookup } from '@/services/Types';
    import { User } from '@/services/UserService';
//    import { Customer, customerService } from '@/services/CustomerService';
//    import { Period } from '@/services/PeriodService';
    import { Trade, TradeReportData } from '@/services/TradeService';
    import { formatDate } from '@/composables/UtilityFunctions';
    import { FilterMatchMode } from 'primevue/api';    
    import MultiSelect from 'primevue/multiselect';   
    import { PurchaseGroup, purchaseGroupService } from '@/services/PurchaseGroupService';
  
    interface Data {
        loading: boolean,
        statuses: Lookup[],
        purchaseGroups: PurchaseGroup[],
        //periods: string[],
        users: User[],
        uniquePeriods: string[],
        tradingListFilters: {},
        selectedTrade: Trade | null,
        showTradeAcceptanceDialog: boolean,
        buySellOptions: Lookup[],
    }

    export default defineComponent({
        props: {
            trades: {
                type: Array as PropType<Array<TradeReportData>>,
                required: true
            },
            commodityTypeId: {
                type: Number,
                required: true
            },
        },
        components: {
           // eslint-disable-next-line 
            MultiSelect,
        },
        data(): Data {
            return {
                loading: true,
                statuses: [],
                purchaseGroups: [],
                //periods: [],
                users: [],
                uniquePeriods: [],
                tradingListFilters: {
                    // 'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    // 'purchaseGroups': { value: null, matchMode: CustomFilterMatchMode.ANY_ID },
                    'period': { value: null, matchMode: FilterMatchMode.IN },
                    // 'tradeDateTime': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                    // 'supplierOfferPrice': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    // 'totalTradeVolume': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    // 'user': { value: null, matchMode: FilterMatchMode.IN },  
                    // 'status': { value: null, matchMode: FilterMatchMode.IN }, 
                },
                buySellOptions: [
                    { id: 1, name: "Buy" },
                    { id: 2, name: "Sell" }
                ],
                selectedTrade: null,
                showTradeAcceptanceDialog: false,
            };
        },
        created() {
            this.fetchData();
        },
        watch: {
            trades(newTrades: TradeReportData[]) {
                //Populate unique periods from trades for filtering purposes 
                this.uniquePeriods = [];
                newTrades.forEach(t => {                    
                    if (this.uniquePeriods.findIndex(p => p == t.period) == -1) {
                        this.uniquePeriods.push(t.period);
                    }
                });
                console.log("periods");
                console.log(this.uniquePeriods); 
                console.log(this.tradesWithInfo);               
            }
        },
        methods: {
            async fetchData() {
                console.log(this.trades);
                this.purchaseGroups = await purchaseGroupService.getPurchaseGroups();
                this.loading = false;            
            },
            async onRowSelect(event: any) {
                console.log(event);
                this.selectedTrade = event.data;
                this.showTradeAcceptanceDialog = true;
            },
            onTradeStatusChange() {

                this.showTradeAcceptanceDialog = false;


                this.$emit('update:trade', this.selectedTrade);
            },
            formatDate(dateToFormat: string) {
                return formatDate(dateToFormat, 'DD/MM/YYYY HH:mm');
            },
            formatCurrency(value: number) {
                return value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });
            },
        },
        computed: {
            tradesWithInfo() {
                const tradesWithInfo = this.trades ? this.trades.map(t => ({                    
                    ...t,                     
                    purchaseGroupName: this.purchaseGroups.find(pg => pg.id == t.purchaseGroupId)?.name,
                })) : [];

                return tradesWithInfo;
            },
            // selectedTradeWithInfo(): Trade | null {

            //     const tradeWithInfo = this.selectedTrade ? {
            //         ...this.selectedTrade,
            //         status: this.statuses.find(s => s.id === this.selectedTrade?.tradeStatusId),
            //         tradeDateTime: new Date(this.selectedTrade?.tradeDateTime ?? ""),
            //         buySell: (this.selectedTrade?.totalTradeVolume ?? 0) > 0 ? this.buySellOptions[0] : this.buySellOptions[1]
            //     } : null;

            //     return tradeWithInfo;
            // }
        }
    });
