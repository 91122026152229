import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card mt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_KeyDetails = _resolveComponent("KeyDetails")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_DeliverySchedule = _resolveComponent("DeliverySchedule")!
  const _component_ShapeFees = _resolveComponent("ShapeFees")!
  const _component_SupplyPoints = _resolveComponent("SupplyPoints")!
  const _component_SupplyPointCharges = _resolveComponent("SupplyPointCharges")!
  const _component_VolumesTemplate = _resolveComponent("VolumesTemplate")!
  const _component_CommissionFees = _resolveComponent("CommissionFees")!
  const _component_Accordion = _resolveComponent("Accordion")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Button, {
      onClick: _ctx.onCompleteCustomerContract,
      label: "Back to All Contracts"
    }, null, 8, ["onClick"]),
    (_ctx.contract)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Accordion, {
            activeIndex: _ctx.activeIndex,
            "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeIndex) = $event)),
            collapseIcon: "pi pi-save",
            expandIcon: "pi pi-chevron-right",
            onTabClose: _ctx.onCloseAccordion
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AccordionTab, { header: "Key Details" }, {
                default: _withCtx(() => [
                  _createVNode(_component_KeyDetails, {
                    editContract: _ctx.contract,
                    editMode: true,
                    isActiveStep: _ctx.activeIndex == 0,
                    saveMe: _ctx.saveIndex == 0,
                    "onSubmit:contract": _ctx.onUpdateContract
                  }, null, 8, ["editContract", "isActiveStep", "saveMe", "onSubmit:contract"])
                ]),
                _: 1
              }),
              _createVNode(_component_AccordionTab, { header: "Delivery Window Schedule" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DeliverySchedule, {
                    editContract: _ctx.contract,
                    editMode: true,
                    isActiveStep: _ctx.activeIndex == 1,
                    saveMe: _ctx.saveIndex == 1,
                    "onSubmit:contract": _ctx.onUpdateContract
                  }, null, 8, ["editContract", "isActiveStep", "saveMe", "onSubmit:contract"])
                ]),
                _: 1
              }),
              _createVNode(_component_AccordionTab, { header: "Shape Fees" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ShapeFees, {
                    editContract: _ctx.contract,
                    editMode: true,
                    isActiveStep: _ctx.activeIndex == 2,
                    saveMe: _ctx.saveIndex == 2,
                    "onSubmit:contract": _ctx.onUpdateContract
                  }, null, 8, ["editContract", "isActiveStep", "saveMe", "onSubmit:contract"])
                ]),
                _: 1
              }),
              _createVNode(_component_AccordionTab, { header: "Supply Points" }, {
                default: _withCtx(() => [
                  _createVNode(_component_SupplyPoints, {
                    editContract: _ctx.contract,
                    editMode: true,
                    editProduct: _ctx.activeProduct,
                    isActiveStep: _ctx.activeIndex == 3,
                    saveMe: _ctx.saveIndex == 3,
                    "onSubmit:contract": _ctx.onUpdateContract
                  }, null, 8, ["editContract", "editProduct", "isActiveStep", "saveMe", "onSubmit:contract"])
                ]),
                _: 1
              }),
              _createVNode(_component_AccordionTab, { header: "Supply Points Charges" }, {
                default: _withCtx(() => [
                  _createVNode(_component_SupplyPointCharges, {
                    editContract: _ctx.contract,
                    editMode: true,
                    isActiveStep: _ctx.activeIndex == 4,
                    saveMe: _ctx.saveIndex == 4,
                    "onSubmit:contract": _ctx.onUpdateContract
                  }, null, 8, ["editContract", "isActiveStep", "saveMe", "onSubmit:contract"])
                ]),
                _: 1
              }),
              _createVNode(_component_AccordionTab, { header: "Contract Volumes" }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_component_VolumesTemplate, {
                    editContract: _ctx.contract,
                    editMode: true,
                    key: _ctx.volumeKey,
                    isActiveStep: _ctx.activeIndex == 5,
                    saveMe: _ctx.saveIndex == 5,
                    units: _ctx.activeProduct && _ctx.activeProduct.utilityTypeId == 1 ? `therm/day` : `MW`,
                    "onSubmit:contract": _ctx.onUpdateContract
                  }, null, 8, ["editContract", "isActiveStep", "saveMe", "units", "onSubmit:contract"]))
                ]),
                _: 1
              }),
              _createVNode(_component_AccordionTab, { header: "Commissions" }, {
                default: _withCtx(() => [
                  _createVNode(_component_CommissionFees, {
                    editContract: _ctx.contract,
                    editMode: true,
                    isActiveStep: _ctx.activeIndex == 6,
                    saveMe: _ctx.saveIndex == 6,
                    "onSubmit:contract": _ctx.onUpdateContract
                  }, null, 8, ["editContract", "isActiveStep", "saveMe", "onSubmit:contract"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["activeIndex", "onTabClose"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}