import { useCRMApi } from '@/composables/CRMApi'
import { Lookup, Document } from '@/services/Types'
import { SupplyPoint } from '@/services/SupplyPointService'
import { getFormData } from '@/composables/UtilityFunctions'
import dayjs from "dayjs";


// todo: move these to a separate file
export interface CustomerContractSupplyPoints {
    contractId: number,
    supplyPointsIds: number[]
}

export interface CustomerContract {
    id: number,
    description: string,
    customerId: number,
    contractStatusId: number,
    supplierId?: number,
    utilityTypeId?: number,
    taxRateId?: number,
    productId?: number,
    startDate?: Date | null,
    endDate?: Date | null,
    consumptionPerMeter?: number,
    annualConsumption?: number,
    contractValue?: number,
    breakClauseDate?: Date | null,
    firstBreakClauseDate?: Date | null,
    contractDocument?: number,
    supplierAccountNumber?: string | null,
    customContractId?: string | null,
    minimumTerm: number,
    deliveryStart: number,
    supplyPoints: SupplyPoint[],
    schedule: DeliveryWindow[],
    volumes: ContractVolume[]
}

export interface DeliveryWindow {
    id: number,
    customerContractId: number,
    startDate?: Date | null,
    endDate?: Date | null,
    shapeFee?: number,
    commissionPrimary?: number,
    commissionSecondary?: number,
    termCharges: TermCharge[]
}

export interface TermCharge {
    id: number,
    deliveryWindowId: number,
    supplyPointId: number,
    shapeFee?: number,
    nonCommodityCost?: number,
    capacityChargeApr?: number,
    capacityChargeOct?: number,
    dailyCharge?: number,
}

export interface TermChargeXd {
    meterRef?: string | null,
    supplyPointName?: string | null,
    siteName: string,
    annualConsumption?: number | null,
    termCharge?: TermCharge,
}

export interface VolumeRef {
    deliveryWindowId: number,
    id: number,
    name: string,
    startDate?: string,
    endDate?: string,
    volume?: number,
    volumeId?: number,
}

export interface AddContractVolume {
    customerContractId: number,
    deliveryWindowId: number,
    periodId: number,
    readVolume?: number,
}

export interface ContractVolume {
    id: number,
    customerContractId: number,
    deliveryWindowId: number,
    periodId: number,
    readVolume?: number,
}

export default class CustomerContractService {

    /***** these are unit tests applied to getBreakClause outside of the scope of proj.
     function isDate(date, year, month, day) {
     return date.getMonth() === month
     && date.getDate() === day
     && date.getFullYear() === year
     }

     let clause = {};
     //
     console.log('1 year, Jan 2024 batch, -> should be 30 Sep 2024.');
     clause = getBreakClause(new Date(2024, 0, 1), 12, 10);
     console.log(isDate(clause, 2024, 8, 30));
     clause = getBreakClause(new Date(2024, 0, 1), 9, 10);
     console.log(isDate(clause, 2024, 8, 30));
     clause = getBreakClause(new Date(2024, 0, 8), 12, 10);
     console.log(isDate(clause, 2024, 8, 30));
     clause = getBreakClause(new Date(2024, 0, 9), 9, 10);
     console.log(isDate(clause, 2024, 8, 30));
     clause = getBreakClause(new Date(2024, 0, 31), 12, 10);
     console.log(isDate(clause, 2024, 8, 30));
     clause = getBreakClause(new Date(2024, 0, 31), 9, 10);
     console.log(isDate(clause, 2024, 8, 30));


     console.log('3 year, Jan 2024 batch, -> should be 30 Sep 2026.');

     clause = getBreakClause(new Date(2024, 0, 1), 36, 10);
     console.log(isDate(clause, 2026, 8, 30));

     clause = getBreakClause(new Date(2024, 0, 1), 33, 10);
     console.log(isDate(clause, 2026, 8, 30));

     clause = getBreakClause(new Date(2024, 0, 8), 36, 10);
     console.log(isDate(clause, 2026, 8, 30));

     clause = getBreakClause(new Date(2024, 0, 9), 33, 10);
     console.log(isDate(clause, 2026, 8, 30));

     clause = getBreakClause(new Date(2024, 0, 31), 36, 10);
     console.log(isDate(clause, 2026, 8, 30));

     clause = getBreakClause(new Date(2024, 0, 31), 33, 10);
     console.log(isDate(clause, 2026, 8, 30));


     console.log('1 year, April 2024 batch, -> should be 30 Sep 2025.');
     clause = getBreakClause(new Date(2024, 3, 1), 12, 10);
     console.log(isDate(clause, 2025, 8, 30));

     clause = getBreakClause(new Date(2024, 3, 1), 18, 10);
     console.log(isDate(clause, 2025, 8, 30));

     clause = getBreakClause(new Date(2024, 3, 8), 12, 10);
     console.log(isDate(clause, 2025, 8, 30));

     clause = getBreakClause(new Date(2024, 3, 9), 18, 10);
     console.log(isDate(clause, 2025, 8, 30));

     clause = getBreakClause(new Date(2024, 3, 30), 12, 10);
     console.log(isDate(clause, 2025, 8, 30));

     clause = getBreakClause(new Date(2024, 3, 30), 18, 10);
     console.log(isDate(clause, 2025, 8, 30));

     console.log('3 year, April 2024 batch, -> should be 30 Sep 2027.');
     clause = getBreakClause(new Date(2024, 3, 1), 36, 10);
     console.log(isDate(clause, 2027, 8, 30));

     clause = getBreakClause(new Date(2024, 3, 1), 42, 10);
     console.log(isDate(clause, 2027, 8, 30));

     clause = getBreakClause(new Date(2024, 3, 8), 36, 10);
     console.log(isDate(clause, 2027, 8, 30));

     clause = getBreakClause(new Date(2024, 3, 9), 42, 10);
     console.log(isDate(clause, 2027, 8, 30));

     clause = getBreakClause(new Date(2024, 3, 30), 36, 10);
     console.log(isDate(clause, 2027, 8, 30));

     clause = getBreakClause(new Date(2024, 3, 30), 42, 10);
     console.log(isDate(clause, 2027, 8, 30));
     ******/
    
    getBreakClause(startDate: Date, minTerm: number, deliveryWindowStart: number) {
        const DELIVERY_WINDOW_OFFSET = 1;
        const MONTHS_IN_YEAR = 12;

        //convert startDate to dayjs and first of month. -- quick hack...
        startDate = dayjs(startDate).startOf('month').toDate();
        const deliveryWindowNormalized = deliveryWindowStart - DELIVERY_WINDOW_OFFSET;

        let nextDeliveryWindowMonth = new Date(startDate);

        do {
            nextDeliveryWindowMonth = new Date(nextDeliveryWindowMonth.setMonth(nextDeliveryWindowMonth.getMonth() + 1));
        } while (nextDeliveryWindowMonth.getMonth() !== deliveryWindowNormalized);

        const startDateConv = dayjs(startDate);
        const nextDeliveryWindowConv = dayjs(nextDeliveryWindowMonth);

        const monthDifference = -(startDateConv.diff(nextDeliveryWindowConv, 'month'));
        if (monthDifference <= 6) minTerm += monthDifference;

        let years = minTerm / MONTHS_IN_YEAR;

        years = years <= 1 ? 0 : Math.ceil(years - 1);
        
        const offset = monthDifference - minTerm;
        const actualTerm = offset + minTerm;

        const actualStartDate = startDateConv.startOf('month');
        const actualTargetDate =
            actualStartDate.add(actualTerm, 'month')
                .subtract(1, 'day')
                .add(years, 'year');

        return actualTargetDate.toDate();
    }

    async getContractsForCustomer(customerId: string) {

        const data = await useCRMApi(`/crm/contracts/customer/${customerId}`) as CustomerContract[];
        return data ?? [];
    }

    async getCustomerContract(contractId: Number) {

        const data = await useCRMApi(`/crm/contracts/${contractId}`) as CustomerContract;
        return data ?? null;
    }

    async getContractSupplyPoints(contractId: Number) {

        const data = await useCRMApi(`/crm/contracts/${contractId}/supplypoints`) as SupplyPoint[];
        return data ?? [];
    }


    async getScheduleByContractId(contractId: Number) {

        const data = await useCRMApi(`/crm/schedule/customercontract/${contractId}`) as DeliveryWindow[];
        return data ?? [];
    }

    async getVolumesByContractId(contractId: Number) {

        const data = await useCRMApi(`/crm/contractvolume/customercontract/${contractId}`) as ContractVolume[];
        return data ?? [];
    }

    async getSelectedCustomerContracts(selected: number[] | null) {

        const payload = { CustomerContractIds: selected };

        const newOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };


        const data = await useCRMApi('/crm/contracts/selected', newOptions) as CustomerContract[];
        return data ?? [];
    }

    async updateCustomerContractSupplyPoints(updatedSPs: CustomerContractSupplyPoints | null) {

        const newOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updatedSPs)
        };

        const data = await useCRMApi(`/crm/contracts/supplypoints`, newOptions);
        return data ?? null;
    }

    async updateCustomerContract(updateContract: CustomerContract | null) {

        const formData = getFormData(updateContract);

        const newOptions = {
            method: 'PUT',
            body: formData
        };

        const data = await useCRMApi(`/crm/contracts/customer`, newOptions);
        return data ?? null;
    }

    async addCustomerContract(addContract: CustomerContract | null) {

        const formData = getFormData(addContract);

        const newOptions = {
            method: 'POST',
            body: formData
        };

        const data = await useCRMApi(`/crm/contracts/customer`, newOptions) as CustomerContract;
        return data ?? null;
    }

    async addContractSchedule(addSchedule: DeliveryWindow[]) {
        const schedule = addSchedule.map(({ customerContractId, startDate, endDate }) => ({ customerContractId, startDate, endDate }));
        //const formData = getFormData({ schedule: addSchedule });
        const payload = { newSchedule: schedule };


        const newOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };

        const data = await useCRMApi(`/crm/schedule`, newOptions) as DeliveryWindow[];
        return data ?? [];
    }

    async updateContractSchedule(editSchedule: DeliveryWindow[] | null) {
        //const formData = getFormData({ schedule: addSchedule });
        const payload = { updateSchedule: editSchedule };


        const newOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };

        const data = await useCRMApi(`/crm/schedule`, newOptions);
        return data ?? null;
    }

    async addContractTermCharges(addedTermCharges: TermCharge[]) {

        //const formData = getFormData(addedTermCharges);

        const newOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(addedTermCharges)
        };

        const data = await useCRMApi(`/crm/schedule/termcharges`, newOptions) as TermCharge[];
        return data ?? [];
    }

    async updateContractTermCharges(editedCharges: TermCharge[] | null) {
        //const formData = getFormData({ schedule: addSchedule });
        //const payload = { termCharges: editedCharges };


        const newOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(editedCharges)
        };

        const data = await useCRMApi(`/crm/schedule/termcharges`, newOptions);
        return data ?? null;
    }

    async addContractVolumes(addedVolumes: AddContractVolume[]) {

        //const formData = getFormData(addedVolumes);

        const newOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(addedVolumes)
        };

        const data = await useCRMApi(`/crm/contractvolume/multi`, newOptions) as ContractVolume[];
        return data ?? [];
    }

    async updateContractVolumes(editedVolumes: ContractVolume[] | null) {

        const newOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(editedVolumes)
        };

        const data = await useCRMApi(`/crm/contractvolume/multi`, newOptions);
        return data ?? null;
    }

    async deleteContract(id: Number) {
        const newOptions = {
            method: 'DELETE'
        };

        const data = await useCRMApi(`/crm/contracts/${id}`, newOptions);
        return data ?? null;
    }

    async getContractStatuses() {

        const data = await useCRMApi('/crm/contractstatus') as Lookup[];
        return data ?? [];
    }

    async getUtilityTypes() {

        const data = await useCRMApi('/crm/utilitytypes') as Lookup[];
        return data ?? [];
    }

    async getSuppliers() {

        const data = await useCRMApi('/crm/suppliers') as Lookup[];
        return data ?? [];
    }

    async getTaxRates() {

        const data = await useCRMApi('/crm/taxrates') as Lookup[];
        return data ?? [];
    }

    async getBenchmarks() {

        const data = await useCRMApi('/crm/benchmarks') as Lookup[];
        return data ?? [];
    }


}


export const customerContractService = new CustomerContractService();


