
    import { defineComponent, PropType } from 'vue';
    import * as yup from "yup";
    import dayjs from "dayjs";
    import { Form, Field, ErrorMessage } from 'vee-validate';
    import { formatDate } from '@/composables/UtilityFunctions';
    import DocumentUpload from '@/components/Common/DocumentUpload.vue';

    import { customerContractService, CustomerContract } from '@/services/ContractService';
    import { purchaseGroupService, PurchaseGroup } from '@/services/PurchaseGroupService';
    import { Lookup } from '@/services/Types';

    interface Data {
        loading: boolean,
        validationSchema: any,
        contract: CustomerContract,
        statuses: null | Lookup[],
        startDates: Lookup[],
        newContractDocument: File | null,
        contractDocuments: any[],
        contractDuration: number,
        minimumTerm: number
        deliveryStart: number,
        purchaseGroup: null | PurchaseGroup,
        //annualConsumption: number,
    }

    export default defineComponent({

        props: {
            editContract: {
                type: Object as PropType<CustomerContract>,
                required: true
            },
            editMode: Boolean,
            isActiveStep: Boolean,
            saveMe: {
                type: Boolean,
                required: false
            },
        },
        components: {
            Form,
            Field,
            ErrorMessage,
            DocumentUpload
        },
        data(): Data {

            const validationSchema = yup.object({
                customerId: yup.number().required(),
                description: yup.string().required().label("Description"),
                contractStatusId: yup.number().required().label("Contract Status"),
                supplierAccountNumber: yup.string().nullable().label("Contract Reference Number"),
                customContractId: yup.string().nullable().label("Contract ID"),
                startDate: yup.date().required().label("Start Date"),
                contractDuration: yup.number().nullable().label("Contract Duration"),
                minimumTerm: yup.number().nullable().label("Minimum Term"),
                deliveryStart: yup.number().required().label("Delivery Window Start Month"),
                annualConsumption: yup.number().nullable().label("Annual Consumption"),
            })
                .typeError("Please select ${value} and ${type}");

            const startMonths = [
                { id: 1, name: "January" },
                { id: 4, name: "April" },
                { id: 10, name: "October" },
            ];

            return {
                loading: true,
                validationSchema: validationSchema,
                contract: this.editContract,
                statuses: null,
                startDates: startMonths,
                newContractDocument: null,
                contractDocuments: this.editContract.contractDocument ? [this.editContract.contractDocument] : [],
                contractDuration: 60,
                minimumTerm: this.editContract.minimumTerm ?? 36,
                deliveryStart: this.editContract.deliveryStart ?? 10,
                purchaseGroup: null
                //annualConsumption: 0,
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        watch: {          
            editContract(newValue) {
                if (this.isActiveStep) {
                    console.log("documents changed")
                    this.contract = newValue;
                    this.contractDocuments = newValue.contractDocument ? [newValue.contractDocument] : [];
                    this.newContractDocument = null;
                }
            },
            saveMe(shouldSave) {
                //Forces save if in edit mode
                if (this.editMode && shouldSave) {
                    this.onSubmit(true);
                }
            },
        },
        computed: {
            annualConsumption() {
                var acc = 0;                
                this.contract.supplyPoints.forEach(sp => {
                    if (sp?.annualConsumption) acc = acc + sp.annualConsumption;
                })
                return acc;
            }
        },
        methods: {
            async fetchData() {

                this.statuses = await customerContractService.getContractStatuses();
                this.statuses = this.statuses.filter(cs => cs.name === "Not Started" || cs.name === "Accepted")
                    .map(cs => {
                        var nameChange = cs.name.replace("Not Started", "Draft").replace("Accepted", "Confirmed");
                        return { ...cs, name: nameChange };
                    });

                this.purchaseGroup = await purchaseGroupService.getPurchaseGroupByContractId(this.contract.id);
                this.loading = false;

                console.log("contract details loaded");
            },
            onSubmit(values: any) {
                console.log("Submitting:", values);
                const enddate = dayjs(this.contract.startDate).add(this.contractDuration, "months").toISOString();
                console.log("End date:", enddate);

                const editedContract = {
                    ...this.contract,
                    endDate: enddate,
                    contractDocument: this.newContractDocument,
                }

                console.log("Contract:", editedContract);

                this.$emit('submit:contract', editedContract);
            },
            onSelectDocument(event: any) {

                console.log("select doc");
                console.log(event);

                this.newContractDocument = event;

                this.$emit('select:contractDocument', this.newContractDocument);
            },
            onRemoveDocument(index: number) {

                let removedFile = this.contractDocuments.splice(index, 1)[0];

                this.contractDocuments = [...this.contractDocuments];

                this.$emit('remove:contractDocument', removedFile);
            },
            formatDate(dateToFormat: string) {
                return formatDate(dateToFormat);
            }
        }
    });
