
    import { defineComponent } from 'vue';
    import { contactService, Contact } from '@/services/ContactService';
    import ContactList from '@/components/Contacts/ContactList.vue';
    
    interface Data {
        loading: boolean,
        contacts: Contact[],
    }

    export default defineComponent({
        data(): Data {
            return {
                loading: true,
                contacts: [],
                };
        },
        components: {
            ContactList
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                this.contacts = await contactService.getContacts();

                this.loading = false;
                
            },
        }
    });
