import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, {
      value: _ctx.dataWithInfo,
      selectionMode: "single",
      dataKey: "id",
      onRowSelect: _ctx.onRowSelect,
      responsiveLayout: "scroll",
      filters: _ctx.gasDataStoreListFilters,
      "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.gasDataStoreListFilters) = $event)),
      filterDisplay: "menu",
      globalFilterFields: [],
      paginator: true,
      rows: 10,
      loading: _ctx.loading
    }, {
      empty: _withCtx(() => [
        _createTextVNode(" No Data Found. ")
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "period",
          header: "Period",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "meterRef",
          header: "Meter Ref",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "commodityRate",
          header: "Commodity Rate",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "nonCommodity",
          header: "Non Commodity",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "consumption",
          header: "Consumption",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "combinedUnitCost",
          header: "Combined Unit Cost",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "monthlyCharge",
          header: "Monthly Charge",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "otherCharges",
          header: "Other Charges",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "ccl",
          header: "CCL",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "netCost",
          header: "Net Cost",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "vat",
          header: "VAT",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "totalCost",
          header: "Total Cost",
          dataType: "numeric",
          sortable: true
        })
      ]),
      _: 1
    }, 8, ["value", "onRowSelect", "filters", "loading"])
  ]))
}