
    import { defineComponent, PropType } from 'vue';
    import * as yup from "yup";
    import dayjs from "dayjs";
    import { Form, Field, ErrorMessage } from 'vee-validate';
    import { formatDate, formatDateForBackend } from '@/composables/UtilityFunctions';

    import { customerContractService, CustomerContract, DeliveryWindow } from '@/services/ContractService';

    interface Data {
        loading: boolean,
        validationSchema: any,
        contract: CustomerContract,
        schedule: DeliveryWindow[],
        newDeliveryWindow: DeliveryWindow,
        newSchedule: boolean,
    }

    export default defineComponent({

        props: {
            editContract: {
                type: Object as PropType<CustomerContract>,
                required: true
            },
            editMode: Boolean,
            isActiveStep: Boolean,
            saveMe: {
                type: Boolean,
                required: false
            },
        },
        components: {
            Form,
            Field,
            ErrorMessage,
        },
        data(): Data {

            const validationSchema = yup.object({
                customerContractId: yup.number().required(),
                breakClauseDate: yup.date().nullable().label("Break Clause Notice Date"),
                firstBreakClauseDate: yup.date().nullable().label("First Break Clause Date"),
            })
                .typeError("Please select ${value} and ${type}");

            return {
                loading: true,
                validationSchema: validationSchema,
                contract: this.editContract,
                schedule: this.editContract.schedule,
                newDeliveryWindow: {
                    id: 0,
                    customerContractId: this.editContract.id,
                    startDate: this.editContract.startDate,
                    endDate: this.editContract.endDate,
                    termCharges: []
                },
                newSchedule: false
            };
        },
        created() {
            // fetch on init
            //this.fetchData();
        },
        watch: {
            editContract(newValue) {
                if (this.isActiveStep) {
                    console.log("contract changed");
                    this.contract = newValue;
                    this.fetchData();
                }
            },
            saveMe(shouldSave) {
                //Forces save if in edit mode
                if (this.editMode && shouldSave) {
                    this.onSubmit(true);
                }
            },
        },
        methods: {
            async fetchData() {

                if (this.contract.id > 0) this.schedule = await customerContractService.getScheduleByContractId(this.contract.id);

                if (this.schedule.length == 0) {
                    this.newSchedule = true;
                    this.createSchedule();
                    console.log("new schedule created");
                }
                if(this.contract.startDate) {
                    let breakClause = customerContractService.getBreakClause(this.contract.startDate, this.contract.minimumTerm, this.contract.deliveryStart);
                    this.contract.firstBreakClauseDate = breakClause;
                    this.contract.breakClauseDate = dayjs(breakClause).subtract(3, "months").toDate();
                }


                this.loading = false;

                console.log("contract details loaded");
            },
            async onSubmit(values: any) {
                console.log("Submitting:", values);

                const editedContract = {
                    ...this.contract,
                    endDate: this.schedule[this.schedule.length - 1].endDate,
                    schedule: this.schedule,
                    breakClauseDate: formatDateForBackend(this.contract.breakClauseDate),
                    firstBreakClauseDate: formatDateForBackend(this.contract.firstBreakClauseDate)
                }

                console.log("Contract:", editedContract);

                if (this.newSchedule) {
                    editedContract.schedule = await customerContractService.addContractSchedule(this.schedule);
                    if (editedContract.schedule.length > 0) this.newSchedule = false;
                }
                this.$emit('submit:contract', editedContract);
            },
            formatDate(dateToFormat: string) {
                return formatDate(dateToFormat);
            },
            createSchedule() {
                this.schedule = [];

                //Create first truncated entry
                const startdate = dayjs(this.contract.startDate);
                const startMonth = startdate.month() + 1;
                var endYear = startdate.year();
                if (this.contract.deliveryStart <= startMonth) endYear = endYear + 1;
                var endFirstDate = new Date(endYear, this.contract.deliveryStart - 1, 1);
                var endFirst = dayjs(endFirstDate).subtract(1, "days").endOf("day");
                const firstDelivery = {
                    ...this.newDeliveryWindow,
                    customerContractId: this.contract.id,
                    startDate: startdate.toDate(),
                    endDate: endFirst.toDate(),
                };
                console.log("First end date:", endFirst);
                this.schedule.push(firstDelivery);

                //Generate remainder of schedule entries
                const enddate = dayjs(this.contract.endDate);
                console.log("End date:", enddate);
                var nextEnd = endFirst.clone().add(12, "months");
                var nextStart = endFirst.clone().add(1, "days").startOf("day");
                while (nextEnd <= enddate) {
                    this.schedule.push({
                        ...this.newDeliveryWindow,
                        customerContractId: this.contract.id,
                        startDate: nextStart.toDate(),
                        endDate: nextEnd.toDate(),
                    });
                    nextStart = nextStart.add(12, "months");
                    nextEnd = nextEnd.add(12, "months");
                }

            },
        }
    });
