import { createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.reportPeriod === 'Daily')
      ? (_openBlock(), _createBlock(_component_DataTable, {
          key: 0,
          value: _ctx.supplyPointReadings,
          selectionMode: "single",
          dataKey: "readTime",
          loading: _ctx.loading,
          paginator: true,
          rows: 24,
          filters: _ctx.filters,
          "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters) = $event)),
          filterDisplay: "menu",
          paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          rowsPerPageOptions: [5,10,25],
          currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} volumes",
          scrollable: true,
          responsiveLayout: "scroll"
        }, {
          empty: _withCtx(() => [
            _createTextVNode(" No Supply Point readings found. ")
          ]),
          loading: _withCtx(() => [
            _createTextVNode(" Loading supply point readings... ")
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "readTime",
              header: "Reading Date",
              dataType: "date",
              sortable: true
            }, {
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(_ctx.formatDate(slotProps.data.readTime,'DD/MM/YYYY')), 1)
              ]),
              filter: _withCtx(({filterModel}) => [
                _createVNode(_component_Calendar, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  dateFormat: "dd/mm/yy",
                  showTime: false
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "usage",
              header: "Billed Therms",
              sortable: true
            })
          ]),
          _: 1
        }, 8, ["value", "loading", "filters"]))
      : _createCommentVNode("", true),
    (_ctx.reportPeriod === 'Monthly')
      ? (_openBlock(), _createBlock(_component_DataTable, {
          key: 1,
          value: _ctx.readingsByMonth,
          selectionMode: "single",
          dataKey: "id",
          loading: _ctx.loading,
          paginator: true,
          rows: 24,
          filters: _ctx.filters,
          "onUpdate:filters": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters) = $event)),
          filterDisplay: "menu",
          paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          rowsPerPageOptions: [5,10,25],
          currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} volumes",
          scrollable: true,
          responsiveLayout: "scroll"
        }, {
          empty: _withCtx(() => [
            _createTextVNode(" No Supply Point readings found. ")
          ]),
          loading: _withCtx(() => [
            _createTextVNode(" Loading supply point readings... ")
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "readTime",
              header: "Month",
              sortable: true
            }, {
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(_ctx.formatDate(slotProps.data.readTime,'MMMM')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "readTime",
              header: "Year",
              sortable: true
            }, {
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(_ctx.formatDate(slotProps.data.readTime,'YYYY')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "usage",
              header: "Billed Therms",
              sortable: true
            }, {
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(slotProps.data.usage.toFixed(1)), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "loading", "filters"]))
      : _createCommentVNode("", true)
  ]))
}