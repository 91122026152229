
    import * as yup from "yup";
    import { Form, Field, ErrorMessage } from 'vee-validate';   
    import { defineComponent, PropType } from 'vue';
    import { purchaseGroupService, PurchaseGroup } from '@/services/PurchaseGroupService';
    import { tradeService } from '@/services/TradeService';
    import { customerContractService } from '@/services/ContractService';
    import { Lookup } from '@/services/Types';
    import DocumentUpload from '@/components/Common/DocumentUpload.vue';
    import MultiSelect from 'primevue/multiselect';
    import { formatDate } from '@/composables/UtilityFunctions';

    interface Data {
        loading: boolean,
        editMode: boolean,
        validationSchema: any,
        purchaseGroup: PurchaseGroup | null,
        statuses: Lookup[] | null,
        suppliers: Lookup[] | null,
        commodityTypes: Lookup[] | null,
        defaultIndexes: Lookup[] | null,
        allowedTradeTypes: Lookup[] | null,
        newContract: File | null,
        contractDocuments: any[],
        endDays: any[],
    }

    export default defineComponent({
        props:  {
            editPurchaseGroup: {
                type: Object as PropType<PurchaseGroup>,
                required: true
            },
        },
        data(): Data {
            const validationSchema = yup.object().shape({
                id: yup.number().required(),
                purchaseGroupName: yup.string().required().label('Purchase Group Name'),
                supplierId: yup.number().required().label('Supplier'),
                defaultIndexId: yup.number().required().label('Default Index'),
                startDate: yup.date().nullable().label("Start Date"),
                endDate: yup.date().nullable().label("End Date"),
                nonTradingDaysAtMonthEnd: yup.number().nullable().label("Last Trading Day"),
                statusId: yup.number().required().label('Status'),
                commodityTypeId: yup.number().required().label('Commodity Type'),
                parentPurchaseGroupId: yup.number().nullable().label('Parent Purchase Group'),
                minimumClipsSize: yup.number().nullable().label('Minimum Clips Size'),
                sellbackFee: yup.number().nullable().label('Sell Back Fee'),
                indexFee: yup.number().required().label('Index Fee'),
                allowedTradeTypes: yup.array().min(1).label('Allowed Trade Types'),
            });

            return {
                loading: false,
                editMode: false,
                purchaseGroup: this.editPurchaseGroup,
                statuses: [],
                suppliers: [],
                commodityTypes: [],
                defaultIndexes: [],
                allowedTradeTypes: [],
                validationSchema: validationSchema,
                newContract: null,
                contractDocuments: this.editPurchaseGroup.contractDocument ? [this.editPurchaseGroup.contractDocument] : [],
                endDays: [
                    { label: 'Month end - 1 day', value: 1 },
                    { label: 'Month end - 2 days', value: 2 },
                    { label: 'Month end - 3 days', value: 3 },
                    { label: 'Month end - 4 days', value: 4 },
                    { label: 'Month end - 5 days', value: 5 }
                ]
            };
        },
        components: {
            Form,
            Field,
            ErrorMessage,
            MultiSelect,
            DocumentUpload
        },
        created() {
            // set edit mode based on customer id
            this.editMode = this.editPurchaseGroup.id > 0;

            // fetch on init
            this.fetchData();
        },
        watch: {          
            editPurchaseGroup(newValue: PurchaseGroup) {

                this.purchaseGroup = newValue;
                this.newContract = null;
                this.contractDocuments = newValue.contractDocument ? [newValue.contractDocument] : [];
            },
        },
        methods: {
            async fetchData() {

                console.log("fetch data...")

                //this.customer = null;
                this.loading = true;

                this.commodityTypes = await purchaseGroupService.getCommodities();
                this.suppliers = await customerContractService.getSuppliers();
                this.defaultIndexes = await purchaseGroupService.getDefaultIndexes();
                this.statuses = await purchaseGroupService.getPurchaseGroupStatuses();
                this.allowedTradeTypes = await tradeService.getTradeTypes();

                this.loading = false;

                console.log("purchase group data loaded");
            },
            onSubmit() {

                const updatePurchaseGroup = {
                    ...this.purchaseGroup,
                    contractDocument: this.newContract,
                };

                console.log("Pre-submitting:", updatePurchaseGroup);
                this.$emit('submit:purchaseGroup', updatePurchaseGroup);
            },
            onSelectContract(event: File[]) {
                this.newContract = event.length > 0 ? event[0] : null;
                this.$emit('select:contract', this.newContract);
            },
            onRemoveContract(index: number) {
                let removedFile = this.contractDocuments.splice(index, 1)[0];
                this.contractDocuments = [...this.contractDocuments];
                this.$emit('remove:contract', removedFile);
            },
            formatDate(dateToFormat: string) {
                return formatDate(dateToFormat);
            }
       }
    });
