

export const RegEx = {
    // iso standard https://en.wikipedia.org/wiki/E.164
    TELEPHONE: new RegExp(/^[0-9]\d{1,14}$/),
    // mobile numbers
    MOBILE: new RegExp(/^[0-9]\d{1,12}$/),
    POSTCODE: new RegExp(/^(([A-Z]{1,2}\d[A-Z\d]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?\d[A-Z]{2}|BFPO ?\d{1,4}|(KY\d|MSR|VG|AI)[ -]?\d{4}|[A-Z]{2} ?\d{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/),
    // can be letters or numbers
    ALPHANUMERIC: new RegExp(/^[A-Za-z0-9]+$/),
    THREE_ALPHANUMERIC: new RegExp(/^[A-Za-z0-9]{3}$/),
    THREE_DIGITS: new RegExp(/^[0-9]{3}$/),
    THIRTEEN_DIGITS: new RegExp(/^[0-9]{13}$/)
}
