
    import { Auth } from 'aws-amplify'
    import { defineComponent, PropType } from 'vue';
    import { Lookup } from '@/services/Types';
    import { formatDate } from '@/composables/UtilityFunctions';
    import { FilterMatchMode } from 'primevue/api';
    import MultiSelect from 'primevue/multiselect';
    import ConsumptionItem from '@/components/Settings/Consumption/ConsumptionItem.vue';
    import { consumptionService, ConsumptionImport } from '@/services/ConsumptionService';

    interface Data {
        loading: boolean,
        notParsed: boolean,
        importing: boolean,
        deleting: boolean,
        user: any | null,
        statuses: Lookup[],
        utilityTypes: Lookup[],
        consumptionListFilters: {},
        newConsumptionImport: ConsumptionImport | null,
        addConsumptionImportDialog: boolean,
        deleteImportDialog: boolean,
    }

    export default defineComponent({
        props: {
            imports: {
                type: Array as PropType<Array<ConsumptionImport>>,
                required: true
            }
        },
        emits: [
            "add:import",
            "update:import",
            "delete:import",
        ],
        data(): Data {
            return {
                loading: true,
                notParsed: true,
                importing: false,
                deleting: false,
                user: null,
                statuses: [
                    { id: 0, name: "unprocessed" },
                    { id: 1, name: "success" },
                    { id: 2, name: "error" }
                ],
                utilityTypes: [
                    { id: 1, name: "HH" },
                    { id: 2, name: "NHH" },
                    { id: 3, name: "Gas" },
                //    { id: 12, name: "HH & NHH" },
                ],
                newConsumptionImport: null,
                addConsumptionImportDialog: false,
                deleteImportDialog: false,
                consumptionListFilters: {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'statusId': { value: null, matchMode: FilterMatchMode.IN },
                    'utilityTypeId': { value: null, matchMode: FilterMatchMode.IN },
                },
            };
        },
        components: {
            ConsumptionItem,
            MultiSelect
        },
        created() {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    this.user = user;
                })
                .catch(() => console.log('not signed in...'))

            // fetch on init
            this.fetchData();
        },
        computed: {
        },
        methods: {
            async fetchData() {

                this.loading = false;
                
            },
            onRowSelect(event: any) {
                console.log(event);
            //    this.$router.push({ name: 'editImport', params: { importId: event.data.id } })
            },
            addNewConsumptionImport() {

                this.newConsumptionImport = {
                    id: 0,
                    name: "",
                    importedById: this.user?.username,
                    statusId: 0,
                    utilityTypeId: 0,
                    errorMessage: "",
                    advice: "",
                    rawFile: undefined,
                    creationTime: null,
                };

                this.addConsumptionImportDialog = true;
            },
            async onAddConsumptionImport(addImportEvent: any) {
                console.log("Creating import...");

                this.importing = true;

                this.newConsumptionImport = await consumptionService.addImport(addImportEvent)

                this.$emit('add:import', addImportEvent);

                console.log("created successfully!", this.newConsumptionImport);
            },
            async onUpdateConsumptionImport(updateImportEvent: any) {
                console.log("Updating import...");

                await consumptionService.updateImport(updateImportEvent)
                this.$emit('update:import', updateImportEvent);

                console.log("updated successfully!", this.newConsumptionImport);

                this.importing = false;
                this.addConsumptionImportDialog = false;
            },
            onSelectConsumptionImport() {
                this.notParsed = false;
            },
            onRemoveConsumptionImport() {
                this.notParsed = true;
            },
            lookupUtilityType(utilId: number) {
                let utility = this.utilityTypes.find(ut => ut.id == utilId);
                if (utility == undefined) return "unknown";
                else return utility.name;
            },
            confirmDeleteImport(consumptionImport: ConsumptionImport) {
                this.newConsumptionImport = consumptionImport;
                this.deleteImportDialog = true;
            },
            async deleteImport() {
                this.deleting = true;
                if (this.newConsumptionImport) {
                    await consumptionService.deleteImport(this.newConsumptionImport.id);

                    this.$emit('delete:import');
                }
                this.deleting = false;
                this.notParsed = true;
                this.deleteImportDialog = false;
                this.newConsumptionImport = null;
            },
            formatDate(dateToFormat: string) {
                return formatDate(dateToFormat, 'DD/MM/YYYY h:mma');
            },

        }
    });
