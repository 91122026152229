export class VolumeService {
    static formatVolumeForDisplay(volumeValue: any) {
        //ToDo: Remove any in the future and make it a number, i don't have time to re-architect this right now
        if(isNaN(volumeValue)) {
            return volumeValue;
        }
        if(volumeValue % 1 === 0) {
            return volumeValue;
        } else {
            return volumeValue.toFixed(4);
        }
    }
}