
    import { defineComponent } from 'vue';
    import { supplyPointService, SupplyPoint } from '@/services/SupplyPointService';
    import SupplyPointList from '@/components/SupplyPoints/SupplyPointList.vue';

    interface Data {
        loading: boolean,
        supplyPoints: SupplyPoint[]
    }

    export default defineComponent({
        props: {
            customerId: {
                type: Number,
                required: true
            }
        },
        components: {
            SupplyPointList
        },
        data(): Data {

            return {
                loading: true,
                supplyPoints: []
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {
                this.supplyPoints = await supplyPointService.getSupplyPointsForCustomer(this.customerId);

                this.loading = false;
            },
        }
    });
