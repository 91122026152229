
    import { defineComponent } from 'vue';
    import { VolumeHistory, purchaseGroupService } from '@/services/PurchaseGroupService';
    import { Period, periodService } from '@/services/PeriodService';
    import { User, userService } from '@/services/UserService';
    import { formatDate } from '@/composables/UtilityFunctions';    

    interface Data {
        loading: boolean,
        historyVolumesDialog: boolean,
        volumeHistories: VolumeHistory[],
        periods: Period[],
        firstVolumeIds: FirstVolume[],
        users: User[],
    }

    interface FirstVolume {
        volumeId: Number,
        volumeHistoryId: Number,
    }

    export default defineComponent({
        props: {
            purchaseGroupId: {
                type: Number,
                required: true
            },
        },
        data(): Data {
            return {
                loading: true,
                historyVolumesDialog: false,
                volumeHistories: [],
                periods: [],
                firstVolumeIds: [],
                users: []
            };
        },
        created() {
            this.fetchData(); 
        },
        methods: {
            async fetchData() {
                this.users = await userService.getUsers();
                this.periods = await periodService.getPeriods();

                const res = await purchaseGroupService.getAllVolumeHistoriesByPurchaseGroup(this.purchaseGroupId);
                if(Array.isArray(res) == false) {                    
                    console.log(res);
                    return;
                }

                this.volumeHistories = res;
                this.volumeHistories.forEach( v => {
                    if(this.firstVolumeIds.some(fv => fv.volumeId == v.volumeId) == false) {
                        this.firstVolumeIds.push({
                            volumeId: v.volumeId,
                            volumeHistoryId: v.id ?? 0,
                        });
                    }
                });
                
                this.loading = false;
            },
            async show() {
                this.loading = true;
                this.historyVolumesDialog = true;

                await this.fetchData();
            },
            isFirstHistoryForVolume(volumeHistory: VolumeHistory): boolean {
                return this.firstVolumeIds.some(fv => fv.volumeHistoryId == volumeHistory.id)
            },
            getDateFormatted(creationTime: Date) {
                const formattedDate = formatDate(creationTime.toString());
                return formattedDate;
            },
            getTimeFormatted(creationTime: Date) {
                const formattedTime = formatDate(creationTime.toString(), "h:mm A");
                return formattedTime;
            },
            getUser(creatorUserId: string) {       
                let user = this.users.find( u => u.userId == creatorUserId);
                return user;
            },
            getPeriod(periodId: Number) {
                var period = this.periods.find(p => p.id == periodId);
                return period;
            },
            getPreviousHistory(volumeHistory: VolumeHistory) {
                const allVolumeHistoriesForVolume = this.volumeHistories.filter( vh => vh.volumeId == volumeHistory.volumeId);

                const indexOfCurrentVolume = allVolumeHistoriesForVolume.findIndex(vh => vh.id == volumeHistory.id);
                const previousVolumeHistory = allVolumeHistoriesForVolume[indexOfCurrentVolume - 1];

                return previousVolumeHistory;
            }
        }
    });
