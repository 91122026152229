
    import { defineComponent } from 'vue';
    import Accordion from 'primevue/accordion';
    import AccordionTab from 'primevue/accordiontab';
    import { customerContractService, CustomerContract } from '@/services/ContractService';
    import { productService, Product } from '@/services/ProductService';
    import KeyDetails from '@/components/Contracts/KeyDetails.vue';
    import DeliverySchedule from '@/components/Contracts/DeliverySchedule.vue';
    import ShapeFees from '@/components/Contracts/ShapeFees.vue';
    import SupplyPoints from '@/components/Contracts/SupplyPoints.vue';
    import SupplyPointCharges from '@/components/Contracts/SupplyPointCharges.vue';
    import VolumesTemplate from '@/components/Contracts/VolumesTemplate.vue';
    import CommissionFees from '@/components/Contracts/CommissionFees.vue';

    interface Data {
        loading: boolean,
        contract: CustomerContract | null,
        activeProduct: Product | null,
        activeIndex: number,
        saveIndex: number,
        isSaving: boolean,
        volumeKey:number
    }

    export default defineComponent({

        props: {
            customerId: {
                type: Number,
                required: true
            },
            contractId: {
                type: Number,
                required: true
            }
        },
        components: {
            Accordion,
            AccordionTab,
            KeyDetails,
            DeliverySchedule,
            ShapeFees,
            SupplyPoints,
            SupplyPointCharges,
            VolumesTemplate,
            CommissionFees,
        },
        data(): Data {

            return {
                loading: true,
                contract: null,
                activeProduct: null,
                activeIndex: 0,
                saveIndex: -1,
                isSaving: false,
                volumeKey: 0
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                this.contract = await customerContractService.getCustomerContract(this.contractId);
                await this.onProductSelect(this.contract.productId);

                this.loading = false;
            },
            async onProductSelect(selected: any) {
                if (selected > 0) {
                    this.activeProduct = await productService.getProductById(selected);
                    console.log("Product:", this.activeProduct);
                }
            },
            async onCompleteCustomerContract() {

                console.log("save successful!");

                this.$router.push({ name: 'customerContracts', params: { customerId: this.customerId } })

            },
            async onCloseAccordion(accordionTabCloseEvent: any) {
                console.log("Closed accordion tab:", accordionTabCloseEvent.index);
                this.saveIndex = accordionTabCloseEvent.index;
            },
            async onUpdateContract(updateContractEvent: any) {
                console.log(updateContractEvent);

                this.isSaving = true;

                var updateContract = {
                    ...this.contract,
                    ...updateContractEvent,
                };

                await customerContractService.updateCustomerContract(updateContract);
                this.$emit('update:contract', updateContract);

                this.contract = updateContract;

                this.saveIndex = -1;
                this.isSaving = false;

                console.log("contract save successful!");
                //reloading data.
                await this.fetchData();
                this.volumeKey++;

            },
        }
    });
