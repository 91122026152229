import { createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, {
      value: _ctx.tradesWithInfo,
      selectionMode: "single",
      dataKey: "id",
      onRowSelect: _ctx.onRowSelect,
      responsiveLayout: "scroll",
      filters: _ctx.tradingListFilters,
      "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tradingListFilters) = $event)),
      filterDisplay: "menu",
      globalFilterFields: ['purchaseGroupNames', 'customerNames'],
      paginator: true,
      rows: 10,
      loading: _ctx.loading
    }, {
      header: _withCtx(() => []),
      empty: _withCtx(() => [
        _createTextVNode(" No trades have been made. ")
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "period",
          filterField: "period",
          header: "Period",
          showFilterMatchModes: false,
          filterMenuStyle: {'width':'16rem'}
        }, {
          body: _withCtx((tradesWithInfo) => [
            _createTextVNode(_toDisplayString(tradesWithInfo.data.period), 1)
          ]),
          filter: _withCtx(({filterModel}) => [
            _createVNode(_component_MultiSelect, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              options: _ctx.uniquePeriods,
              placeholder: "Any",
              filter: true
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "tradedClips",
          header: "Traded Clips",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "totalClips",
          header: "Total Clips",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "percentageTraded",
          header: "Percentage Traded",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "tradedRate",
          header: "Traded Rate",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "exposedRate",
          header: "Exposed Rate",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "totalRate",
          header: "Total Rate",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "tradedCost",
          header: "Traded Cost",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "exposedCost",
          header: "Exposed Cost",
          dataType: "numeric",
          sortable: true
        }),
        _createVNode(_component_Column, {
          field: "totalMTMCost",
          header: "Total MTM Cost",
          dataType: "numeric",
          sortable: true
        })
      ]),
      _: 1
    }, 8, ["value", "onRowSelect", "filters", "loading"])
  ]))
}