
   
    import { defineComponent } from 'vue';
    import { Lookup } from '@/services/Types';
    import { tradeService } from '@/services/TradeService';

    interface Data {
        commodities: Lookup[],
    }

    export default defineComponent({
        props: {
            tradingView: {
                type: String,
                required: true
            },
        },

        data(): Data {
            return {
                commodities:[]
            }
        },    
        computed: {   
            items() {

                // get commodities from database
                return this.commodities.map((c: Lookup) => ({ label: c.name, to: { name: this.tradingView, params: { commodity: c.name.toLowerCase().replaceAll(' ', '') } } }));
            },
        },
        async created() {
            this.commodities = await tradeService.getCommodities();
        }
    });
