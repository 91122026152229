import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, {
      value: _ctx.sites,
      selectionMode: "single",
      dataKey: "id",
      onRowSelect: _ctx.onRowSelect,
      responsiveLayout: "scroll"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "name",
          header: "Name"
        }),
        _createVNode(_component_Column, {
          field: "address.address1",
          header: "Address"
        }),
        _createVNode(_component_Column, {
          field: "address.town",
          header: "Town"
        }),
        _createVNode(_component_Column, {
          field: "address.county",
          header: "County"
        })
      ]),
      _: 1
    }, 8, ["value", "onRowSelect"])
  ]))
}