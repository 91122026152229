

    import { defineComponent, PropType } from 'vue';
    import Chip from 'primevue/chip';
    
    interface Data {
        loading: boolean,
    }

    export default defineComponent({
        props: {
            purchaseGroups: {
                type: Object as PropType<Array<any>>,
                required: true
            },
            periods: {
                type: Object as PropType<Array<any>>,
                required: true
            },
            displayPeriod: {
                type: Object as PropType<any>,
                required: false
            },
        },
        components: {
            Chip
        },
        data(): Data {
            return {
                loading: true,
            }
        },
        created() {
            this.fetchData();
        },
        computed: {   

        },
        methods: {
            async fetchData() {

                this.loading = false;

            },
        },

    });
