
    import { defineComponent, PropType } from 'vue';
    import { parse, ParseMeta } from "papaparse";
    import { Lookup } from '@/services/Types';
    import { Form, Field, ErrorMessage } from 'vee-validate';
    import * as yup from "yup";
    import dayjs from "dayjs";
    import customParseFormat from 'dayjs/plugin/customParseFormat';
    dayjs.extend(customParseFormat);
    import Message from 'primevue/message';
    import DocumentUpload from '@/components/Common/DocumentUpload.vue';
    import { consumptionService, ConsumptionImport, ConsumptionReading } from '@/services/ConsumptionService';

    interface Data {
        loading: boolean,
        validationSchema: any,
        consumptionImport: ConsumptionImport,
        utilityTypes: Lookup[],
        baseUtilityTypeId: number,
        baseUtilityTypes: Lookup[],
        newReading: ConsumptionReading,
        newImport: File | null,
        consumptionImports: any[],
        contents: any[],
        parsed: boolean,
        isError: boolean,
    }

    export default defineComponent({

        props: {
            editImport: {
                type: Object as PropType<ConsumptionImport>,
                required: true
            },
        },
        components: {
            Form,
            Field,
            ErrorMessage,
            DocumentUpload,
            Message,
        },
        data(): Data {

            const validationSchema = yup.object({
                utilityTypeId: yup.number().required().label("Profile Type"),
                baseUtilityTypeId: yup.number().required().label("Utility Type"),
           });
            return {
                loading: false,
                validationSchema: validationSchema,
                consumptionImport: this.editImport,
                utilityTypes: [],
                baseUtilityTypeId: 0, //this.editImport.utilityTypeId == 3 ? 3 : 2,
                baseUtilityTypes: [{ id: 3, name: "Gas" }, { id: 2, name: "Electricity" }],
                newImport: null,
                consumptionImports: [],
                contents: [],
                parsed: false,
                isError: false,
                newReading: {
                    id: 0,
                    consumptionImportId: 1,
                    mpanCore: '',
                    utilityTypeId: 2,
                    readTime: dayjs().toDate(),
                    duration: 1800,
                    usage: 0,
                }
            };
        },
        emits: [
            "submit:import",
            "update:import",
            "select:import",
            "remove:import",
        ],
        created() {
            // fetch on init
            this.fetchData();
        },
        watch: {
            async editImport(newValue: ConsumptionImport) {
                if (newValue.id > 0) {
                    console.log("document uploaded");

                    this.consumptionImport = newValue;

                    var loaded = [];

                    //Only import contents if no parsing errors encountered
                    if (this.isError) {
                        this.consumptionImport.statusId = 2;
                    } else {
                        this.contents = this.contents.map(reading => ({ ...reading, consumptionImportId: newValue.id }));
                        loaded = await consumptionService.addBulkReading(this.contents);
                    }

                    if (loaded && Array.isArray(loaded) && loaded.length > 0) {
                        this.consumptionImport.statusId = 1;
                    } else {
                        this.consumptionImport.statusId = 2;
                        alert(loaded);
                    }
                    // Pass this back up to parent to update
                    this.$emit('update:import', this.consumptionImport);
                }
            },
        },
        methods: {
            onSubmit() {

                let editImport = {
                    ...this.consumptionImport,
                    rawFile: this.consumptionImports[0]
                } 
                console.log("Submitting:", editImport);
                this.$emit('submit:import', editImport);

            },
            async fetchData() {

                //this.utilityTypes = await productService.getUtilityTypes();
                this.utilityTypes = this.lookupUtilityTypes(this.baseUtilityTypeId);

                this.loading = false;

            },
            onUtilityChange(event: any) {
                console.log("Utility selected:", event.value);
                this.utilityTypes = this.lookupUtilityTypes(event.value);
                this.onRemoveImport(0);
            },
            onSubUtilityChange() {
                this.onRemoveImport(0);
            },
            lookupUtilityTypes (utilId: number) {
                if (utilId == 2) {
                    this.consumptionImport.utilityTypeId = 1;
                    return [
                        { id: 1, name: "HH" },
                        { id: 2, name: "NHH" },
                    ];
                }
                else {
                    this.consumptionImport.utilityTypeId = 3;
                    return [
                        { id: 3, name: "Gas" },
                    ];
                }
            },
            onSelectImport(files: File[]) {
                this.consumptionImports = files.length > 0 ? files : [];
                this.newImport = files.length > 0 ? files[0] : null;
                //console.log('Selected:', this.newImport);
                this.consumptionImport.name = this.newImport ? this.newImport.name : '';
                this.parseFile();
                if (!this.isError) this.$emit('select:import');
            },
            onRemoveImport(index: number) {
                let removedFile = this.consumptionImports.splice(index, 1)[0];
                this.consumptionImports = [...this.consumptionImports];
                this.contents = [];
                this.$emit('remove:import', removedFile);
            },
            parseFile() {
                parse(this.consumptionImports[0], {
                    download: true,
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    //transformHeader: (header: string) => header.replace(this.units, 'volume'),
                    complete: (result) => this.parseResults(result.data, result.meta),
                });
            },
            parseResults(results: any[], meta: ParseMeta) {
                console.log("read:", meta);
                if (results.length == 0) {
                    this.isError = true;
                    this.consumptionImport.errorMessage = "No entries could be found. The imported file does not match the expected format.";
                    this.consumptionImport.advice = "Please check the imported file and re-submit the csv file in the required format.";
                } else {
                    //process upload contents
                    this.isError = false;
                    this.contents = [];
                    var offset = dayjs().utcOffset();
                    var headerField = meta.fields != undefined ? meta.fields[2] : 'Usage';
                    results.forEach(row => {
                        var reading = this.newReading;
                        var readTime = row.Date ? dayjs(row.Date, "DD/MM/YYYY") : dayjs(reading.readTime);
                        readTime = readTime.add(offset, 'minute');

                        reading.utilityTypeId = this.consumptionImport.utilityTypeId;
                        if (reading.utilityTypeId == 3) { // Gas import
                            reading.mpanCore = row.MeterRef;
                            reading.duration = 86400;

                            //console.log(readTime.toISOString());
                            this.contents.push({
                                ...reading,
                                readTime: readTime.toDate(),
                                usage: this.validateNumberInput(String(row[headerField]), readTime.toDate()),
                            })
                        } else {
                            reading.mpanCore = String(row.MPAN).padStart(13, '0');
                            var fieldsToRead = reading.utilityTypeId == 1 ? 48 : 1;
                            for (var i = 0; i < fieldsToRead; i++) {
                                headerField = meta.fields != undefined ? meta.fields[i + 2] : 'Usage';
                                //console.log(readTime.toISOString());
                                this.contents.push({
                                    ...reading,
                                    readTime: readTime.toDate(),
                                    usage: this.validateNumberInput(String(row[headerField]), readTime.toDate()),
                                })
                                readTime = readTime.add(1800, 'seconds');
                            }
                        }
                    });
                    this.parsed = true;
               }

            },
            validateNumberInput(value: String, fieldValue: Date) {
                let numberPattern = /-?\d*\.?\d+/g;
                let numbers = value.match(numberPattern);

                if (numbers === null) {
                    this.isError = true;
                    this.consumptionImport.errorMessage = "Invalid field values encountered for " + fieldValue.toISOString();
                    this.consumptionImport.advice = "Please review the csv file on the date specified and fix the value before trying again.";
                    return 0;
                }

                return parseFloat(numbers.join(''));
            },
        }
    });
