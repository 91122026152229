
    import { defineComponent, PropType } from 'vue';
    import { customerContractService, CustomerContract } from '@/services/ContractService';
    import { productService, Product } from '@/services/ProductService';
    import SupplierProduct from '@/components/Contracts/SupplierProduct.vue';
    import KeyDetails from '@/components/Contracts/KeyDetails.vue';
    import DeliverySchedule from '@/components/Contracts/DeliverySchedule.vue';
    import ShapeFees from '@/components/Contracts/ShapeFees.vue';
    import SupplyPoints from '@/components/Contracts/SupplyPoints.vue';
    import SupplyPointCharges from '@/components/Contracts/SupplyPointCharges.vue';
    import VolumesTemplate from '@/components/Contracts/VolumesTemplate.vue';
    import CommissionFees from '@/components/Contracts/CommissionFees.vue';

    interface Data {
        loading: boolean,
        contract: CustomerContract,
        isSaving: boolean,
        activeIndex: number,
        visitedIndex: number,
        subForms: string[],
        activeProduct: Product | null,
        isNewContractCreated: boolean,  
        volumeKey: number
    }

    export default defineComponent({

        props: {
            editContract: {
                type: Object as PropType<CustomerContract>,
                required: true
            },
            editMode: Boolean,
        },
        components: {
            SupplierProduct,
            KeyDetails,
            DeliverySchedule,
            ShapeFees,
            SupplyPoints,
            SupplyPointCharges,
            VolumesTemplate,
            CommissionFees,
        },
        data(): Data {
            const subForms: string[] = [
                'contract-form',
                'contract-form-details',
                'contract-form-schedule',
                'contract-form-shapefees',
                'contract-form-supplypoints',
                'contract-form-supplypointcharges',
                'contract-form-volumes',
                'contract-form-commission',
            ];

            return {
                loading: true,
                contract: this.editContract,
                isSaving: false,
                activeIndex: 0,
                visitedIndex: 0,
                subForms: subForms,
                activeProduct: null,
                isNewContractCreated: false, 
                volumeKey: 0
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            gotoPage(index: number) {
                console.log("Jump to ", index.toString());
                if (this.visitedIndex >= index) this.activeIndex = index;
            },
            nextPage() {
                console.log("Next.");
                //Skip Shape Fees page if not required
                this.activeIndex = this.activeIndex == 2 && !this.activeProduct?.isShapeFee ? this.activeIndex + 2 : this.activeIndex + 1;
                this.visitedIndex = this.activeIndex;
            },
            prevPage() {
                console.log("Prev.");
                this.activeIndex = this.activeIndex > 0 ? this.activeIndex - 1 : 0;
            },
            async fetchData() {

                //this.contract = await customerContractService.getCustomerContract(this.contractId);
                await this.onProductSelect(this.contract.productId);

                this.loading = false;
            },
            async onProductSelect(selected: any) {
                if (selected > 0) {
                    this.activeProduct = await productService.getProductById(selected);
                    console.log("Product:", this.activeProduct);
                }
            },
            async onUpdateContract(updateContractEvent: any, navigate = true) {

                this.isSaving = true;

                var updateContract = {
                    ...this.contract,
                    ...updateContractEvent,
                };

                if (this.activeIndex == 1 && updateContract.id == 0) {
                    if (!this.isNewContractCreated) {
                        updateContract = await customerContractService.addCustomerContract(updateContract);
                        this.$emit('update:contract', updateContract);
                        this.isNewContractCreated = true;
                    }
                } else if (this.activeIndex > 0) {
                    await customerContractService.updateCustomerContract(updateContract);
                    this.$emit('update:contract', updateContract);
                } else {
                    console.log("No need to save here.");
                }

                this.contract = updateContract;

                //if last page of contract process inform parent to update contract list
                if (this.activeIndex == (this.subForms.length - 1)) {
                    this.$emit('add:contract', updateContract);
                }

                this.isSaving = false;

                if (navigate) {
                    //advance to the next step of the process
                    this.nextPage();
                } else {
                    //refresh the volume component.
                    this.volumeKey++;
                }
            },
        }
    });
