import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid"
}
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_Column = _resolveComponent("Column")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, {
    id: "contract-form-commission",
    onSubmit: _ctx.onSubmit,
    "validation-schema": _ctx.validationSchema
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Card, null, {
        header: _withCtx(() => []),
        content: _withCtx(() => [
          (_ctx.schedule)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  (!_ctx.editMode)
                    ? (_openBlock(), _createElementBlock("h2", _hoisted_3, "Add Commission"))
                    : _createCommentVNode("", true),
                  _createVNode(_component_Field, {
                    name: "customerContractId",
                    type: "hidden",
                    modelValue: _ctx.contract.id,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contract.id) = $event))
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_DataTable, {
                    value: _ctx.schedule,
                    editMode: "cell",
                    onCellEditComplete: _ctx.onCellEditComplete,
                    tableStyle: "min-width: 50rem",
                    class: "mb-8"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        field: "title",
                        header: ""
                      }, {
                        body: _withCtx(({ index }) => [
                          _createTextVNode(_toDisplayString(`Delivery Window ${index + 1}`), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_Column, {
                        field: "startDate",
                        header: "Start Date"
                      }, {
                        body: _withCtx((slotProps) => [
                          _createTextVNode(_toDisplayString(_ctx.formatDate(slotProps.data.startDate)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_Column, {
                        field: "endDate",
                        header: "End Date"
                      }, {
                        body: _withCtx((slotProps) => [
                          _createTextVNode(_toDisplayString(_ctx.formatDate(slotProps.data.endDate)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_Column, {
                        field: "commissionPrimary",
                        header: "Commission Primary p/kWh"
                      }, {
                        body: _withCtx(({ data }) => [
                          _createTextVNode(_toDisplayString(_ctx.formatNumber(data.commissionPrimary, 3) ?? "Click to enter value"), 1)
                        ]),
                        editor: _withCtx(({ data }) => [
                          _createVNode(_component_InputNumber, {
                            modelValue: data.commissionPrimary,
                            "onUpdate:modelValue": ($event: any) => ((data.commissionPrimary) = $event),
                            minFractionDigits: 0,
                            maxFractionDigits: 3,
                            autofocus: ""
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_Column, {
                        field: "commissionSecondary",
                        header: "Commission Secondary p/kWh"
                      }, {
                        body: _withCtx(({ data }) => [
                          _createTextVNode(_toDisplayString(_ctx.formatNumber(data.commissionSecondary, 3) ?? "Click to enter value"), 1)
                        ]),
                        editor: _withCtx(({ data }) => [
                          _createVNode(_component_InputNumber, {
                            modelValue: data.commissionSecondary,
                            "onUpdate:modelValue": ($event: any) => ((data.commissionSecondary) = $event),
                            minFractionDigits: 0,
                            maxFractionDigits: 3,
                            autofocus: ""
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value", "onCellEditComplete"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onSubmit", "validation-schema"]))
}