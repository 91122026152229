import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "col-12 flex justify-content-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PurchaseGroupContractItem = _resolveComponent("PurchaseGroupContractItem")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.purchaseGroupContract)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PurchaseGroupContractItem, {
          editContract: _ctx.purchaseGroupContract,
          "onSubmit:purchaseGroupContract": _ctx.onSubmit
        }, null, 8, ["editContract", "onSubmit:purchaseGroupContract"]),
        _createVNode(_component_Divider),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Button, {
              type: "submit",
              form: "purchase-group-contract-form",
              icon: "pi pi-save",
              label: "Save",
              class: "p-button-lg",
              disabled: _ctx.isSaving
            }, null, 8, ["disabled"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}