
    import { defineComponent } from 'vue';
    import { purchaseGroupService, PurchaseGroupContract } from '@/services/PurchaseGroupService';
    import { customerContractService, CustomerContract } from '@/services/ContractService';

    import PurchaseGroupContractItem from '@/components/PurchaseGroups/PurchaseGroupContractItem.vue';

    interface Data {
        loading: boolean,
        purchaseGroupContract: PurchaseGroupContract | null,
        customerContract: CustomerContract | null,
        isSaving: boolean,
    }

    export default defineComponent({

        props: {
            purchaseGroupContractId: {
                type: Number,
                required: true
            },
        },
        components: {
            PurchaseGroupContractItem,
        },
        data(): Data {

            return {
                loading: false,
                purchaseGroupContract: null,
                customerContract: null,
                isSaving: false,
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                console.log("fetch data...")

                this.loading = true;

                this.purchaseGroupContract = await purchaseGroupService.getPurchaseGroupContractById(this.purchaseGroupContractId);
                if (this.purchaseGroupContract) {
                    this.customerContract = await customerContractService.getCustomerContract(this.purchaseGroupContract.customerContractId);
                    this.purchaseGroupContract.customerId = this.customerContract?.customerId ?? 0;
                }
                this.loading = false;
                
            },
            async onSubmit(updatePurchaseGroupContract: any) {
                this.isSaving = true;

                console.log("Submitting:", updatePurchaseGroupContract);

                await purchaseGroupService.updatePurchaseGroupContract(updatePurchaseGroupContract);

                this.$emit('update:purchaseGroupContract', updatePurchaseGroupContract);

                this.isSaving = false;

                console.log("save successful!");
                this.$router.push({ name: 'editPurchaseGroup', params: { purchaseGroupId: Number(this.purchaseGroupContract?.purchaseGroupId) } })
                
            },
        }
    });
