
    import { defineComponent, PropType } from 'vue';
    import { contactService, Contact, ContactType, CountryCode } from '@/services/ContactService';
    import { customerService, Customer } from '@/services/CustomerService';
    import { siteService, Site } from '@/services/SiteService';
    import * as yup from "yup";
    import { Form, Field, ErrorMessage } from 'vee-validate';
    import { RegEx } from '@/composables/RegularExpressions';
    import MultiSelect from 'primevue/multiselect';

    interface Data {
        loading: boolean,
        validationSchema: any,
        contact: Contact,
        customer: Customer | null,
        sites: Site[],
        selectedSiteIds: number[],
        selectedContactTypeIds: number[],
        contactTypes: ContactType[],
        countryCodes: CountryCode[],
        countryCodePhone: CountryCode | null | undefined,
        countryCodeMobile: CountryCode | null | undefined,
        countryCodeDirect: CountryCode | null | undefined,
    }

    export default defineComponent({

        props: {
            editContact: {
                type: Object as PropType<Contact>,
                required: true
            },
        },
        components: {
            MultiSelect,
            Form,
            Field,
            ErrorMessage,
        },
        data(): Data {

            const validationSchema = yup.object({
                customerId: yup.number().required(),
                firstName: yup.string().required().label("First Name"),
                lastName: yup.string().required().label("Last Name"),
                email: yup.string().required().email().label("Email"),
                countryCodePhone: yup.object().nullable().label("Country Code Phone"),
                phone: yup.string().nullable().label("Main Phone Number").matches(RegEx.TELEPHONE, { message: 'Must be a valid telephone number without the international dialling code - e.g. 01234567890', excludeEmptyString: true }),
                countryCodeMobile: yup.object().nullable().label("Country Code Mobile"),
                mobile: yup.string().nullable().label("Mobile").matches(RegEx.MOBILE, { message: 'Must be a valid mobile number without the international dialling code - e.g 07513438167', excludeEmptyString: true }),
                countryCodeDirect: yup.object().nullable().label("Country Code Direct"),
                directDial: yup.string().nullable().label("Direct Dial").matches(RegEx.TELEPHONE, { message: 'Must be a valid telephone number without the international dialling code - e.g. 01234567890', excludeEmptyString: true }),
                jobTitle: yup.string().nullable().label("Job Title"),
                //customerName: yup.string().nullable().label("Customer"),
                //contactTypeId: yup.number().required().label("Contact Type"),
                selectedSiteIds: yup.array().nullable().label("Sites"),
                selectedContactTypeIds: yup.array().nullable().label("Contact Types")
            });
            return {
                loading: false,
                validationSchema: validationSchema,
                contact: this.editContact,
                customer: null,
                sites: [],
                contactTypes: [],
                selectedSiteIds: [],
                selectedContactTypeIds: [],
                countryCodes: contactService.getCountryCodes(),
                countryCodePhone: null,
                countryCodeMobile: null,
                countryCodeDirect: null,
            };
        },
        created() {
            // fetch on init
            this.fetchData();
       },
        methods: {
            onSubmit(values: any) {
                console.log(values);

                //Clear Selected sites if not Site Contact type
                if (!this.selectedContactTypeIds.includes(3)) this.selectedSiteIds = [];

                let editContact = {
                    ...this.contact,
                    selectedSiteIds: this.selectedSiteIds,
                    selectedContactTypeIds: this.selectedContactTypeIds,
                    countryCodePhone: this.countryCodePhone?.code,
                    countryCodeMobile: this.countryCodeMobile?.code,
                    countryCodeDirect: this.countryCodeDirect?.code,
                } 
                this.$emit('submit:contact', editContact);

            },
            async fetchData() {

                this.contactTypes = await contactService.getContactTypes();
                this.selectedContactTypeIds = this.contact.contactTypes.map(ct => ct.id);
                this.customer = await customerService.getCustomerById(this.contact.customerId);
                this.sites = await siteService.getSitesForCustomer(this.contact.customerId);
                let selectedSites = this.contact.id > 0 ? await contactService.getSitesByContactId(this.contact.id) : [];
                this.selectedSiteIds = selectedSites.map(ss => ss.id);

                const defaultCode = this.lookupCountryCode(44);
                this.countryCodePhone = this.contact?.countryCodePhone ? this.lookupCountryCode(this.contact?.countryCodePhone) : defaultCode;
                this.countryCodeMobile = this.contact?.countryCodeMobile ? this.lookupCountryCode(this.contact?.countryCodeMobile) : defaultCode;
                this.countryCodeDirect = this.contact?.countryCodeDirect ? this.lookupCountryCode(this.contact?.countryCodeDirect) : defaultCode;

                this.loading = false;

            },
            lookupCountryCode(code: number | null) {
                if (code === null) return null;
                const foundCode = this.countryCodes?.find(cc => cc.code == code);
                return foundCode;
            }
        }
    });
