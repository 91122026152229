import { serialize } from 'object-to-formdata';
import dayjs from 'dayjs';

export const getFormData = (object: any) => {

    const options = {
        noFilesWithArrayNotation: true,
        indices: true,
    }
    const formData = serialize(object, options);

    console.log("form data...");
    console.log([...formData]);

    return formData;
}

export const formatDate = (dateToFormat: string, format: string = 'DD/MM/YYYY') => {
    return dateToFormat ? dayjs(dateToFormat).format(format) : null
}

export const formatDateForBackend = (dateToFormat: string | Date | null | undefined) => {
    return dateToFormat ? dayjs(dateToFormat).format('YYYY-MM-DD') : null;
}