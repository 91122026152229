
    import { defineComponent } from 'vue';
    import { purchaseGroupService, PurchaseGroup, Volume } from '@/services/PurchaseGroupService';
    import { periodService, PeriodPrice, Period } from '@/services/PeriodService';
    import { Trade, tradeService } from '@/services/TradeService';
    import { Lookup } from '@/services/Types';
    import TradePurchaseGroupPeriods from '@/components/Trading/MarketTrading/TradePurchaseGroupPeriods.vue';
    import TradeItem from '@/components/Trading/MarketTrading/TradeItem.vue';
    import {VolumeService} from "@/services/VolumeService";
    //import MultiSelect from 'primevue/multiselect';

    interface Data {
        loading: boolean,
        loadingFixedVolumes: boolean,
        purchaseGroupId: number,
        purchaseGroups: PurchaseGroup[],
        periodPrices: PeriodPrice[],
        quarterPrices: PeriodPrice[],
        seasonPrices: PeriodPrice[],
        periods: Period[],
        quarters: Period[],
        seasons: Period[],
        selectedPurchaseGroups: PurchaseGroup[],
        selectedPG: PurchaseGroup | null,
        selectedPeriods: Period[],
        selectedPeriod: Period | null,
        newTrade: Trade | null,
        addTradeDialog: boolean,
        sellTradeSelected: boolean,
        isSaving: boolean,
        commodityTypes: Lookup[],
    }
    export default defineComponent({
        props: {
            commodity: {
                type: String,
                required: true
            },
        },
        components: {
            TradePurchaseGroupPeriods,
            //MultiSelect,
            TradeItem
        },
        data(): Data {
            return {
                loading: true,
                loadingFixedVolumes: false,
                purchaseGroupId: 0,
                purchaseGroups: [],
                periodPrices: [],
                quarterPrices: [],
                seasonPrices: [],
                periods: [],
                quarters: [],
                seasons: [],
                selectedPurchaseGroups: [],
                selectedPG: null,
                selectedPeriods: [],
                selectedPeriod: null,
                newTrade: null,
                addTradeDialog: false,
                sellTradeSelected: false,
                isSaving: false,
                commodityTypes: [],
            }
        },
        created() {
            this.fetchData();
        },
        watch: {
            async selectedPG(newPG: PurchaseGroup) {
                newPG.volumes = await purchaseGroupService.getVolumesByPurchaseGroupId(newPG.id);
                this.selectedPurchaseGroups = [newPG];
            }
        },
        computed: {
          VolumeService() {
            return VolumeService
          },
            hasPeriodSelected() {
                return this.validSelectedPeriods.length > 0;
            },
            commonPurchaseGroupPeriods() {
                // get the list of periods which are valid for all selected purchase group volumes
                const purchaseGroupVolumeArrays = this.selectedPurchaseGroups.map(pg => pg.volumes) ?? [];
                // only return periods if there are selected purchase groups
                let commonPeriodPrices: PeriodPrice[] = purchaseGroupVolumeArrays.length > 0 ? 
                    purchaseGroupVolumeArrays.reduce((previous, current) => this.filterPeriodsArray(previous, current), this.periodPrices) 
                    : [];
                
                const commonPeriods: Period[] = purchaseGroupVolumeArrays.length > 0 ? purchaseGroupVolumeArrays.reduce((previous, current) => this.filterPeriodsArray(previous, current), this.periods) : [];
                //Populate openVolume in periodPrices from purchaseGroups volumes
                commonPeriodPrices = commonPeriodPrices.map(pp => ({
                    ...pp,
                    openVolume: purchaseGroupVolumeArrays?.reduce((vs, va) => va, []).find(v => v.periodId == pp.id)?.exposedVolume
                }));
                console.log('commonPeriodPrices', commonPeriodPrices);
                const commonPeriodsInFuture: Period[] = commonPeriods.filter(p => new Date(p.startDate) > new Date());

                var combinedPeriod = commonPeriodsInFuture.map(p => {
                    let periodPrice: PeriodPrice | undefined = commonPeriodPrices.find(pp => pp.id == p.id);
                    const tradedVol = purchaseGroupVolumeArrays[0].find(v => v.periodId == p.id)?.tradedVolume;
                    // 
                    p.tradedVolume = tradedVol;
                    
                    if (periodPrice) {
                        periodPrice.tradedVolume = tradedVol;
                        return periodPrice;
                    }
                    return {
                        ...p,
                    };
                })

                if (combinedPeriod) {
                    //For every quarter, ensure that every child has a periodPrice present
                    var quartersFull: PeriodPrice[] = this.quarters.filter(q => q.children.map(c => c.id).every(c => commonPeriodsInFuture.map(cp => cp.id).includes(c)));
                    //If so, add up all the child openVolumes
                    quartersFull = quartersFull.map(q => ({
                        ...q,
                        openVolume: q.children.reduce((minVolume, c) => {
                            var matchedPeriod = combinedPeriod.find(cp => cp.id == c.id);
                            return (matchedPeriod?.openVolume != undefined) && (matchedPeriod.openVolume <= minVolume) ? matchedPeriod?.openVolume : minVolume;
                        }, Infinity),
                        tradedVolume: q.children.reduce((minVolume, c) => {
                            var tradedmatchedPeriod = purchaseGroupVolumeArrays[0].find(cp => cp.periodId == c.id);
                            return (tradedmatchedPeriod?.tradedVolume != undefined) && (tradedmatchedPeriod.tradedVolume <= minVolume) ? tradedmatchedPeriod?.tradedVolume : minVolume;
                        }, Infinity)
                    }));
                    quartersFull = quartersFull.flatMap(q => {
                        var qprice = this.quarterPrices.find(qp => qp.id == q.id);
                        return [{
                                ...q,
                            bid: qprice?.bid,
                            offer: qprice?.offer,
                            change: qprice?.change,
                            openVolume: q.openVolume != undefined && isFinite(q.openVolume) ? q.openVolume : undefined
                            }]
                    });
                    // and add the quarter period to combined
                    quartersFull.forEach(q => combinedPeriod.push(q));

                    //For every season, ensure that every quarter has open monthly periodsf
                    var seasonsFull: PeriodPrice[] = this.seasons.filter(s => s.children.map(c => c.id).every(c => quartersFull.map(q => q.id).includes(c)));
                    //If so, add up all the child openVolumes
                    seasonsFull = seasonsFull.map(s => ({
                        ...s,
                        openVolume: s.children.reduce((minVolume, c) => {
                            var matchedPeriod = quartersFull.find(cp => cp.id == c.id);
                            return (matchedPeriod?.openVolume != undefined) && (matchedPeriod.openVolume <= minVolume) ? matchedPeriod?.openVolume : minVolume;
                        }, Infinity),
                        tradedVolume: s.children.reduce((minVolume, c) => {
                            var tradedmatchedPeriod = quartersFull.find(cp => cp.id == c.id);
                            return (tradedmatchedPeriod?.tradedVolume != undefined) && (tradedmatchedPeriod.tradedVolume <= minVolume) ? tradedmatchedPeriod?.tradedVolume : minVolume;
                        }, Infinity)
                    })).map(s => {
                        var sprice = this.seasonPrices.find(sp => sp.id == s.id);
                            return {
                                ...s,
                            bid: sprice?.bid,
                            offer: sprice?.offer,
                            change: sprice?.change,
                            openVolume: s.openVolume != undefined && isFinite(s.openVolume) ? s.openVolume : undefined
                            }
                    });
                    // and add the quarter period to combined
                    seasonsFull.forEach(s => combinedPeriod.push(s));
                }


                return combinedPeriod;
            },
            validSelectedPeriods() {
                // calculate valid periods based common periods across selected purchase groups. This allows us to keep track of previously selected periods in case purchase group selection changes.
                let validSelectedPeriods: PeriodPrice[] = [];

                if (this.selectedPeriod && this.selectedPeriod.children && (this.selectedPeriod.children.length > 0)) {
                    //if season
                    if (this.selectedPeriod.name[0] == "S" || this.selectedPeriod.name[0] == "W") {
                        //get all monthly periods from quarters
                        var quarters = this.quarters.filter(q => this.selectedPeriod?.children.find(quarter => quarter.id == q.id));
                        quarters.forEach(quart =>
                            quart.children.forEach(childMonthPeriod => {
                                validSelectedPeriods.push(childMonthPeriod);
                            })
                        );
                    }
                    else //is quarter
                        validSelectedPeriods = this.selectedPeriod?.children;
                } else { //is month
                    validSelectedPeriods = [...this.commonPurchaseGroupPeriods.filter((period: Period) => period.id == this.selectedPeriod?.id)];
                }

                return validSelectedPeriods;
            },
            fixedVolumeHeader() {
                return this.commodityTypeId === 1 ? 'Fixed volume (therms/day)' : 'Fixed volume (MW)';
            },
            openVolumeHeader() {
                return this.commodityTypeId === 1 ? 'Open volume (therms/day)' : 'Open volume (MW)';
            },
            commodityTypeId() {

                const type = this.commodityTypes.find(ct => ct.name.toLowerCase().replaceAll(' ', '') === this.commodity);
                return type?.id ?? 0; // should never hit this...
            },

        },
        methods: {
            getPriceDisplay(price: any) {
              if(price === null || price === undefined) return "-";
              if(price !== 0) return price;
              return "-";
            },
            async fetchData() {

                this.commodityTypes = await tradeService.getCommodities();
                this.periodPrices = await periodService.getPeriodPricesForCommodity(this.commodityTypeId);
                this.periods = await periodService.getPeriods();
                this.quarters = await periodService.getChildPeriods(2);
                this.quarterPrices = await periodService.getPeriodPricesForCommodityLevel(this.commodityTypeId, 2);
                this.seasons = await periodService.getChildPeriods(1);
                this.seasonPrices = await periodService.getPeriodPricesForCommodityLevel(this.commodityTypeId, 1);

                this.purchaseGroups = await purchaseGroupService.getOpenPurchaseGroupsForCommodity(this.commodityTypeId) as PurchaseGroup[];
                this.loading = false;
            },
            filterPeriodsArray(periods: Period[], volumes: Volume[]) {
                return periods.filter((period: Period) => volumes.some((volume: Volume) => period.id === volume.periodId));
            },



            async addNewTrade(tradeType: boolean) {

                const totalOpenVolumes = await purchaseGroupService.getOpenVolumes(this.selectedPurchaseGroups, this.validSelectedPeriods);
                const minOpenVolume = totalOpenVolumes.reduce((prev, current) => (prev.exposedVolume < current.exposedVolume) ? prev : current);
                this.newTrade = {
                    id: 0,
                    tradeStatusId: 1,
                    tradeDateTime: new Date(),
                    indexCharge: 0,
                    sellBackCharge: 0,
                    commodityTypeId: this.commodityTypeId,
                    purchaseGroups: this.selectedPurchaseGroups,
                    periods: this.validSelectedPeriods,
                    totalOpenVolume: minOpenVolume.exposedVolume,
                    totalTradeVolume: totalOpenVolumes.reduce((acc, cur) => acc + cur.tradedVolume, 0)
                },

                    this.sellTradeSelected = tradeType;

                this.addTradeDialog = true;
            },
            async onAddTrade(addTradeEvent: any) {

                this.isSaving = true;

                const newTrade = {
                    ...addTradeEvent,
                    selectedPurchaseGroupIds: this.selectedPurchaseGroups.map(pg => pg.id),
                    selectedPeriodIds: this.validSelectedPeriods.map(p => p.id)
                }

                // negate trade volume if a sell trade
                newTrade.totalTradeVolume = this.sellTradeSelected ? (newTrade.totalTradeVolume * -1) : newTrade.totalTradeVolume;

                await tradeService.addTrade(newTrade)

                this.$emit('add:trade', newTrade);

                this.isSaving = false;
                this.addTradeDialog = false;

                // now navigate to previous trading screen.
                this.$router.push({ name: 'previousTrading', params: { commodity: this.commodity } });

            },
        },
    });
