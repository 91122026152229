
    import { defineComponent } from 'vue';
    import { siteService, Site } from '@/services/SiteService';
    import SiteItem from '@/components/Sites/SiteItem.vue';
    import SiteList from '@/components/Sites/SiteList.vue';

    interface Data {
        loading: boolean,
        sites: Site[] | null,
        newSite: Site | null,
        addSiteDialog: boolean,
    }

    export default defineComponent({
        props: ['customerId'],
        data(): Data {
            return {
                loading: true,
                sites: [],
                newSite: null,
                addSiteDialog: false,
            };
        },
        components: {
            SiteItem,
            SiteList
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                this.sites = await siteService.getSitesForCustomer(this.customerId);

                this.loading = false;
            },
            addNewSite() {
                console.log("add new site!");

                this.newSite = {
                    id: 0,
                    name: "new site",
                    customerId: this.customerId,
                    address: {}
                };

                this.addSiteDialog = true;
            },
            async onAddSite(addSiteEvent: any) {

                await siteService.addSite(addSiteEvent)
                
                this.$emit('add:site', addSiteEvent);
                    

                console.log("save successful!")

                // now refresh main list of sites...
                this.sites = await siteService.getSitesForCustomer(this.customerId);
                
                this.addSiteDialog = false;
            },
        }
    });
