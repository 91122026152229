
    import { defineComponent, PropType } from 'vue';
    import { supplyPointService, NHHSupplyPoint, MeterOperator, ProfileClass, TCRBanding, Voltage, MeterType, DNO } from '@/services/SupplyPointService';
    import * as yup from "yup";
    import { Form, Field, ErrorMessage } from 'vee-validate';
    import { RegEx } from '@/composables/RegularExpressions';
    import { formatDate } from '@/composables/UtilityFunctions';

    interface Data {
        loading: boolean,
        editMode: boolean,
        validationSchema: any,
        supplyPoint: NHHSupplyPoint
        meterOperators: MeterOperator[],
        profileClasses: ProfileClass[],
        tcrBandings: TCRBanding[],
        voltages: Voltage[],
        meterTypes: MeterType[],
        dnos: DNO[],
    }

    export default defineComponent({

        props: {
            editSupplyPoint: {
                type: Object as PropType<NHHSupplyPoint>,
                required: true
            },
        },
        components: {
            Form,
            Field,
            ErrorMessage
        },
        data(): Data {

            const typeErrorMessage = "Please enter a value";

            const validationSchema = yup.object({
                siteId: yup.number().required(),
                name: yup.string().nullable().label('Supply Point Name'),
                annualConsumption: yup.number().typeError(typeErrorMessage).nullable().label('Annual Consumption'),
                budgetCost: yup.number().typeError(typeErrorMessage).nullable().label('Budget Cost'),
                meterOperatorRenewalDate: yup.date().nullable().label('Meter Operator Renewal Date'),
                meterOperatorId: yup.number().nullable(),
                amrEnabled: yup.boolean(),
                fivePercentVATEnabled: yup.boolean(),
                accountNumber: yup.string().nullable().label("Supplier Account Number").matches(RegEx.ALPHANUMERIC, { message: 'Supplier Account Number must be alphanumeric', excludeEmptyString: true }),
                profileClassId: yup.number().required().label("Profile Class"),
                llf: yup.string().nullable().matches(RegEx.THREE_DIGITS, { message: 'Must be 3 digits', excludeEmptyString: true } ),
                mts: yup.string().nullable().matches(RegEx.THREE_ALPHANUMERIC, { message: 'Must be 3 chars', excludeEmptyString: true }),
                mpanCore: yup.string().nullable().matches(RegEx.THIRTEEN_DIGITS, { message: 'Must be 13 digits', excludeEmptyString: true }),
                supplyCapacity: yup.number().nullable().label('Supply Capacity'),
                tcrBandingId: yup.number().nullable(),
                isEnergised: yup.boolean(),
                dnoId: yup.number().nullable(),
                meterTypeId: yup.number().nullable(),
            });

            return {
                loading: true,
                editMode: false,
                validationSchema: validationSchema,
                meterOperators: [],
                profileClasses: [],
                tcrBandings:[],
                voltages: [],
                meterTypes: [],
                supplyPoint: this.editSupplyPoint,
                dnos:[],
            };
        },
        created() {

            this.editMode = this.supplyPoint.id > 0;

            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                this.meterOperators = await supplyPointService.getMeterOperators();
                this.profileClasses = await supplyPointService.getProfileClasses();
                this.tcrBandings = await supplyPointService.getTCRBandings();
                this.voltages = await supplyPointService.getVoltages();
                this.meterTypes = await supplyPointService.getMeterTypes();
                this.dnos = await supplyPointService.getDNOs();

                this.loading = false;                
            },
            onSubmit(values: any) {
                console.log(values);

                if (this.editMode) {

                    this.$emit('update:supplyPoint', this.supplyPoint);
                }
                else {

                    this.$emit('add:supplyPoint', this.supplyPoint);
                }
            },
            formatDate(dateToFormat: string) {
                return formatDate(dateToFormat);
            }
        },
        computed: {
            selectedDNO():string {
                const dno = this.dnos.find(dno => dno.id == this.editSupplyPoint.dnoId);
                return `${dno?.name} - ${dno?.id}`; 
            },
            formattedProfileClasses():ProfileClass[] {

                let contactsWithCustomers = this.profileClasses ? this.profileClasses.map(pc => ({...pc, fullId: pc.id.toLocaleString('en-GB', {minimumIntegerDigits: 2, useGrouping:false}) })) : [];
                return contactsWithCustomers;
            }  
        }
    });
