
    import { defineComponent } from 'vue';
    import { supplyPointService, SupplyPoint } from '@/services/SupplyPointService';
    import SupplyPointComponent from '@/components/SupplyPoints/SupplyPoint.vue';

    interface Data {
        loading: boolean,
        supplyPoint: SupplyPoint | null
    }

    export default defineComponent({

        props: {
            customerId: {
                type: Number,
                required: true
            },
            supplyPointId: {
                type: Number,
                required: true
            }
        },
        components: {
            SupplyPointComponent,
        },
        data(): Data {

            return {
                loading: true,
                supplyPoint: null
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                this.supplyPoint = await supplyPointService.getSupplyPoint(this.supplyPointId);

                this.loading = false;
            },
        },
    });
