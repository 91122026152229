
    import { defineComponent } from 'vue';
    import VueApexCharts from "vue3-apexcharts";
    //import { jsPDF } from 'jspdf';
    import { TradeReportData, TradeReportCustomerFilter, tradeService } from '@/services/TradeService';
    import { purchaseGroupService, PurchaseGroup } from '@/services/PurchaseGroupService';
    import { formatDate } from '@/composables/UtilityFunctions';
    import { Customer } from '@/services/CustomerService';
    import { siteService, SiteSupplyPoints } from '@/services/SiteService';
    import { SupplyPoint } from '@/services/SupplyPointService';
    import TradesMtoMList from '@/components/Reports/Trades/TradesMtoMList.vue';
    import { Lookup } from '@/services/Types';

    interface Data {
        loading: boolean,        
        labels: any,
        tradesReportData: TradeReportData[],
        totalMTMCosts: any,
        totalRates:any,
        series: any,
        chartOptions: any, 
        selectedPG: PurchaseGroup | null, 
        selectedPurchaseGroups: PurchaseGroup[],
        purchaseGroups: PurchaseGroup[],
        selectedCustomer: Customer | null,
        selectedCustomers: Customer[],
        customers: Customer[],
        commodityTypes: Lookup[],
        siteSupplyPoints: SiteSupplyPoints[],
        selectedSupplyPoint: SupplyPoint | null,
        dateRange: Date[] | null,
        startDate: Date | undefined,
        endDate: Date | undefined     
    }

    export default defineComponent({
        props: {    
            commodity: {
                type: String,
                required: true,
                default: 'gas'
            },        
        },
        components: {
            apexchart: VueApexCharts,
            TradesMtoMList
        },
        data(): Data {
            return {
                loading: true,                
                labels: [],                
                tradesReportData: [],
                totalMTMCosts: [],
                totalRates: [],
                series: [],
                chartOptions: {},
                selectedPG: null,
                selectedPurchaseGroups: [],
                purchaseGroups: [],
                selectedCustomer: null,
                selectedCustomers: [],
                customers: [],
                commodityTypes: [],
                siteSupplyPoints: [],
                selectedSupplyPoint: null,
                dateRange: null,
                startDate: undefined,
                endDate: undefined
            };
        },
        created() {
            this.fetchData();
        },
        methods: {
            async fetchData() {                
                this.commodityTypes = await tradeService.getCommodities();
                this.purchaseGroups = await purchaseGroupService.getOpenPurchaseGroupsForCommodity(this.commodityTypeId) as PurchaseGroup[];                
                this.loading = false;            
            },
            async getSupplyPoints(customerId: number) {
                return await siteService.getSiteSupplyPointsForCustomer(customerId);  
            },
            async getCustomers(purchaseGroupId: number) {
                return await purchaseGroupService.getCustomersByPurchaseGroupId(purchaseGroupId);                
            },
            formatDate(dateToFormat: string) {
                return formatDate(dateToFormat, 'DD/MM/YYYY HH:mm');
            },
            formatCurrency(value: number) {
                return value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });
            },
            onClearFilters() {
                console.log("In onClearFilters");
                this.selectedPG = null;
                this.selectedCustomer = null;
                this.selectedSupplyPoint = null;
                this.dateRange = null;
                this.startDate = undefined;
                this.endDate = undefined;
                this.tradesReportData = [];
            },
            async onFilter() {
                console.log("In onFilter");
                if (this.selectedPG !== null) {
                    var filters: TradeReportCustomerFilter = {
                            purchaseGroupId: this.selectedPG.id,
                            customerId: this.selectedCustomer?.id,
                            startDateTime: this.startDate,
                            endDateTime: this.endDate,                        
                        }
                    this.tradesReportData = await tradeService.filteredTradeReportCustomerWise(filters);
                    console.log(this.tradesReportData);
                }
            },
            async onTradeChange() {      
                console.log("In onTradeChange");
                if (this.selectedPG !== null) {
                    var filters: TradeReportCustomerFilter = {
                            purchaseGroupId: this.selectedPG.id,
                            customerId: this.selectedCustomer?.id,
                            startDateTime: this.startDate,
                            endDateTime: this.endDate,                        
                        }
                    this.tradesReportData = await tradeService.filteredTradeReportCustomerWise(filters);
                }
            },
            onPdfExport() {
                console.log("Export as PDF");
                //var chart = window.ApexCharts; // new ApexCharts(document.getElementById("chart"), this.chartOptions);
                //chart.render().then(() => {                      
                //VueApexCharts.dataURI().then(({ imgURI }) => {
                //        var pdf = new jsPDF();
                //        pdf.addImage(imgURI, 'png', 0, 0, 800, 500);
                //        pdf.save("download.pdf");
                //    });
                //});
            },
        },
        computed: {
            getTotalMTMCosts() {
                console.log("In getTotalMTMCosts");
                var totalMTMCostArray:any = [];
                this.tradesReportData.map((trade: TradeReportData) => {                    
                    totalMTMCostArray.push(trade.totalMTMCost);                                                                               
                });
                return totalMTMCostArray;
            },
            getTotalRates() {
                console.log("In getTotalRates");
                var totalRateArray: any = [];
                this.tradesReportData.map((trade: TradeReportData) => {
                    totalRateArray.push(trade.totalRate);
                });
                return totalRateArray;
            },
            getPeriods() {
                console.log("In getPeriods");
                var periods: any = [];
                this.tradesReportData.map((trade: TradeReportData) => {
                    periods.push(trade.period);                    
                });
                return periods;
            },
            commodityTypeId() {
                console.log("In commodityTypeId");
                var typeId: number = 0;
                const type = this.commodityTypes.find(ct => ct.name.toLowerCase().replaceAll(' ', '') === this.commodity);                
                if (type) {
                    typeId = type.id;
                }
                return typeId;
            }
        },
        watch: {
            async selectedPG(newPG: PurchaseGroup) {
                console.log("In selectedPG");
                if (newPG != null) {
                    console.log("Selected:", newPG);
                    newPG.volumes = await purchaseGroupService.getVolumesByPurchaseGroupId(newPG.id);
                    this.selectedPurchaseGroups = [newPG];
                    this.customers = await this.getCustomers(newPG.id);                    
                    console.log(this.customers);
                    this.dateRange = null;
                    this.startDate = undefined;
                    this.endDate = undefined;
                    this.selectedCustomer = null;
                    this.selectedSupplyPoint = null;
                    this.siteSupplyPoints = [];
                }
                //this.$emit('update:purchasegroup', newPG);
            },
            async selectedCustomer(newCustomer: Customer) {
                console.log("In selectedCustomer");
                if (newCustomer != null) {
                    console.log("Selected Customer:", newCustomer);                    
                    this.selectedCustomers = [newCustomer];
                    this.siteSupplyPoints = await this.getSupplyPoints(newCustomer.id);
                    console.log("Site Supply Points");
                    console.log(this.siteSupplyPoints);
                }
                //this.$emit('update:customer', newCustomer);
            },
            dateRange(newDates: Date[]) {
                console.log("In dateRange");
                console.log(newDates); 
                if (newDates !== null) {
                    this.startDate = newDates[0];
                    this.endDate = newDates[1];
                }
            },
            tradesReportData: {                
                handler(newValue) {                    
                    console.log("In tradesReportData");
                    this.totalMTMCosts = this.getTotalMTMCosts;
                    this.totalRates = this.getTotalRates;
                    this.labels = this.getPeriods;
                    console.log(newValue);
                    console.log(this.totalMTMCosts);
                    console.log(this.totalRates);
                    console.log(this.labels);
                    this.series = [
                        {
                            name: 'Total MTM Cost',
                            type: 'column',
                            data: this.totalMTMCosts
                        },
                        {
                            name: 'Total Rate',
                            type: 'line',
                            data: this.totalRates
                        }];
                    this.chartOptions= {
                        chart:
                        {
                            height: '350',
                            type: 'line',
                            //background: '#fff'
                        },
                        colors: ['#6c5092','#a79b02'],
                        stroke:
                        {
                            width: [0, 4]
                        },
                        title:
                        {
                            text: 'Trades'
                        },
                        dataLabels:
                        {
                            enabled: true,
                            enabledOnSeries: [1]
                        },
                        labels: this.labels,
                        xaxis:
                        {
                            type: 'string'
                        },
                        yaxis:
                        [
                            {
                                title:
                                {
                                    text: 'Total MTM Cost',
                                },            
                            },
                            {
                                opposite: true,
                                title:
                                {
                                    text: 'Total Rate'
                                }       
                            }
                            ],
                        theme:
                        {
                            mode: "dark",
                            //palette: "palette9",
                        },
                        tooltip:
                        {
                            fillSeriesColor: true,
                            theme: 'dark',
                        },
                    }
                },
                deep: true
            }
        }
    });
