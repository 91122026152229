import { createWebHistory, createRouter, RouterLink, RouteLocationNormalized } from 'vue-router';
import { Auth } from 'aws-amplify';

// 1. Define route components.
import DashboardLayout from '@/components/Dashboard/DashboardLayout.vue';
import DashboardHome from '@/components/Dashboard/DashboardHome.vue';
import AuthComponent from '@/components/AuthScreen.vue';
import PurchaseGroupsList from '@/components/PurchaseGroups/PurchaseGroupsList.vue';
import PurchaseGroupHome from '@/components/PurchaseGroups/PurchaseGroupHome.vue';
import TradeHistory from '@/components/PurchaseGroups/TradeHistory.vue';
import TradeableVolumes from '@/components/PurchaseGroups/TradeableVolumes.vue';
import EditPurchaseGroup from '@/components/PurchaseGroups/EditPurchaseGroup.vue';
import EditPurchaseGroupContract from '@/components/PurchaseGroups/EditPurchaseGroupContract.vue';
import TradingHome from '@/components/Trading/TradingHome.vue';
import MarketTrading from '@/components/Trading/MarketTrading.vue';
import PreviousTrading from '@/components/Trading/PreviousTrading.vue';
import ContactHome from '@/components/Contacts/ContactHome.vue';
import EditContact from '@/components/Contacts/EditContact.vue';
import CustomerList from '@/components/Customer/CustomerList.vue';
import CustomerHome from '@/components/Customer/CustomerHome.vue';
import EditCustomer from '@/components/Customer/EditCustomer.vue';
import CustomerSites from '@/components/Customer/CustomerSites.vue';
import EditSite from '@/components/Sites/EditSite.vue';
import ContractList from '@/components/Contracts/ContractList.vue';
import EditContract from '@/components/Contracts/ContractView.vue';
import CustomerSupplyPoints from '@/components/Customer/CustomerSupplyPoints.vue';
import EditSupplyPoint from '@/components/SupplyPoints/EditSupplyPoint.vue';
import SettingsHome from '@/components/Settings/SettingsHome.vue';
import ReportsHome from '@/components/Reports/ReportsHome.vue';
import TradesReportHome from '@/components/Reports/Trades/TradesHome.vue';
import TradesReport from '@/components/Reports/Trades/TradesMtoMChart.vue';
import TradeHistoryReportHome from '@/components/Reports/TradeHistory/TradeHistoryHome.vue';
import TradeHistoryReport from '@/components/Reports/TradeHistory/TradeHistory.vue';
import TradesMtoMChart from '@/components/Reports/Trades/TradesMtoMChart.vue';
import FullyDeliveredHome from '@/components/Reports/FullyDelivered/FullyDeliveredHome.vue';
import ElectricDataStoreHome from '@/components/Reports/ElectricDataStore/ElectricDataStoreHome.vue';
import GasDataStoreHome from '@/components/Reports/GasDataStore/GasDataStoreHome.vue';
import ProductHome from '@/components/Settings/Products/ProductHome.vue';
import EditProduct from '@/components/Settings/Products/EditProduct.vue';
import ConsumptionHome from '@/components/Settings/Consumption/ConsumptionHome.vue';
import TriggerHome from '@/components/Settings/Triggers/TriggerHome.vue';
import EditTrigger from '@/components/Settings/Triggers/EditTrigger.vue';
import QuickSightEmbed from "@/components/Reports/qs/QuickSightEmbed.vue";
import ReportSettings from "@/components/Settings/Report-Settings/ReportSettings.vue";


// 2. Define some routes
// Each route should map to a component.
const routes = [
    {
        path: '/auth', component: AuthComponent
    },
    {
        path: '/', component: DashboardLayout, meta: { requiresAuth: true },
        children: [
            {
                path: '', component: DashboardHome, meta: { requiresAuth: true }
            },
            {
                path: '/contacts', component: ContactHome, meta: { requiresAuth: true }
            },
            {
                // EditContact will be rendered inside Customer's <router-view>
                // when /contacts/:contactId is matched
                path: '/contacts/:contactId',
                name: 'editContact',
                component: EditContact,
                props: (route: RouteLocationNormalized) => ({ contactId: Number(route.params.contactId) }),
                meta: { requiresAuth: true },
            },
            {
                path: '/customers', component: CustomerList, meta: { requiresAuth: true }
            },
            {
                path: '/customers/:customerId', props: (route: RouteLocationNormalized) => ({ customerId: Number(route.params.customerId) }), component: CustomerHome, meta: { requiresAuth: true },
                children: [
                    {
                        // EditCustomer will be rendered inside Customer's <router-view>
                        // when /customer/:customerId is matched
                        path: '',
                        name: 'editCustomer',
                        component: EditCustomer,
                        props: (route: RouteLocationNormalized) => ({ customerId: Number(route.params.customerId) }),
                        meta: { requiresAuth: true },
                    },
                    {
                        // CustomerSites will be rendered inside Customer's <router-view>
                        // when /supply-points is matched
                        path: 'sites',
                        name: 'sites',
                        component: CustomerSites,
                        props: (route: RouteLocationNormalized) => ({ customerId: Number(route.params.customerId) }),
                        meta: { requiresAuth: true },
                    },
                    {
                        // EditSite will be rendered inside Customer's <router-view>
                        // when /sites/:siteId is matched
                        path: 'sites/:siteId',
                        name: 'editSite',
                        component: EditSite,
                        props: (route: RouteLocationNormalized) => ({ customerId: Number(route.params.customerId), siteId: Number(route.params.siteId) }),
                        meta: { requiresAuth: true },
                    },
                    {
                        // CustomerSupplyPoints will be rendered inside Customer's' <router-view>
                        // when /supply-points is matched
                        path: 'supply-points',
                        name: 'supplyPoints',
                        component: CustomerSupplyPoints,
                        props: (route: RouteLocationNormalized) => ({ customerId: Number(route.params.customerId) }),
                        meta: { requiresAuth: true },
                    },
                    {
                        // EditSupplyPoint will be rendered inside Customer's' <router-view>
                        // when /supply-points/:supplyPointId is matched
                        path: 'supply-points/:supplyPointId',
                        name: 'editSupplyPoint',
                        component: EditSupplyPoint,
                        props: (route: RouteLocationNormalized) => ({ customerId: Number(route.params.customerId), supplyPointId: Number(route.params.supplyPointId) }),
                        meta: { requiresAuth: true },
                    },
                    {
                        // Customer Contracts will be rendered inside Customer's <router-view>
                        // when /customer-contracts is matched
                        path: 'customer-contracts',
                        name: 'customerContracts',
                        component: ContractList,
                        props: (route: RouteLocationNormalized) => ({ customerId: Number(route.params.customerId) }),
                        meta: { requiresAuth: true },
                    },
                    {
                        // EditContract will be rendered inside Customer's <router-view>
                        // when /customer-contracts/:contractId is matched
                        path: 'customer-contracts/:contractId',
                        name: 'editContract',
                        component: EditContract,
                        props: (route: RouteLocationNormalized) => ({ customerId: Number(route.params.customerId), contractId: Number(route.params.contractId) }),
                        meta: { requiresAuth: true },
                    },
                ],
            },
            {
                path: '/purchase-groups', component: PurchaseGroupsList, meta: { requiresAuth: true },
            },
            {
                path: '/purchase-groups/:purchaseGroupId', props: (route: RouteLocationNormalized) => ({ purchaseGroupId: Number(route.params.purchaseGroupId) }), component: PurchaseGroupHome, meta: { requiresAuth: true },
                children: [
                    {
                        // editPurchaseGroup will be rendered inside PurchaseGroup's <router-view>
                        // when /purchase-group/:purchaseGroupId is matched
                        path: '',
                        name: 'editPurchaseGroup',
                        component: EditPurchaseGroup,
                        props: (route: RouteLocationNormalized) => ({ purchaseGroupId: Number(route.params.purchaseGroupId) }),
                        meta: { requiresAuth: true },
                    },
                    {
                        // TradeHistory will be rendered inside PurchaseGroup's <router-view>
                        // when /trade-history is matched
                        path: 'trade-history',
                        name: 'tradeHistory',
                        component: TradeHistory,
                        props: (route: RouteLocationNormalized) => ({ purchaseGroupId: Number(route.params.purchaseGroupId) }),
                        meta: { requiresAuth: true },
                    },
                    {
                        // EditContract will be rendered inside Purchase Group's <router-view>
                        // when /customer-contracts/:purchaseGroupContractId is matched
                        path: 'customer-contracts/:purchaseGroupContractId',
                        name: 'editPurchaseGroupContract',
                        component: EditPurchaseGroupContract,
                        props: (route: RouteLocationNormalized) => ({ purchaseGroupId: Number(route.params.purchaseGroupId), purchaseGroupContractId: Number(route.params.purchaseGroupContractId) }),
                        meta: { requiresAuth: true },
                    },
                    {
                        // TradeableVolumes will be rendered inside PurchaseGroup's <router-view>
                        // when /tradeable-volumes is matched
                        path: 'tradeable-volumes',
                        name: 'tradeableVolumes',
                        component: TradeableVolumes,
                        props: (route: RouteLocationNormalized) => ({ purchaseGroupId: Number(route.params.purchaseGroupId) }),
                        meta: { requiresAuth: true },
                    },
                ],
            },
            {
                path: '/market-trading', props: { tradingView: "marketTrading" }, component: TradingHome, meta: { requiresAuth: true },
                redirect: '/market-trading/gas',
                children: [
                    {
                        // MarketTrading will be rendered inside Trading Home's <router-view>
                        // when /market-trading/:commodity is matched
                        path: ':commodity',
                        name: 'marketTrading',
                        component: MarketTrading,
                        props: (route: RouteLocationNormalized) => ({ commodity: route.params.commodity }),
                        meta: { requiresAuth: true },
                    },
                ],
            },
            {
                path: '/previous-trading', props: { tradingView: "previousTrading" }, component: TradingHome, meta: { requiresAuth: true },
                redirect: '/previous-trading/gas',
                children: [
                    {
                        // Previous Trading will be rendered inside Trading Home's <router-view>
                        // when /previous-trading/:commodity/ is matched
                        path: ':commodity',
                        name: 'previousTrading',
                        component: PreviousTrading,
                        props: (route: RouteLocationNormalized) => ({ commodity: route.params.commodity }),
                        meta: { requiresAuth: true },
                    },
                ],
            },
            {
                path: '/reports', name: 'reportsHome', component: ReportsHome, meta: { requiresAuth: true },
            },
            {
                path: '/reports/trades',
                name: 'tradesReportHome',
                component: TradesReportHome,
                props: (route: RouteLocationNormalized) => ({ commodity: route.params.commodity }),
                meta: { requiresAuth: true },
                redirect: '/reports/trades/gas',
                children: [
                    {
                        // Trades Report will be rendered inside Report Trades' <router-view>
                        // when /reports/trades/:commodity/ is matched
                        path: ':commodity',
                        name: 'tradesReport',
                        component: TradesReport,
                        props: (route: RouteLocationNormalized) => ({ commodity: route.params.commodity }),
                        meta: { requiresAuth: true },
                    },
                ]
            },
            {
                path: '/reports/:dashboardId',
                name: 'reportEmbed',
                component: QuickSightEmbed,
                meta: { requiresAuth: true },
            },
            {
                path: '/reports/trade-history',
                name: 'tradeHistoryReportHome',
                component: TradeHistoryReportHome,
                //props: (route: RouteLocationNormalized) => ({ purchaseGroupId: Number(route.params.purchaseGroupId) }),
                meta: { requiresAuth: true },
                redirect: '/reports/trade-history/gas',
                children: [
                    {
                        // Trade History Report will be rendered inside Report Trade History Home's <router-view>
                        // when /reports/trade-history/:commodity/ is matched
                        path: ':commodity',
                        name: 'tradeHistoryReport',
                        component: TradeHistoryReport,
                        props: (route: RouteLocationNormalized) => ({ commodity: route.params.commodity }),
                        meta: { requiresAuth: true },
                    },
                ]
            },
            //{
            //    path: '/reports/delivered',
            //    name: 'fullyDeliveredHome',
            //    component: FullyDeliveredHome,
            //},
            {
                path: '/reports/delivered',
                name: 'fullyDeliveredHome',
                component: FullyDeliveredHome,
                props: (route: RouteLocationNormalized) => ({ commodity: route.params.commodity }),
                meta: { requiresAuth: true },
                redirect: '/reports/delivered/gas',
                children: [
                    {
                        path: 'gas',
                        name: 'gasCostsReport',
                        component: GasDataStoreHome,
                        props: (route: RouteLocationNormalized) => ({ commodity: route.params.commodity }),
                        meta: { requiresAuth: true },
                    },
                    {
                        path: ':commodity',
                        name: 'electricCostsReport',
                        component: ElectricDataStoreHome,
                        props: (route: RouteLocationNormalized) => ({ commodity: route.params.commodity }),
                        meta: { requiresAuth: true },
                    },
                ]
            },
            {
                path: '/reports/trade-customers',
                name: 'customerTradesReport',
                component: TradesMtoMChart,
                meta: { requiresAuth: true }
            },
            {
                path: '/settings', component: SettingsHome, meta: { requiresAuth: true },
            },
            {
                path: '/products', name: 'ProductHome', component: ProductHome, meta: { requiresAuth: true },
            },
            {
                path: '/consumption-imports', name: 'ConsumptionHome', component: ConsumptionHome, meta: { requiresAuth: true },
            },
            {
                path: '/products/:productId',
                name: 'editProduct',
                component: EditProduct,
                props: (route: RouteLocationNormalized) => ({ productId: Number(route.params.productId) }),
                meta: { requiresAuth: true },
            },
            {
                path: '/triggers', name: 'TriggerHome', component: TriggerHome, meta: { requiresAuth: true },
            },
            {
                path: '/report-settings', name: 'ReportSettings', component: ReportSettings, meta: { requiresAuth: true },
            },
            {
                path: '/triggers/:triggerId',
                name: 'editTrigger',
                component: EditTrigger,
                props: (route: RouteLocationNormalized) => ({ triggerId: Number(route.params.triggerId) }),
                meta: { requiresAuth: true },
            },
       ]
    }
]

// 3. Create the router instance and pass the `routes` option
const router = createRouter({
    // Provide the history implementation to use. We are using the html5 history mode here.
    history: createWebHistory(),
    routes
})

// resolve guards - check if authentication is required and validate with AWS Amplify, otherwise redirect to /auth
// This is called before navigation is confirmed.
router.beforeResolve((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {

        Auth.currentAuthenticatedUser().then(() => {
            next()
        }).catch(() => {
            next({
                path: '/auth'
            });
        });
    }
    else next()
})

router.beforeEach((to, from, next) => {
    // Get the page title from the route meta data that we have defined
    document.title = to.meta.title as string || "Flagship Portal";

    // Continue resolving the route
    next()
})

export default router
