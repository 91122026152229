import { useTradingApi } from '@/composables/TradingApi'
import { Lookup } from './Types';
import { Period } from './PeriodService';
import { getFormData } from '@/composables/UtilityFunctions'

export interface Trade {
    id: number,
    commodityTypeId: number,
    tradeStatusId: number,
    tradeTypeId?: number,
    tradeDateTime?: Date,
    strategyId?: number,
    tradeMethodId?: number,
    counterPartyId?: number,
    indexCharge?: number,
    sellBackCharge?: number,
    counterPartySleevingPrice?: number,
    notes?: string,
    totalOpenVolume?: number,
    totalTradeVolume?: number,
    tradeValue?: number,
    supplierOfferPrice?: number
    creatorUserId?: string,
    purchaseGroups: any[],
    periods: Period[],
    acceptanceDocuments?: Document[],
    lastModifierUserId?: string,
    lastModificationTime?: string,
}

export interface TradeHistory {
    id: number,
    commodityTypeId: number,
    tradeStatusId: number,
    tradeTypeId?: number,
    tradeDateTime?: string,
    tradeMethodId?: number,
    totalTradeVolume?: number,
    supplierOfferPrice?: number
    creatorUserId?: string,
    periods: any[]
}

export interface TradeHistoryFilter {
    purchaseGroupId: number,
    startDateTime?: Date,
    endDateTime?: Date,
    tradeStatusId?: number,
}

export interface TradeReportCustomerFilter {
    purchaseGroupId: number,
    customerId?: number,
    startDateTime?: Date,
    endDateTime?: Date,
}

export interface TradeReportData {
    purchaseGroupId: number,
    period: string,
    tradedClips: number,
    exposedClips: number,
    totalClips: number,
    percentageTraded: number,
    tradedCost: number,
    exposedCost: number,
    tradedRate: number,
    exposedRate: number,
    totalMTMCost: number,
    totalRate: number,
    lastUpdated: Date
}

export interface TradeImport extends Trade {
    TradeDate: string,
    TradeType: string,
    BasePeak: string,
    Product: string,
    Period: string,
    Client: string,
    BuySell: string,
    Broker: string,
    ContractPrice: number,
    ContractSize: number,
    ContractSizeHours: number,
    TotalQuantity: number,
    TotalCost: number,
    Status: string,
}

export interface DataStoreReportFilter {
    customerId: number,
    supplyPointId?: number,
    startDateTime?: Date,
    endDateTime?: Date,
}

export interface DeliveredCost {
    id: number,
    meterRef: number,
    customerId: number,
    supplyPointId: number,
    period: string,
    startDate: Date,
    endDate: Date,
    nonCommodity: number,
    combinedUnitCost: number,
    commodityRate: number,
    monthlyCharge: number,
    otherCharges: number,
    ccl: number,
    netCost: number,
    vat: number,
    totalCost: number,
}

export interface GasDataStore extends DeliveredCost {   
    consumption: number,  
}

export interface ElectricDataStore extends DeliveredCost {
    unitCost: number,
    supplyCapacity: number,
    shapeFee: number,
    capacityCharge: number,
    commodityKWH: number,
    billedKWH: number,
}

export default class TradeService {
   
    async getTrades() {

        const data = await useTradingApi(`/trading/trades/`) as Trade[];
        return data ?? [];
    }

    async getTradeById(id: Number) {

        const data = await useTradingApi(`/trading/trades/${id}`) as Trade;
        return data ?? null;
    }

    async getTradesForCommodity(commodityTypeId: number | null) {

        const data = await useTradingApi(`/trading/trades/commodity/${commodityTypeId}`) as Trade[];
        return data ?? [];
    }

    async addTrade(trade: any | null) {

        const newOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(trade)
        };

        const data = await useTradingApi(`/trading/trades/`, newOptions);
        return data ?? null;
    }

    async updateTrade(trade: any | null) {

        const newOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(trade)
        };

        const data = await useTradingApi(`/trading/trades/`, newOptions);
        return data ?? null;
    }

    async getCommodities() {

        const data = await useTradingApi(`/trading/commodities/`) as Lookup[];
        return data ?? [];
    }

    async getTradeStatuses() {

        const data = await useTradingApi(`/trading/tradestatuses/`) as Lookup[];
        return data ?? [];
    }

    async getTradeMethods() {

        const data = await useTradingApi(`/trading/trademethods/`) as Lookup[];
        return data ?? [];
    }

    async getTradeTypes() {

        const data = await useTradingApi(`/trading/tradetypes/`) as Lookup[];
        return data ?? [];
    }

    async getCounterParties() {

        const data = await useTradingApi(`/trading/counterparties/`) as Lookup[];
        return data ?? [];
    }

    async getTradeHistory(id: number | null) {

        const data = await useTradingApi(`/trading/purchasegroups/${id}/tradehistory`) as TradeHistory[];
        return data ?? [];
    }

    async filteredTradeHistory(filters: TradeHistoryFilter) {

        const formData = getFormData(filters);

        const options = {
            method: 'POST',
            body: formData
        };

        const data = await useTradingApi(`/trading/purchasegroups/tradehistory`, options);
        return data ?? null;
    }

    async filteredTradeReportCustomerWise(filters: TradeReportCustomerFilter) {

        const formData = getFormData(filters);

        const options = {
            method: 'POST',
            body: formData
        };

        const data = await useTradingApi(`/trading/reports/customersdata`, options) as TradeReportData[];
        return data ?? null;
    }

    async filteredElectricDataStoreReport(filters: DataStoreReportFilter) {

        const formData = getFormData(filters);

        const options = {
            method: 'POST',
            body: formData
        };

        const data = await useTradingApi(`/trading/reports/electricdatastore`, options) as ElectricDataStore[];
        return data ?? null;
    }

    async filteredGasDataStoreReport(filters: DataStoreReportFilter) {

        const formData = getFormData(filters);

        const options = {
            method: 'POST',
            body: formData
        };

        const data = await useTradingApi(`/trading/reports/gasdatastore`, options) as GasDataStore[];
        return data ?? null;
    }

    // async filteredElectricDataStoreReport(filters: ElectricDataStoreReportFilter) {

    //     const formData = getFormData(filters);

    //     const options = {
    //         method: 'POST',
    //         body: formData
    //     };

    //     const data = await useTradingApi(`/trading/reports/electricdatastore`, options) as ElectricDataStoreReportData[];
    //     return data ?? null;
    // }

    async acceptTrade(id: Number | null, payload: any) {

        const formData = getFormData(payload);

        const options = {
            method: 'POST',
            body: formData
        };

        const data = await useTradingApi(`/trading/trades/${id}/accept`, options);
        return data ?? null;
    }

    async approveTrade(id: Number | null) {

        const options = {
            method: 'POST',
        };

        const data = await useTradingApi(`/trading/trades/${id}/approve`, options);
        return data ?? null;
    }

    async declineTrade(id: Number | null, payload: any) {

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };

        const data = await useTradingApi(`/trading/trades/${id}/decline`, options);
        return data ?? null;
    }

    async addBulkTradeImport(trades: TradeImport[]) {

        //const formData = getFormData(trades);
        const payload = { newTrades: trades };

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };

        const data = await useTradingApi(`/trading/trades/bulkimport`, options);
        return data ?? null;
    }


}


export const tradeService = new TradeService();


