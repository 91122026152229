import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, null, {
    header: _withCtx(() => [
      _createVNode(_component_TabMenu, {
        model: _ctx.items,
        class: "mb-2"
      }, null, 8, ["model"])
    ]),
    content: _withCtx(() => [
      (_openBlock(), _createBlock(_component_router_view, {
        key: this.$route.path
      }))
    ]),
    _: 1
  }))
}