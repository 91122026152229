
    import { defineComponent } from 'vue';
    import { triggerService, Trigger } from '@/services/TriggerService';
    import TriggerList from '@/components/Settings/Triggers/TriggerList.vue';

    interface Data {
        loading: boolean,
        triggers: Trigger[],
    }

    export default defineComponent({
        data(): Data {
            return {
                loading: true,
                triggers: [],
            };
        },
        components: {
            TriggerList
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                this.triggers = await triggerService.getTriggers();
                this.loading = false;

            },
            async refreshList() {
                await this.fetchData();
            }
        }
    });
