
    import { defineComponent } from 'vue';
    import VueApexCharts from "vue3-apexcharts";
    import { GasDataStore, DataStoreReportFilter, tradeService } from '@/services/TradeService';
    import { formatDate } from '@/composables/UtilityFunctions';
    import { Customer, customerService } from '@/services/CustomerService';
    import { siteService, SiteSupplyPoints } from '@/services/SiteService';
    import { SupplyPoint } from '@/services/SupplyPointService';
    import GasDataStoreList from '@/components/Reports/GasDataStore/GasDataStoreList.vue';
    import { Lookup } from '@/services/Types';

    interface Data {
        loading: boolean, 
        labels: any,       
        gasDataStore: GasDataStore[],        
        selectedCustomer: Customer | null,
        selectedCustomers: Customer[],
        customers: Customer[],
        commodityTypes: Lookup[],
        siteSupplyPoints: SiteSupplyPoints[],
        selectedSupplyPoint: SupplyPoint | null,
        dateRange: Date[] | null,
        startDate: Date | undefined,
        endDate: Date | undefined,
        series: any,
        chartOptions: any,
        combinedUnitCosts: any,
        commodityRates: any     
    }

    export default defineComponent({
        props: {    
            commodity: {
                type: String,
                required: true,
                default: 'gas'
            },        
        },
        components: {
            GasDataStoreList,
            apexchart: VueApexCharts
        },
        data(): Data {
            return {
                loading: true,     
                labels: [],                            
                gasDataStore: [],                
                selectedCustomer: null,
                selectedCustomers: [],
                customers: [],
                commodityTypes: [],
                siteSupplyPoints: [],
                selectedSupplyPoint: null,
                dateRange: null,
                startDate: undefined,
                endDate: undefined,
                series: [],
                chartOptions: {},
                combinedUnitCosts: [],
                commodityRates: []
            };
        },
        created() {
            this.fetchData();
        },
        methods: {
            async fetchData() {                
                this.commodityTypes = await tradeService.getCommodities();                
                this.customers = await customerService.getCustomers();                
                this.loading = false;            
            },
            async getSupplyPoints(customerId: number) {
                return await siteService.getSiteSupplyPointsForCustomer(customerId);  
            },            
            formatDate(dateToFormat: string) {
                return formatDate(dateToFormat, 'DD/MM/YYYY HH:mm');
            },
            formatCurrency(value: number) {
                return value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });
            },
            onClearFilters() {
                console.log("In onClearFilters");                
                this.selectedCustomer = null;
                this.selectedSupplyPoint = null;
                this.dateRange = null;
                this.startDate = undefined;
                this.endDate = undefined;
                this.gasDataStore = [];
            },
            async onFilter() {
                console.log("In onFilter");
                if (this.selectedCustomer !== null) {
                    var filters: DataStoreReportFilter = {
                            customerId: this.selectedCustomer.id,
                            supplyPointId: this.selectedSupplyPoint?.id,
                            startDateTime: this.startDate,
                            endDateTime: this.endDate,                        
                    }
                    console.log(filters);
                    this.gasDataStore = await tradeService.filteredGasDataStoreReport(filters);
                    console.log(this.gasDataStore);
                }
            },
        },
        computed: {        
            commodityTypeId() {
                console.log("In commodityTypeId");
                var typeId: number = 0;
                const type = this.commodityTypes.find(ct => ct.name.toLowerCase().replaceAll(' ', '') === this.commodity);                
                if (type) {
                    typeId = type.id;
                }
                return typeId;
            },
            getCombinedUnitCosts() {
                console.log("In getCombinedUnitCosts");
                var combinedUnitCostArray: any = [];
                this.gasDataStore.map((row: GasDataStore) => {                    
                    combinedUnitCostArray.push(row.combinedUnitCost);
                });
                return combinedUnitCostArray;
            },
            getCommodityRates() {
                console.log("In getCommodityRates");
                var commodityRatesArray:any = [];
                this.gasDataStore.map((row: GasDataStore) => {                    
                    commodityRatesArray.push(row.commodityRate);                                                                               
                });
                return commodityRatesArray;
            },            
            getPeriods() {
                console.log("In getPeriods");
                var periods: any = [];
                this.gasDataStore.map((row: GasDataStore) => {                    
                    periods.push(row.period);                    
                });
                return periods;
            },
        },
        watch: {
            async selectedCustomer(newCustomer: Customer) {
                console.log("In selectedCustomer");
                if (newCustomer != null) {
                    console.log("Selected Customer:", newCustomer);                    
                    this.selectedCustomers = [newCustomer];
                    this.siteSupplyPoints = await this.getSupplyPoints(newCustomer.id);
                    console.log("Site Supply Points");
                    console.log(this.siteSupplyPoints);
                }
            },
            dateRange(newDates: Date[]) {
                console.log("In dateRange");
                console.log(newDates); 
                if (newDates !== null) {
                    this.startDate = newDates[0];
                    this.endDate = newDates[1];
                }
            }, 
            gasDataStore: {                
                handler(newValue) {                    
                    console.log("In gasDataStore");
                    this.combinedUnitCosts = this.getCombinedUnitCosts;
                    this.commodityRates = this.getCommodityRates;
                    this.labels = this.getPeriods;
                    console.log(newValue);
                    console.log(this.combinedUnitCosts);
                    console.log(this.commodityRates);
                    console.log(this.labels);
                    this.series = [
                        {
                            name: 'Combined Unit Cost',
                            type: 'column',
                            data: this.combinedUnitCosts
                        },
                        {
                            name: 'Commodity Rate',
                            type: 'line',
                            data: this.commodityRates
                        }];
                    this.chartOptions= {
                        chart:
                        {
                            height: '350',
                            type: 'line',
                           // background: '#fff'
                        },
                        colors: ['#6c5092','#a79b02'],
                        stroke:
                        {
                            width: [0, 4]
                        },
                        title:
                        {
                            text: 'Full Delivery'
                        },
                        dataLabels:
                        {
                            enabled: true,
                            enabledOnSeries: [1]
                        },
                        labels: this.labels,
                        xaxis:
                        {
                            type: 'string',
                            title:
                            {
                                text: 'Months'
                            }
                        },
                        yaxis:
                        [
                            {
                                title:
                                {
                                    text: 'Combined Unit Cost',
                                },            
                            },
                            {
                                opposite: true,
                                title:
                                {
                                    text: 'Commodity Rate'
                                }       
                                }],
                        theme:
                        {
                            mode: "dark",
                            //palette: "palette9",
                        },
                        tooltip:
                        {
                            fillSeriesColor: true,
                            theme: 'dark',
                        },
                    }
                },
                deep: true
            }           
        }
    });
