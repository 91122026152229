import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "grid",
  style: {"max-width":"850px"}
}
const _hoisted_2 = { class: "flex flex-column" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "col-4 m:col-12"
      }, [
        _createVNode(_component_Card, { class: "home-item text-center" }, {
          content: _withCtx(() => [
            _createVNode(_component_router_link, {
              to: item.to,
              class: "button"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("img", {
                    class: "block",
                    src: item.icon,
                    alt: ""
                  }, null, 8, _hoisted_3),
                  _createElementVNode("h2", null, _toDisplayString(item.label), 1)
                ])
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 2
        }, 1024)
      ]))
    }), 128))
  ]))
}