import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 4 }
const _hoisted_7 = { key: 5 }
const _hoisted_8 = { key: 6 }
const _hoisted_9 = { key: 7 }
const _hoisted_10 = { class: "grid grid-nogutter justify-content-between" }
const _hoisted_11 = { class: "carousel__navigation" }
const _hoisted_12 = { class: "carousel__navigation-list" }
const _hoisted_13 = { class: "carousel__navigation-item" }
const _hoisted_14 = { class: "carousel__navigation-item" }
const _hoisted_15 = { class: "carousel__navigation-item" }
const _hoisted_16 = {
  key: 0,
  class: "carousel__navigation-item"
}
const _hoisted_17 = { class: "carousel__navigation-item" }
const _hoisted_18 = { class: "carousel__navigation-item" }
const _hoisted_19 = { class: "carousel__navigation-item" }
const _hoisted_20 = { class: "carousel__navigation-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SupplierProduct = _resolveComponent("SupplierProduct")!
  const _component_KeyDetails = _resolveComponent("KeyDetails")!
  const _component_DeliverySchedule = _resolveComponent("DeliverySchedule")!
  const _component_ShapeFees = _resolveComponent("ShapeFees")!
  const _component_SupplyPoints = _resolveComponent("SupplyPoints")!
  const _component_SupplyPointCharges = _resolveComponent("SupplyPointCharges")!
  const _component_VolumesTemplate = _resolveComponent("VolumesTemplate")!
  const _component_CommissionFees = _resolveComponent("CommissionFees")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!

  return (_ctx.contract)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Card, null, {
          header: _withCtx(() => []),
          content: _withCtx(() => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_SupplierProduct, {
                editContract: _ctx.contract,
                editMode: _ctx.editMode,
                isActiveStep: _ctx.activeIndex == 0,
                "onSubmit:contract": _ctx.onUpdateContract,
                onProductSelect: _ctx.onProductSelect,
                class: "mb-3"
              }, null, 8, ["editContract", "editMode", "isActiveStep", "onSubmit:contract", "onProductSelect"])
            ])), [
              [_vShow, _ctx.activeIndex == 0]
            ]),
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_KeyDetails, {
                editContract: _ctx.contract,
                editMode: _ctx.editMode,
                isActiveStep: _ctx.activeIndex == 1,
                "onSubmit:contract": _ctx.onUpdateContract,
                class: "mb-3"
              }, null, 8, ["editContract", "editMode", "isActiveStep", "onSubmit:contract"])
            ])), [
              [_vShow, _ctx.activeIndex == 1]
            ]),
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_DeliverySchedule, {
                editContract: _ctx.contract,
                editMode: _ctx.editMode,
                isActiveStep: _ctx.activeIndex == 2,
                "onSubmit:contract": _ctx.onUpdateContract,
                class: "mb-3"
              }, null, 8, ["editContract", "editMode", "isActiveStep", "onSubmit:contract"])
            ])), [
              [_vShow, _ctx.activeIndex == 2]
            ]),
            (_ctx.activeProduct && _ctx.activeProduct.isShapeFee)
              ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_ShapeFees, {
                    editContract: _ctx.contract,
                    editMode: _ctx.editMode,
                    isActiveStep: _ctx.activeIndex == 3,
                    "onSubmit:contract": _ctx.onUpdateContract,
                    class: "mb-3"
                  }, null, 8, ["editContract", "editMode", "isActiveStep", "onSubmit:contract"])
                ])), [
                  [_vShow, _ctx.activeIndex == 3]
                ])
              : _createCommentVNode("", true),
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_SupplyPoints, {
                editContract: _ctx.contract,
                editMode: _ctx.editMode,
                editProduct: _ctx.activeProduct,
                isActiveStep: _ctx.activeIndex == 4,
                "onSubmit:contract": _ctx.onUpdateContract,
                class: "mb-3"
              }, null, 8, ["editContract", "editMode", "editProduct", "isActiveStep", "onSubmit:contract"])
            ])), [
              [_vShow, _ctx.activeIndex == 4]
            ]),
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_SupplyPointCharges, {
                editContract: _ctx.contract,
                editMode: _ctx.editMode,
                isActiveStep: _ctx.activeIndex == 5,
                "onSubmit:contract": _ctx.onUpdateContract,
                class: "mb-3"
              }, null, 8, ["editContract", "editMode", "isActiveStep", "onSubmit:contract"])
            ])), [
              [_vShow, _ctx.activeIndex == 5]
            ]),
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_8, [
              (_openBlock(), _createBlock(_component_VolumesTemplate, {
                editContract: _ctx.contract,
                key: _ctx.volumeKey,
                editMode: _ctx.editMode,
                isActiveStep: _ctx.activeIndex == 6,
                units: _ctx.activeProduct && _ctx.activeProduct.utilityTypeId == 3 ? `therms` : `kWh`,
                "onSubmit:contract": _ctx.onUpdateContract,
                class: "mb-3"
              }, null, 8, ["editContract", "editMode", "isActiveStep", "units", "onSubmit:contract"]))
            ])), [
              [_vShow, _ctx.activeIndex == 6]
            ]),
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_CommissionFees, {
                editContract: _ctx.contract,
                editMode: _ctx.editMode,
                isActiveStep: _ctx.activeIndex == 7,
                "onSubmit:contract": _ctx.onUpdateContract,
                class: "mb-3"
              }, null, 8, ["editContract", "editMode", "isActiveStep", "onSubmit:contract"])
            ])), [
              [_vShow, _ctx.activeIndex == 7]
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_Button, {
                disabled: _ctx.activeIndex == 0,
                label: "Prev",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.prevPage())),
                icon: "pi pi-angle-left",
                iconPos: "left",
                class: "p-button-lg"
              }, null, 8, ["disabled"]),
              _createElementVNode("aside", _hoisted_11, [
                _createElementVNode("ol", _hoisted_12, [
                  _createElementVNode("li", _hoisted_13, [
                    _createElementVNode("a", {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.gotoPage(0))),
                      class: _normalizeClass(_ctx.activeIndex == 0 ? 'carousel__navigation-button carousel__navigation-active' : 'carousel__navigation-button' )
                    }, "Go to page 1", 2)
                  ]),
                  _createElementVNode("li", _hoisted_14, [
                    _createElementVNode("a", {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.gotoPage(1))),
                      class: _normalizeClass(_ctx.activeIndex == 1 ? 'carousel__navigation-button carousel__navigation-active' : 'carousel__navigation-button' )
                    }, "Go to page 2", 2)
                  ]),
                  _createElementVNode("li", _hoisted_15, [
                    _createElementVNode("a", {
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.gotoPage(2))),
                      class: _normalizeClass(_ctx.activeIndex == 2 ? 'carousel__navigation-button carousel__navigation-active' : 'carousel__navigation-button' )
                    }, "Go to page 3", 2)
                  ]),
                  (_ctx.activeProduct && _ctx.activeProduct.isShapeFee)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_16, [
                        _createElementVNode("a", {
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.gotoPage(3))),
                          class: _normalizeClass(_ctx.activeIndex == 3 ? 'carousel__navigation-button carousel__navigation-active' : 'carousel__navigation-button' )
                        }, "Go to page 4", 2)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("li", _hoisted_17, [
                    _createElementVNode("a", {
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.gotoPage(4))),
                      class: _normalizeClass(_ctx.activeIndex == 4 ? 'carousel__navigation-button carousel__navigation-active' : 'carousel__navigation-button' )
                    }, "Go to page 5", 2)
                  ]),
                  _createElementVNode("li", _hoisted_18, [
                    _createElementVNode("a", {
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.gotoPage(5))),
                      class: _normalizeClass(_ctx.activeIndex == 5 ? 'carousel__navigation-button carousel__navigation-active' : 'carousel__navigation-button' )
                    }, "Go to page 6", 2)
                  ]),
                  _createElementVNode("li", _hoisted_19, [
                    _createElementVNode("a", {
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.gotoPage(6))),
                      class: _normalizeClass(_ctx.activeIndex == 6 ? 'carousel__navigation-button carousel__navigation-active' : 'carousel__navigation-button' )
                    }, "Go to page 7", 2)
                  ]),
                  _createElementVNode("li", _hoisted_20, [
                    _createElementVNode("a", {
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.gotoPage(7))),
                      class: _normalizeClass(_ctx.activeIndex == 7 ? 'carousel__navigation-button carousel__navigation-active' : 'carousel__navigation-button' )
                    }, "Go to page 8", 2)
                  ])
                ])
              ]),
              _createVNode(_component_Button, {
                type: "submit",
                form: _ctx.subForms[_ctx.activeIndex],
                disabled: _ctx.isSaving,
                label: _ctx.activeIndex == (_ctx.subForms.length - 1) ? `Finish` : `Next`,
                icon: "pi pi-angle-right",
                iconPos: "right",
                class: "p-button-lg"
              }, null, 8, ["form", "disabled", "label"])
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}