
    import { defineComponent } from 'vue';
    import { productService, Product } from '@/services/ProductService';
    import ProductList from '@/components/Settings/Products/ProductList.vue';

    interface Data {
        loading: boolean,
        products: Product[],
    }

    export default defineComponent({
        data(): Data {
            return {
                loading: true,
                products: [],
            };
        },
        components: {
            ProductList
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                this.products = await productService.getProducts();

                this.loading = false;

            },
            async refreshList() {
                await this.fetchData();
            }
        }
    });
