import { useTradingApi } from '@/composables/TradingApi'

export interface Period {
    id: number,
    name: string,
    startDate: Date,
    endDate: Date,
    ratio?: number,
    totalHours?: number,
    totalDays?: number,
    children: Period[],
    tradedVolume?: number,
}

export interface PeriodPrice {
    id: number,
    name: string,
    startDate: Date,
    endDate: Date,
    ratio?: number,
    children: Period[],
    bid?: number,
    offer?: number,
    change?: number,
    openVolume?: number,
    tradedVolume?:number
}

export interface PeriodFixedVolume {
    purchaseGroupId: number,
    tradedVolume:number,
}

export interface PeriodFixedVolumeResponse {
    periodId: number;
    fixedVolume: number;
}
export default class PeriodService {
   
    async getChildPeriods(parentLevel: number) {

        const data = await useTradingApi(`/trading/periods/multi/${parentLevel}`) as Period[];
        return data ?? [];
    }

    async getCurrentAndFuturePeriods() {

        const data = await useTradingApi(`/trading/periods/currentAndFuture`) as Period[];
        return data ?? [];
    }

    async getPeriods() {

        const data = await useTradingApi(`/trading/periods/months`) as Period[];
        return data ?? [];
    }

    async getPeriodsFixedVolumeForPurchaseGroup(PeriodFixedVolume: any | null): Promise<PeriodFixedVolumeResponse[] | null> {

        const newOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(PeriodFixedVolume)
        };
        const data = await useTradingApi(`/trading/periods/tradedvolume/`, newOptions);
        return data ?? null;
    }

    async getFuturePeriods() {

        const data = await useTradingApi(`/trading/periods/future`) as Period[];
        return data ?? [];
    }

    async getParentPeriod(periodIds: number[]) {

        const newOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(periodIds)
        };
        const data = await useTradingApi(`/trading/periods/parents`, newOptions) as Period;
        return data ?? null;
    }

    async getPeriodPricesForCommodity(commodityTypeId: number | null) {
        const data = await useTradingApi(`/trading/periods/prices/commodity/${commodityTypeId}`) as PeriodPrice[];
        return data ?? [];
    }
    
    async getPeriodPricesForCommodityLevel(commodityTypeId: number | null, periodLevel: number | null) {
        const data = await useTradingApi(`/trading/periods/prices/commodity/${commodityTypeId}/${periodLevel}`) as PeriodPrice[];
        return data ?? [];
    }
    
}


export const periodService = new PeriodService();


