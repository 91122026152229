import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, {
      value: _ctx.supplyPointsWithSiteInfo,
      selectionMode: "single",
      dataKey: "id",
      onRowSelect: _ctx.onRowSelect,
      responsiveLayout: "scroll",
      loading: _ctx.loading
    }, {
      empty: _withCtx(() => [
        _createTextVNode(" No Supply Points. ")
      ]),
      loading: _withCtx(() => [
        _createTextVNode(" Loading supply point data... ")
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "name",
          header: "Name"
        }),
        _createVNode(_component_Column, {
          field: "siteName",
          header: "Site"
        }),
        _createVNode(_component_Column, { header: "MPAN/MPRN" }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(_ctx.getSupplyPointIdentifier(slotProps.data)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "SupplyPointType",
          header: "Type"
        }),
        _createVNode(_component_Column, {
          field: "annualConsumption",
          header: "Annual Consumption kWh"
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(slotProps.data.annualConsumption?.toLocaleString()), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "onRowSelect", "loading"])
  ]))
}