
    import { defineComponent } from 'vue';
    import dayjs from "dayjs";
    import { parse } from "papaparse";
    import { FilterMatchMode } from 'primevue/api';
    import VolumeHistories from '@/components/PurchaseGroups/VolumeHistories.vue';
    import EditVolume from '@/components/PurchaseGroups/EditVolume.vue';
    import {
      Volume,
      purchaseGroupService,
      VolumeHistory,
      PurchaseGroupContract,
      PurchaseGroup
    } from '@/services/PurchaseGroupService';
    import { customerContractService, CustomerContract, VolumeRef } from '@/services/ContractService';
    import { Period, periodService } from '@/services/PeriodService';
    import DocumentUpload from '@/components/Common/DocumentUpload.vue';
    import { VolumeService } from "@/services/VolumeService";

    interface Data {
        loading: boolean,
        isOverride: boolean,
        volumes: Volume[],
        volumeDialog: boolean,
        deleteVolumeDialog: boolean,
        deleteVolumesDialog: boolean,
        newVolume: any,
        volume: any,
        selectedVolumes: Volume[],
        purchaseGroupContracts: PurchaseGroupContract[],
        customerContracts: CustomerContract[],
        submitted: boolean,
        periods: Period[],
        filters: any,
        commodityTypeId?: number,
        contents: VolumeRef[],
        csvHeaders: string,
        csvContent: string,
        csvUploads: any[],
        parsed: boolean,
        isError: boolean,
        parseErrorMsg: string,
        editBulkVolume: boolean,
        purchaseGroup: PurchaseGroup
    }

    export default defineComponent({
        props: {
            purchaseGroupId: {
                type: Number,
                required: true
            },
        },
        components: {
            VolumeHistories,
            EditVolume,
            DocumentUpload,
        },
        data(): Data {

            return {
                loading: true,
                isOverride: false,
                volumes: [],
                volumeDialog: false,
                deleteVolumeDialog: false,
                deleteVolumesDialog: false,
                newVolume: {
                    purchaseGroupId: this.purchaseGroupId
                },
                volume: {},
                selectedVolumes: [],
                purchaseGroupContracts: [],
                customerContracts: [],
                submitted: false,
                periods: [],
                filters: {},
                commodityTypeId: 1,
                contents: [],
                csvHeaders: '',
                csvContent: '',
                csvUploads: [],
                parsed: false,
                isError: false,
                parseErrorMsg: '',
                editBulkVolume: false,
                purchaseGroup: {} as PurchaseGroup
            };
        },
        created() {
            this.initFilters();
            this.fetchData();
        },
        methods: {
            async fetchData() {

                this.periods = await periodService.getPeriods();
                this.volumes = await purchaseGroupService.getVolumesByPurchaseGroupId(this.purchaseGroupId);
                const volumesOverride = await purchaseGroupService.getVolumesOverrideByPurchaseGroupId(this.purchaseGroupId);
                this.isOverride = volumesOverride.volumesOverride;
                //get a list of customer contracts for this PG
                this.purchaseGroupContracts = await purchaseGroupService.getPurchaseGroupContracts(this.purchaseGroupId);
                this.customerContracts = await customerContractService.getSelectedCustomerContracts(this.purchaseGroupContracts.map(pgc => pgc.customerContractId.valueOf()));
                this.purchaseGroup = await purchaseGroupService.getPurchaseGroupById(this.purchaseGroupId);
                this.commodityTypeId = this.purchaseGroup.commodityTypeId;

                /**new */
                // If data exist in grid download that data in execl sheet, If not then only add periods in excel sheet which lies between
                // start and end date of the purchasegroup details screen
                if (this.volumes.length > 0)
                    this.editBulkVolume = true;

                this.createContentData();

                this.loading = false;

            },
            createContentData() {
              var periods;
              periods = this.periods.filter(p => (
                  (dayjs(p.startDate) >= dayjs(this.purchaseGroup.startDate) && dayjs(p.startDate) <= dayjs(this.purchaseGroup.endDate)) ||
                  (dayjs(p.endDate) >= dayjs(this.purchaseGroup.startDate) && dayjs(p.endDate) <= dayjs(this.purchaseGroup.endDate))
              )).map(({ id, name, startDate, endDate }) => ({ id, name, startDate, endDate }));

              this.contents = periods.map(p => {
                var startPeriod = dayjs(p.startDate);
                var endPeriod = dayjs(p.endDate);
                var matchedVolume = this.volumes.find(v => v.periodId === p.id);

                //    var matchWindow = null;//purchaseGroup.schedule.find(dw => (startPeriod >= dayjs(dw.startDate)) && (startPeriod <= dayjs(dw.endDate)));
                //var matchWindow = this.contract.schedule.find(dw => (startPeriod >= dayjs(dw.startDate)) && (startPeriod <= dayjs(dw.endDate)));
                return {
                  // deliveryWindowId: matchWindow ? matchWindow.id : 0,
                  deliveryWindowId: 0,
                  ...p,
                  startDate: startPeriod.format("DD/MM/YYYY"),
                  endDate: endPeriod.format("DD/MM/YYYY"),
                  volume: matchedVolume?.tradeableVolume ?? 0,
                  volumedId: matchedVolume?.id ?? "",
                }
              });
              this.formatContentForCSV();
            },
            formatContentForCSV() {
              this.csvContent = this.contents ? this.contents.map(d =>
                  JSON.stringify(Object.values(d))
              )
                  .join('\n')
                  .replace(/(^\[)|(\]$)/mg, '') : "";
              /**new  end */
            },
            onDownloadCsv() {
                this.createContentData();
                var csvContent = "data:text/csv;charset=utf-8,";
                
                this.csvHeaders = this.contents ? 
                    JSON.stringify(Object.keys(this.contents[0]))
                        //SB: Added this quick and dirty replace as TS is emitting errors when i update the this.contents to match.
                        //ToDo: Find out why and fix this on the Type level.
                        .replace("volumedId", "volumeId")
                        .replace(/(^\[)|(\]$)/mg, '') 
                    : "";

                csvContent += this.csvHeaders + '\n';
                csvContent += this.csvContent;

                var encodedUri = encodeURI(csvContent);
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", `purchaseGroup-${this.purchaseGroupId}-volumes.csv`);
                document.body.appendChild(link); // Required for FF
                link.click();
                document.body.removeChild(link);
            },
            onSelectUpload(files: File[]) {
                // If data exist in grid download that data in execl sheet, If not then only add periods in excel sheet which lies between
                // start and end date of the purchasegroup details screen
                if (this.volumes.length > 0)
                    this.editBulkVolume = true;
                this.csvUploads = files.length > 0 ? files : [];
                this.parseFile();
            },
            parseFile() {
                parse(this.csvUploads[0], {
                    download: true,
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    //  transformHeader: (header: string) => header.replace(this.units, 'volume'),
                    complete: (result) => {
                        this.parseResults(result.data);
                        this.saveUploadedVolumes();
                    },
                });
            },

            parseResults(results: any[]) {
              const resultVolumeIds = results.filter(item => item.volumeId && item.volumeId > 0).map(item => item.volumeId);
              const expectedVolumeIds = this.volumes.filter(item => item.id && item.id > 0).map(item => item.id);
              
              const volumeIdsCorrect = resultVolumeIds.filter(v => {
                return !(expectedVolumeIds.filter(e => e === v).length === 1);
              }).length === 0;
              
                if (results.length == 0) {
                    this.isError = true;
                    this.parseErrorMsg = "No entries could be found. The imported file does not match the required format. Please download the template and re-submit the csv file.";
                }
                else if (this.editBulkVolume && !("volumeId" in results[0])) {
                    this.isError = true;
                    this.parseErrorMsg = "The imported file does not have a 'VolumeId' column. Please download and re-submit the CSV file with the correct format.";
                }
                else if (this.editBulkVolume 
                    && !volumeIdsCorrect
                ) {
                  //need a way to determine if the volumeId's SHOULD be supplied or not...  
                  this.isError = true;
                    this.parseErrorMsg = "Some 'VolumeId' values in the imported file are missing. Please download and re-submit the CSV file with complete data.";
                }
                else if (!this.editBulkVolume && results.length != this.contents.length && this.volumes.length > 1) {
                    this.isError = true;
                    this.parseErrorMsg = "The imported file does not have same number of rows to match the existing contract volumes. Please download  and re-submit the csv file.";
                }
                else {
                    //validation that volume field contains a number

                    this.contents = results.map(row => ({
                        ...row,
                        volume: this.validateNumberInput(String(row.volume)),
                    }));
                    this.parsed = true;
                    this.isError = false;
                }

            },
            mapCsvToVolumes(parsedData: any[]): Volume[] {
                const volumes: Volume[] = [];

                parsedData.forEach((row: any) => {
                    const addedVolume: Volume = {
                        id: 0,
                        purchaseGroupId: this.purchaseGroupId,
                        periodId: row.id,
                        tradeableVolume: row.volume,
                        tradedVolume: 0, // Set to an appropriate default value.
                        exposedVolume: 0, // Set to an appropriate default value.
                        calculatedVolume: 0, // Set to an appropriate default value.
                    };

                    volumes.push(addedVolume);
                });

                return volumes;
            },
            mapCsvToUpdateVolumes(parsedData: any[]): VolumeHistory[] {
                const volumes: VolumeHistory[] = [];

                parsedData.forEach((row: any) => {
                    const addedVolume: VolumeHistory = {
                        volumeId: row.volumeId,
                        justification: 'bulk file update',
                        periodId: row.id,
                        tradeableVolume: row.volume,
                        fileUpload: null,
                    };

                    volumes.push(addedVolume);
                });

                return volumes;
            },
            async saveUploadedVolumes() {

                if (this.parsed) {

                    
                    // Call your service method to save the volumes to the database
                    try {
                        // Assuming you have a method in your service to save multiple volumes
                        //SB: This just needs pure refactoring, but i'm in a rush.
                        const rowsToAdd = this.contents.filter(c => c.volumeId == null && (c.volume === undefined || c.volume > 0));
                        const rowsToEdit = this.contents.filter(c => c.volumeId && c.volumeId !== 0);
                        if (rowsToEdit.length > 0) {
                          const volumesToSave = this.mapCsvToUpdateVolumes(rowsToEdit);
                          await purchaseGroupService.updateVolumes(volumesToSave);
                        }
                        if (rowsToAdd.length > 0) {
                          const volumesToSave = this.mapCsvToVolumes(rowsToAdd);
                          await purchaseGroupService.addVolumes(volumesToSave);
                        }
                      
                        // Optionally, you can update your UI to reflect the changes
                        // Reload the data or update the existing data in this.volumes

                        // Clear the CSV-related data
                        this.parsed = false;
                        this.isError = false;
                        this.parseErrorMsg = '';
                        this.csvUploads = [];

                        this.volumes = await purchaseGroupService.getVolumesByPurchaseGroupId(this.purchaseGroupId);


                    } catch (error) {
                        // Handle any errors that may occur during saving
                        console.error('Error saving volumes:', error);

                        // Display an error message or perform other error handling actions
                        // ...
                    }
                } else {
                    // Handle cases where CSV data hasn't been parsed yet
                    // Display an error message or perform other actions as needed
                    // ...
                }
            },
            getPeriod(periodId: Number) {
                var period = this.periods.find(p => p.id == periodId);

                return period;
            },
            daysInPeriod(periodId: Number): number {
                var period = this.getPeriod(periodId);
                if (!period) {
                    return 0;
                }

                var startDate = new Date(period.startDate);
                var endDate = new Date(period.endDate);

                // To calculate the time difference of two dates
                var differenceInTime = endDate.getTime() - startDate.getTime();

                // To calculate the no. of days between two dates
                var daysInPeriod = Math.round(differenceInTime / (1000 * 3600 * 24));
                return daysInPeriod;
            },
            openNew() {
                this.volume = { ...this.newVolume };
                this.submitted = false;
                this.volumeDialog = true;
            },
            hideDialog() {
                this.volumeDialog = false;
                this.submitted = false;
            },
            async saveVolume(event: VolumeHistory) {
                this.submitted = true;


                if (event.id) {
                    const volumeHistory: VolumeHistory = {
                        volumeId: event.id,
                        justification: event.justification,
                        periodId: event.periodId,
                        tradeableVolume: event.tradeableVolume,
                        fileUpload: event.fileUpload,
                    }

                    const editedVolume = {
                        ...this.volume
                    }
                    delete editedVolume.justification;
                    delete editedVolume.fileUpload;

                    await purchaseGroupService.updateVolume(volumeHistory);

                    this.volumes[this.findIndexById(event.id)] = editedVolume;
                }
                else {
                    const returnedVolume = await purchaseGroupService.addVolume(this.volume);
                    if (returnedVolume != null) {
                        const addedVolume: Volume = {
                            ...returnedVolume,
                            tradedVolume: 0,
                            exposedVolume: this.volume.tradeableVolume,
                        }
                        this.volumes.push(addedVolume);
                    }
                }
                this.hideDialog();
                this.volume = { ...this.newVolume };
            },
            editVolume(volume: Volume) {
                this.volume = { ...volume };
                this.volumeDialog = true;
            },
            confirmDeleteVolume(volume: Volume) {
                this.volume = volume;
                this.deleteVolumeDialog = true;
            },
            async deleteVolume() {
                await purchaseGroupService.deleteVolume(this.volume.id);

                this.volumes = this.volumes.filter(val => val.id !== this.volume.id);

                this.deleteVolumeDialog = false;
                this.volume = { ...this.newVolume };
            },
            confirmDeleteSelected() {
                this.deleteVolumesDialog = true;
            },
            async deleteSelectedVolumes() {
                const volumeIdsToDelete = this.selectedVolumes.map(v => v.id);
                await purchaseGroupService.deleteVolumes(volumeIdsToDelete);

                this.volumes = this.volumes.filter(val => !volumeIdsToDelete.includes(val.id));
                this.deleteVolumesDialog = false;
                this.selectedVolumes = [];
            },
            findIndexById(id: Number) {
                let index = -1;
                for (let i = 0; i < this.volumes.length; i++) {
                    if (this.volumes[i].id === id) {
                        index = i;
                        break;
                    }
                }

                return index;
            },
            initFilters() {
                this.filters = {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                }
            },
            isDeleteable(tradeAmount: number) {
                return tradeAmount != 0 ? true : false;
            },
            async onOverride() {
                await purchaseGroupService.setVolumesOverride(this.purchaseGroupId, this.isOverride);
            },
            getCalculatedVolume(periodId: number) {
                var summed = 0;
                //sum readVolumes for periodId across all contracts
                this.customerContracts.forEach(cc => {
                    var matchedVolume = cc.volumes.find(v => v.periodId == periodId);
                    if (matchedVolume && matchedVolume.readVolume != undefined) summed += matchedVolume.readVolume;
                });
                return summed;
            },
            countDecimalPlaces(numberVal: number) {
                const str = numberVal.toString();
                const index = str.indexOf('.');
                if (index === -1) return 0;
                return str.length - index - 1;
            },
            validateNumberInput(value: String) {
                let numberPattern = /-?\d*\.?\d+/g;
                let numbers = value.match(numberPattern);

                if (numbers === null) {
                    return 0;
                }

                return parseFloat(numbers.join(''));
            },
        },
        computed: {
            volumesList() {
                const volumesList = this.volumes ? this.volumes.map(v => ({
                    ...v,
                    exposedVolume: v.tradeableVolume - v.tradedVolume,
                    calculatedVolume: this.getCalculatedVolume(v.periodId.valueOf()),
                })) : [];

                return volumesList;
            },
            VolumeService() {
                return VolumeService
            },
            referencedVolumeHeader() {
                return this.commodityTypeId === 1 ? 'Reference Volume (therms/day)' : 'Reference Volume (MW)';
            },
            calculatedVolumeHeader() {
                return this.commodityTypeId === 1 ? 'Calculated Volume (therms/day)' : 'Calculated Volume (MW)';
            },
            tradeableVolumeHeader() {
                return this.commodityTypeId === 1 ? 'Traded Volume (therms/day)' : 'Traded Volume (MW)';
            },
            exposedVolumeHeader() {
                return this.commodityTypeId === 1 ? 'Exposed Volume (therms/day)' : 'Exposed Volume (MW)';
            },
        }
    })
