
    import { defineComponent, PropType } from 'vue';
    import { productService, Product } from '@/services/ProductService';
    import { Lookup } from '@/services/Types';
    import { FilterMatchMode } from 'primevue/api';
    import MultiSelect from 'primevue/multiselect';
    import ProductItem from '@/components/Settings/Products/ProductItem.vue';

    interface Data {
        loading: boolean,
        suppliers: Lookup[],
        utilityTypes: Lookup[],
        contractTypes: Lookup[],
        productListFilters: {},
        newProduct: Product | null,
        addProductDialog: boolean,
    }

    export default defineComponent({
        props: {
            products: {
                type: Array as PropType<Array<Product>>,
                required: true
            }
        },
        emits: [
            "add:product",
        ],
        data(): Data {
            return {
                loading: true,
                suppliers: [],
                utilityTypes: [
                    { id: 1, name: "HH" },
                    { id: 2, name: "NHH" },
                    { id: 3, name: "Gas" },
                    { id: 12, name: "HH & NHH" },
                ],
                contractTypes: [],
                newProduct: null,
                addProductDialog: false,
                productListFilters: {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'supplierId': { value: null, matchMode: FilterMatchMode.IN },
                    'utilityTypeId': { value: null, matchMode: FilterMatchMode.IN },
                    'contractTypeId': { value: null, matchMode: FilterMatchMode.IN },
                },
            };
        },
        components: {
            ProductItem,
            MultiSelect
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        computed: {
        },
        methods: {
            async fetchData() {

                this.suppliers = await productService.getSuppliers();
                //this.profileClasses = await productService.getProfileClasses();
                this.contractTypes = await productService.getContractTypes();

                this.loading = false;
                
            },
            onRowSelect(event: any) {
                console.log(event);
                this.$router.push({ name: 'editProduct', params: { productId: event.data.id } })
            },
            addNewProduct() {

                this.newProduct = {
                    id: 0,
                    name: "",
                    utilityTypeId: 0,
                    supplierId: 0,
                    //profileClassId: 0,
                    contractTypeId: 0,
                    isShapeFee: true,
                    isNCC: true,
                    isCapacityCharge: true,
                    isDailyStandingCharge: true,
                    deliveryWindow: 12,
                };

                this.addProductDialog = true;
            },
            async onAddProduct(addProductEvent: any) {
                console.log("Creating product...");

                await productService.addProduct(addProductEvent)

                this.$emit('add:product', addProductEvent);

                console.log("created successfully!");

                this.addProductDialog = false;
            },
            lookupUtilityType(utilId: number) {
                let utility = this.utilityTypes.find(ut => ut.id == utilId);
                if (utility == undefined) return "unknown";
                else return utility.name;
            }
        }
    });
