
    import { defineComponent/*, PropType*/ } from 'vue';
    import { parse, ParseMeta } from "papaparse";
    //import { Lookup } from '@/services/Types';
    import { Form } from 'vee-validate';
    //import * as yup from "yup";
    import dayjs from "dayjs";
    import customParseFormat from 'dayjs/plugin/customParseFormat';
    dayjs.extend(customParseFormat);
    import Message from 'primevue/message';
    import DocumentUpload from '@/components/Common/DocumentUpload.vue';
    import { /*tradeService,*/ TradeImport } from '@/services/TradeService';
    import { purchaseGroupService } from '@/services/PurchaseGroupService';
    import { periodService } from '@/services/PeriodService';

    interface Data {
        loading: boolean,
        newTrade: TradeImport,
        newImport: File | null,
        bulkImports: any[],
        contents: any[],
        parsed: boolean,
        isError: boolean,
        errorMessage: string,
    }

    export default defineComponent({

        props: {
        },
        components: {
            Form,
            DocumentUpload,
            Message,
        },
        data(): Data {

            return {
                loading: false,
                newImport: null,
                bulkImports: [],
                contents: [],
                parsed: false,
                isError: false,
                errorMessage: '',
                newTrade: {
                    TradeDate: dayjs().toISOString(),
                    TradeType: 'Index',
                    BasePeak: 'Base',
                    Product: 'Month',
                    Period: 'Aug-21',
                    Client: '',
                    BuySell: 'Buy',
                    Broker: 'Flagship Energy',
                    ContractPrice: 0,
                    ContractSize: 0,
                    ContractSizeHours: 0,
                    TotalQuantity: 0,
                    TotalCost: 0,
                    Status: 'Pending',
                    id: 0,
                    tradeTypeId: 1,
                    tradeStatusId: 4,
                    tradeMethodId: 1,
                    commodityTypeId: 1,
                    totalTradeVolume: 0,
                    tradeValue: 0,
                    supplierOfferPrice: 0,
                    indexCharge: 0,
                    sellBackCharge: 0,
                    purchaseGroups: [],
                    periods: [],
                    notes: "Bulk import",
                }
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        watch: {
        },
        methods: {
            onSubmit() {

                console.log("Submitting:", this.contents);
                this.$emit('submit:import', this.contents);

            },
            onDownloadCsv() {

                var csvContent = "data:text/csv;charset=utf-8,";
                var headers = ["Trade Date", "TradeType", "Period", "Purchase Group", "BuySell", "Trade Price", "Trade Volume", "Status"];
                csvContent += headers.join(',') + '\n';

                //  csvContent += this.csvContent;

                var encodedUri = encodeURI(csvContent);
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", `trades-bulk-import.csv`);
                document.body.appendChild(link); // Required for FF
                link.click();
                document.body.removeChild(link);
            },
            async fetchData() {

                this.loading = false;

            },
            async onSelectImport(files: File[]) {
                this.bulkImports = files.length > 0 ? files : [];
                this.newImport = files.length > 0 ? files[0] : null;
                //console.log('Selected:', this.newImport);
                await this.parseFile();

                //Fetch PG from imported name
                const purchaseGroups = await purchaseGroupService.getPurchaseGroups();
                const periods = await periodService.getPeriods();
                //Only import contents if no parsing errors encountered
                if (this.isError) {
                    this.errorMessage = "No trades recognised in this file. The imported file does not match the expected format.";
                } else {
                    this.contents = this.contents.map(trade => {
                        const pg = purchaseGroups.find(pg => pg.name.toLowerCase() == trade.Client.toLowerCase());
                        const period = periods.find(p => p.name.toLowerCase() == trade.Period.toLowerCase());

                        if (pg == undefined) {
                            this.isError = true;
                            this.errorMessage = `Import failed. Purchase group ${trade.Client} not found.`;
                            return null;
                        } else if (period == undefined) {
                            this.isError = true;
                            this.errorMessage = `Import failed. Period ${trade.Period} not found.`;
                            return null;
                        } else {
                            this.errorMessage = `${this.contents.length} trade records found.`;
                            return {
                                ...trade,
                                tradeDateTime: trade.TradeDate,
                                selectedPurchaseGroupIds: [pg.id],
                                selectedPeriodIds: [period.id],
                                commodityTypeId: pg.commodityTypeId,
                                tradeMethodId: trade.BuySell == 'Sell' ? 2 : 3,
                                totalTradeVolume: trade.TotalQuantity,
                                tradeValue: trade.TotalCost,
                                supplierOfferPrice: trade.ContractPrice,
                                tradeStatusId: trade.Status == 'Approved' ? 4 : 1,
                                notes: trade.Status == 'Approved' ? "Historical bulk import" : "Bulk import",
                            };
                        }
                    }).filter(t => t !== null);

                    if ((!this.isError) && (this.contents.length > 0)) {
                        this.$emit('update:import', this.contents);
                    } else {
                        this.isError = true;
                    }
                }

            },
            onRemoveImport(index: number) {
                let removedFile = this.bulkImports.splice(index, 1)[0];
                this.bulkImports = [...this.bulkImports];
                this.$emit('remove:import', removedFile);
            },
            parseFile() {
                parse(this.bulkImports[0], {
                    download: true,
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    //transformHeader: (header: string) => header.replace(this.units, 'volume'),
                    complete: (result) => this.parseResults(result.data, result.meta),
                });
            },
            parseResults(results: any[], meta: ParseMeta) {
                this.isError = false;
                this.errorMessage = 'loading...';
                console.log("read:", meta);
                if (results.length == 0) {
                    this.isError = true;
                } else {
                    //process upload contents
                    this.contents = [];
                    var offset = dayjs().utcOffset();
                    //var headerField = meta.fields != undefined ? meta.fields[2] : 'Usage';
                    results.forEach(row => {
                        if (Object.values(row).every(val => val == null || val === "")) {
                            return;  // Skip this iteration
                        }
                        var reading = this.newTrade;
                        var tradeTime = row["Trade Date"] ? dayjs(row["Trade Date"], "DD/MM/YYYY") : dayjs(reading.TradeDate);
                        tradeTime = tradeTime.add(offset, 'minute').add(12, 'hour');
                        var tradeDateTime = tradeTime.toDate();
                        
                        this.contents.push({
                            ...reading,
                            TradeDate: tradeDateTime,
                            TradeType: row.TradeType,
                            //   BasePeak: row.BasePeak,
                            //   Product: row.Product,
                            Period: row.Period,
                            Client: row['Purchase Group'],
                            BuySell: row.BuySell,
                            //    Broker: row.Broker,
                            ContractPrice: this.validateNumberInput(String(row['Trade Price']), tradeDateTime),
                            //    ContractSize: this.validateNumberInput(String(row.ContractSize), tradeDateTime),
                            //    ContractSizeHours: this.validateNumberInput(String(row.ContractSizeHours), tradeDateTime),
                            TotalQuantity: this.validateNumberInput(String(row['Trade Volume']), tradeDateTime),
                            //      TotalCost: this.validateNumberInput(String(row.TotalCost), tradeDateTime),
                            Status: row.Status,
                        })
                    });
                    this.parsed = true;
                }
            },
            validateNumberInput(value: String, fieldValue: Date) {
                let numberPattern = /-?\d*\.?\d+/g;
                let numbers = value.match(numberPattern);

                if (numbers === null) {
                    this.isError = true;
                    this.errorMessage = "Invalid field values encountered for " + fieldValue.toISOString();
                    return 0;
                }

                return parseFloat(numbers.join(''));
            },
        }
    });
