
    import { defineComponent } from 'vue';
    import { Lookup } from '@/services/Types';
    import { User, userService } from '@/services/UserService';
    import { TradeHistory, Trade, tradeService } from '@/services/TradeService';
    import { Period, periodService } from '@/services/PeriodService';
    import { formatDate } from '@/composables/UtilityFunctions';
    import { FilterMatchMode, FilterOperator } from 'primevue/api';
    import MultiSelect from 'primevue/multiselect';
    import { CustomFilterMatchMode } from '@/composables/Filters';
    import Badge from 'primevue/badge';
    import TradeAcceptance from '@/components/Trading/PreviousTrading/TradeAcceptance.vue';
    import { VolumeService } from "../../services/VolumeService";

    interface Data {
        loading: boolean,
        statuses: Lookup[],
        types: Lookup[],
        methods: Lookup[],
        periods: Period[],
        users: User[],
        trades: TradeHistory[],
        tradingListFilters: {},
        selectedTrade: Trade | null,
        showTradeInfoDialog: boolean,
        buySellOptions: Lookup[],
    }

    export default defineComponent({
        props: {
            purchaseGroupId: {
                type: Number,
                required: true
            },
        },
        components: {
            MultiSelect,
            Badge,
            TradeAcceptance
        },
        data(): Data {
            return {
                loading: true,
                statuses: [],
                types: [],
                methods: [],
                periods: [],
                users: [],
                trades: [],
                tradingListFilters: {
                    'periods': { value: null, matchMode: CustomFilterMatchMode.ANY_ID },
                    'tradeDateTime': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                    'status': { value: null, matchMode: FilterMatchMode.IN },
                    'method': { value: null, matchMode: FilterMatchMode.IN },
                    'type': { value: null, matchMode: FilterMatchMode.IN }, 
                    'supplierOfferPrice': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'user': { value: null, matchMode: FilterMatchMode.IN },  
                    'totalTradeVolume': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'buySell': { value: null, matchMode: FilterMatchMode.IN },
                    'cost': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
                },
                buySellOptions: [
                    { id: 1, name: "Buy" },
                    { id: 2, name: "Sell" }
                ],
                selectedTrade: null,
                showTradeInfoDialog: false,
            };
        },
        created() {
            this.fetchData();
        },
        methods: {
            async fetchData() {

                this.users = await userService.getUsers();
                this.periods = await periodService.getPeriods();
                this.statuses = await tradeService.getTradeStatuses();
                this.methods = await tradeService.getTradeMethods();
                this.types = await tradeService.getTradeTypes();
                this.trades = await tradeService.getTradeHistory(this.purchaseGroupId);

                this.loading = false;
            
            },
            formatDate(dateToFormat: string) {
                return formatDate(dateToFormat, 'DD/MM/YYYY HH:mm');
            },
            formatCurrency(value: number) {
                return value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });
            },
            formatCurrencyNoSymbol(value: number) {
              return value.toLocaleString('en-GB', { style: 'decimal', maximumFractionDigits:2});
            },
            async onRowSelect(event: any) {

                this.selectedTrade = await tradeService.getTradeById(event.data.id);
                this.showTradeInfoDialog = true;
            },
            async onTradeChange() {

                this.showTradeInfoDialog = false;

                // refresh trade list - or can we just get updated trade and replace? impact on paging/ filtering?
                this.trades = await tradeService.getTradeHistory(this.purchaseGroupId);
            },
        },
        computed: {
            tradesWithInfo() {

                const tradesWithInfo = this.trades ? this.trades.map(t => ({
                    ...t, 
                    status: this.statuses.find(s => s.id === t.tradeStatusId),
                    user: this.users.find(u => u.userId === t.creatorUserId),
                    type: this.types.find(tt => tt.id === t.tradeTypeId),
                    method: this.methods.find(m => m.id === t.tradeMethodId),
                    tradeDateTime: new Date(t.tradeDateTime ?? ""),
                    buySell: (t.totalTradeVolume ?? 0) > 0 ? this.buySellOptions[0] : this.buySellOptions[1]
                })) : [];
                const approvedTrades = tradesWithInfo.filter(t => t.status?.name == "Approved");
                return approvedTrades;
            },
            selectedTradeWithInfo(): Trade | null {

                const tradeWithInfo = this.selectedTrade ? {
                    ...this.selectedTrade,
                    status: this.statuses.find(s => s.id === this.selectedTrade?.tradeStatusId),
                    tradeDateTime: new Date(this.selectedTrade?.tradeDateTime ?? ""),
                    buySell: (this.selectedTrade?.totalTradeVolume ?? 0) > 0 ? this.buySellOptions[0] : this.buySellOptions[1]
                } : null;

                return tradeWithInfo;
            },
            VolumeService() {
                return VolumeService
            },
        }
    });
