
    import { defineComponent, PropType } from 'vue';
    import { contactService, Contact } from '@/services/ContactService';
    import { Lookup } from '@/services/Types';
    import { customerService, Customer } from '@/services/CustomerService';
    import { FilterOperator, FilterMatchMode } from 'primevue/api';
    import MultiSelect from 'primevue/multiselect';
    import { CustomFilterMatchMode } from '@/composables/Filters';

    interface Data {
        loading: boolean,
        customers: Customer[],
        contactTypes: Lookup[],
        contactListFilters: {},
    }

    export default defineComponent({
        props: {
            contacts: {
                type: Array as PropType<Array<Contact>>,
                required: true
            }
        },
        data(): Data {
            return {
                loading: true,
                customers: [],
                contactTypes: [],
                contactListFilters: {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'fullName': {
                        operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
                    },
                    'customerName': {
                        operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
                    },
                    'email': {
                        operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
                    },
                    'jobTitle': {
                        operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
                    },
                    'contactTypes': {
                        operator: FilterOperator.AND, constraints: [{ value: null, matchMode: CustomFilterMatchMode.ANY_ID }]
                    },
                },
            };
        },
        components: {
            MultiSelect
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        computed: {
            contactsWithCustomerInfo():Contact[] | null {

                let contactsWithCustomers = this.contacts ? this.contacts.map(con => ({...con, customerName: this.customers.find(cus => cus.id === con.customerId)?.name})) : [];
                return contactsWithCustomers;
            }
        },
        methods: {
            async fetchData() {

                this.contactTypes = await contactService.getContactTypes();
                this.customers = await customerService.getCustomers();

                this.loading = false;
                
            },
            onRowSelect(event: any) {

                this.$router.push({ name: 'editContact', params: { contactId: event.data.id } })
            },
        }
    });
