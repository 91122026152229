
      import { Auth } from 'aws-amplify';
      import { defineComponent } from 'vue';
      export default defineComponent({
          data() {
              return {
                  user: {},
                  items: [
                      {
                          label: 'Products',
                          icon: require('@/assets/images/icons/Contracts.svg'),
                          to: '/products'
                      },
                  //    {
                  //        label: 'Customers',
                  //        icon: require('@/assets/images/icons/Customers-white.svg'),
                  //        to: '/customers'
                  //    },
                      {
                          label: 'Consumption Imports',
                          icon: require('@/assets/images/icons/PG-white.svg'),
                          to: '/consumption-imports'
                      },
                      {
                          label: 'Triggers',
                          icon: require('@/assets/images/icons/PG-white.svg'),
                          to: '/triggers'
                      },
                      {
                          label: 'Report Settings',
                          icon: require('@/assets/images/icons/PG-white.svg'),
                          to: '/report-settings'
                      }
                  //    {
                  //        label: 'Trading',
                  //        icon: require('@/assets/images/icons/Trading-white.svg'),
                  //        to: '/trading'
                  //    },
                  //    {
                  //        label: 'Reports',
                  //        icon: require('@/assets/images/icons/Reporting-white.svg'),
                  //        to: '/report'
                  //    },
                  //    {
                  //        label: 'Settings',
                  //        icon: require('@/assets/images/icons/Settings-white.svg'),
                  //        to: '/settings'
                  //    },
                  ]
              }
          },
          beforeCreate() {
              Auth.currentAuthenticatedUser()
                  .then(user => {
                      this.user = user;
                  })
                  .catch(() => console.log('not signed in...'))
          }
      });
