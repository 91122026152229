
    import { defineComponent } from 'vue';
    import { purchaseGroupService, PurchaseGroup } from '@/services/PurchaseGroupService';
    //import { Document } from '@/services/Types';

    import PurchaseGroupItem from '@/components/PurchaseGroups/PurchaseGroupItem.vue';
    import PurchaseGroupContractsList from '@/components/PurchaseGroups/PurchaseGroupContractsList.vue';
    import Editor from 'primevue/editor';
    //import DocumentUpload from '@/components/Common/DocumentUpload.vue';

    interface Data {
        loading: boolean,
        purchaseGroup: PurchaseGroup | null,
        newDocuments: File[],
        documentsToRemove: string[],
        removeExistingContract: boolean,
        isSaving: boolean,
    }

    export default defineComponent({

        props: {
            purchaseGroupId: {
                type: Number,
                required: true
            },
        },
        components: {
            PurchaseGroupItem,
            PurchaseGroupContractsList,
            Editor,
            //DocumentUpload,
        },
        data(): Data {

            return {
                loading: false,
                purchaseGroup: null,
                newDocuments: [],
                documentsToRemove: [],
                removeExistingContract: false,
                isSaving: false,
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                console.log("fetch data...")

                this.loading = true;

                this.purchaseGroup = await purchaseGroupService.getPurchaseGroupById(this.purchaseGroupId);
                //await this.getCustomerContactsById(this.purchaseGroupId);

                this.loading = false;
                
            },
            async onSubmit(event: any) {
                this.isSaving = true;

                console.log("Submitting:", event);

                // overwrite contract property
                const updatePurchaseGroup = {
                    ...event,
                    notes: this.purchaseGroup?.notes,
                    //documents: this.newDocuments,
                    //documentsToRemove: this.documentsToRemove,
                    removeExistingContractDocument: this.removeExistingContract,
                };

                await purchaseGroupService.updatePurchaseGroup(updatePurchaseGroup);

                this.$emit('update:purchaseGroup', updatePurchaseGroup);

                this.purchaseGroup = await purchaseGroupService.getPurchaseGroupById(this.purchaseGroupId); // refresh purchase group data

                this.isSaving = false;

                console.log("save successful!");
                
            },
            //onSelectDoc(event: File[]) {
            //    this.newDocuments = event;
            //},
            //onRemoveDoc(document: Document) {
            //    this.documentsToRemove.push(document.id);
            //},
            onRemoveContract() {
                this.removeExistingContract = true;
            },
        }
    });
