
    import { defineComponent, PropType } from 'vue';
    import { supplyPointService, SupplyPoint } from '@/services/SupplyPointService';
    import GasSupplyPoint from '@/components/SupplyPoints/GasSupplyPoint.vue';
    import HHElectricitySupplyPoint from '@/components/SupplyPoints/HHElectricitySupplyPoint.vue';
    import NHHElectricitySupplyPoint from '@/components/SupplyPoints/NHHElectricitySupplyPoint.vue';
    import GasSupplyPointReadings from '@/components/SupplyPoints/GasSupplyPointReadings.vue';
    import HHElectricitySupplyPointReadings from '@/components/SupplyPoints/HHElectricitySupplyPointReadings.vue';
    import NHHElectricitySupplyPointReadings from '@/components/SupplyPoints/NHHElectricitySupplyPointReadings.vue';

    interface Data {
        loading: boolean,
        reportHalfHourly: boolean,
        reportDaily: boolean,
        reportMonthly: boolean,
    }

    export default defineComponent({

        props: {
            supplyPoint: {
                type: Object as PropType<SupplyPoint>,
                required: true
            },
        },
        components: {
            GasSupplyPoint,
            HHElectricitySupplyPoint,
            NHHElectricitySupplyPoint,
            GasSupplyPointReadings,
            HHElectricitySupplyPointReadings,
            NHHElectricitySupplyPointReadings,
        },
        data(): Data {

            return {
                loading: false,
                reportHalfHourly: false,
                reportDaily: false,
                reportMonthly: true,
            };
        },
        created() {
            // fetch on init         
        },
        computed: {
            reportPeriod() {
                var reportType;
                if (this.reportHalfHourly) reportType = "HalfHourly";
                else if (this.reportDaily) reportType = "Daily";
                else reportType = "Monthly";
                return reportType;
            },
        },
        methods: {
            onUpdateSupplyPoint(updatedSupplyPoint: SupplyPoint) {

                //supplyPoint values already updated so no need to use event data?

                supplyPointService.updateSupplyPoint(updatedSupplyPoint)

                console.log("save successful!")

                this.$emit('submit:supplyPoint', updatedSupplyPoint);
            },
            async onAddSupplyPoint(newSupplyPoint: SupplyPoint) {
                
                await supplyPointService.addSupplyPoint(newSupplyPoint);

                console.log("add successful!")

                this.$emit('submit:supplyPoint', newSupplyPoint);               
            },
            onReportHalfHourly() {
                this.reportDaily = false;
                this.reportMonthly = false;
            },
            onReportDaily() {
                this.reportHalfHourly = false;
                this.reportMonthly = false;
            },
            onReportMonthly() {
                this.reportDaily = false;
                this.reportHalfHourly = false;
            },

        },
    });
