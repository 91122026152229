
    import { defineComponent } from 'vue';
    import { PurchaseGroup, purchaseGroupService } from '@/services/PurchaseGroupService';
    import { customerContractService } from '@/services/ContractService';
    import { Lookup, TreeNode } from '@/services/Types';
    import PurchaseGroupItem from '@/components/PurchaseGroups/PurchaseGroupItem.vue';
    import MultiSelect from 'primevue/multiselect';
    import { formatDate } from '@/composables/UtilityFunctions';
    import { FilterMatchMode, FilterOperator } from 'primevue/api';
    //import { CustomFilterMatchMode } from '@/composables/Filters';

    interface Data {
        loading: boolean,
        nodes: TreeNode[],
        purchaseGroups: PurchaseGroup[],
        statuses: Lookup[],
        suppliers: Lookup[] | null,
        newPurchaseGroup: PurchaseGroup | null,
        addPurchaseGroupDialog: boolean,
        purchaseGroupListFilters: {}
    }

    export default defineComponent({
        data(): Data {
            return {
                loading: true,
                nodes: [],
                purchaseGroups: [],
                statuses: [],
                suppliers: [],
                newPurchaseGroup: null,
                addPurchaseGroupDialog: false,
                purchaseGroupListFilters: {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
                    'supplierId': { value: null, matchMode: FilterMatchMode.IN },
                    'startDate': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                    'endDate': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                    //'status': { value: null, matchMode: FilterMatchMode.IN },
                },
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        components: {
            MultiSelect,
            PurchaseGroupItem
        },
        //watch: {
        //    // call again the method if the route changes
        //    '$route': 'fetchData' //todo: what does this do?
        //},
        methods: {
            async fetchData() {

                console.log("fetch data...")

                this.statuses = await purchaseGroupService.getPurchaseGroupStatuses();
                this.suppliers = await customerContractService.getSuppliers();

                this.purchaseGroups = await purchaseGroupService.getPurchaseGroups();

                this.loading = false;
                
            },
            formatDate(dateToFormat: string) {
                return formatDate(dateToFormat, 'DD/MM/YYYY');
            },
            onRowSelect(node: TreeNode) {
                console.log(node);
                this.$router.push({ name: 'editPurchaseGroup', params: { purchaseGroupId: node.data.id } })
            },
            addNewPurchaseGroup() {
                console.log("add new purchase group!");

                this.newPurchaseGroup = {
                    id: 0,
                    name: "",
                    statusId: 1,
                    allowedTradeTypes: [],
                    volumesOverride: false,
                    volumes: [],
                    contracts: []
                };

                this.addPurchaseGroupDialog = true;
            },
            async onAddPurchaseGroup(addPurchaseGroupEvent: any) {

                await purchaseGroupService.addPurchaseGroup(addPurchaseGroupEvent)

                this.$emit('add:purchaseGroup', addPurchaseGroupEvent);
                    

                console.log("save successful!")

                // now refresh main list of purchase groups...
                this.fetchData();

                this.addPurchaseGroupDialog = false;
            }
        },
        computed: {
            purchaseGroupsWithInfo(): PurchaseGroup[] {

                const purchaseGroupsWithInfo = this.purchaseGroups.map(pg => ({
                    ...pg,
                    startDate: new Date(pg.startDate ?? ""),
                    endDate: new Date(pg.endDate ?? ""),
                }));

                return purchaseGroupsWithInfo;
            },
        }
    });
