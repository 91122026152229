import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SiteList = _resolveComponent("SiteList")!
  const _component_Button = _resolveComponent("Button")!
  const _component_SiteItem = _resolveComponent("SiteItem")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SiteList, {
      sites: _ctx.sites,
      class: "mb-3"
    }, null, 8, ["sites"]),
    _createVNode(_component_Button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addNewSite()))
    }, {
      default: _withCtx(() => [
        _createTextVNode("Add Site")
      ]),
      _: 1
    }),
    _createVNode(_component_Dialog, {
      visible: _ctx.addSiteDialog,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.addSiteDialog) = $event)),
      style: {width: '50vw'},
      header: "Add Site",
      modal: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SiteItem, {
          editSite: _ctx.newSite,
          "onSubmit:site": _ctx.onAddSite
        }, null, 8, ["editSite", "onSubmit:site"]),
        _createVNode(_component_Button, {
          type: "submit",
          form: "site-form",
          icon: "pi pi-plus",
          label: "Create",
          class: "p-button-lg"
        })
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}