
    import { defineComponent, PropType } from 'vue';
    import * as yup from "yup";
    import { Form, Field, ErrorMessage } from 'vee-validate';
    import { Volume } from '@/services/PurchaseGroupService';
    import DocumentUpload from '@/components/Common/DocumentUpload.vue';
    import { Period, periodService } from '@/services/PeriodService';

    interface Data {
        validationSchema: any,
        newFileDocument: Document | null,
        fileDocuments: any[],
        volume: Volume,
        periods: Period[]
    }

    export default defineComponent({
        components: {
            Form,
            Field,
            ErrorMessage,
            DocumentUpload
        },
        props: {
            editVolume: {
                type: Object as PropType<Volume>,
                required: true
            },
        },
        data(): Data {
            const validationSchema = yup.object({
                periodId: yup.number().required(),
                tradeableVolume: yup.number().required(),
                justification: yup.string().when("id", (id, schema) => {
                    if (id) { return schema.required(); }
                    return schema.notRequired();
                }),
            });

            return {
                validationSchema: validationSchema,
                fileDocuments: [],
                newFileDocument: null,
                volume: this.editVolume,
                periods: []
            }
        },
        watch: {
            volume(newValue) {
                console.log("documents changed")
                this.volume = newValue;
                this.fileDocuments = newValue.fileUpload ? [newValue.fileUpload] : [];
                this.newFileDocument = null;
            },
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {
                this.periods = await periodService.getPeriods();
            },
            onSubmit() {

                const editedVolume = {
                    ...this.volume,
                    fileUpload: this.newFileDocument,
                }

                console.log("Submitting:", editedVolume);

                this.$emit('submit:volume', editedVolume);

            },
            onSelectDocument(event: any) {

                console.log("select doc");
                console.log(event);

                this.newFileDocument = event;

                this.$emit('select:fileDocuments', this.newFileDocument);
            },
            onRemoveDocument(index: number) {

                let removedFile = this.fileDocuments.splice(index, 1)[0];

                this.fileDocuments = [...this.fileDocuments];

                this.$emit('remove:fileDocuments', removedFile);
            },
        }
    });

