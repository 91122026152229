import { createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, {
      value: _ctx.tradesWithInfo,
      selectionMode: "single",
      dataKey: "id",
      responsiveLayout: "scroll",
      filters: _ctx.tradingListFilters,
      "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tradingListFilters) = $event)),
      filterDisplay: "menu",
      paginator: true,
      rows: 20,
      loading: _ctx.loading
    }, {
      empty: _withCtx(() => [
        _createTextVNode(" No trades have been made for this purchase group. ")
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          filterField: "periods",
          header: "Periods",
          showFilterMatchModes: false,
          filterMenuStyle: {'width':'16rem'}
        }, {
          body: _withCtx(({data}) => [
            _createTextVNode(_toDisplayString(data.periods.map(c => c.name).join(', ')), 1)
          ]),
          filter: _withCtx(({filterModel}) => [
            _createVNode(_component_MultiSelect, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              options: _ctx.periods,
              optionLabel: "name",
              placeholder: "Any",
              filter: true
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "tradeDateTime",
          header: "Trade Date",
          dataType: "date",
          sortable: ""
        }, {
          body: _withCtx(({data}) => [
            _createTextVNode(_toDisplayString(_ctx.formatDate(data.tradeDateTime)), 1)
          ]),
          filter: _withCtx(({filterModel}) => [
            _createVNode(_component_Calendar, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              dateFormat: "dd/mm/yy",
              showTime: true
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "tradeValue",
          header: "Trade Value",
          dataType: "numeric",
          sortable: ""
        }, {
          body: _withCtx(({data}) => [
            _createTextVNode(_toDisplayString(_ctx.formatCurrency(data.tradeValue)), 1)
          ]),
          filter: _withCtx(({filterModel}) => [
            _createVNode(_component_InputNumber, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              mode: "currency",
              currency: "GBP",
              locale: "en-GB"
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "totalTradeVolume",
          header: "Trade Volume",
          dataType: "numeric",
          sortable: ""
        }),
        _createVNode(_component_Column, {
          field: "supplierOfferPrice",
          header: "Trade Rate",
          dataType: "numeric",
          sortable: ""
        }, {
          body: _withCtx(({data}) => [
            _createTextVNode(_toDisplayString(_ctx.formatCurrency(data.supplierOfferPrice)), 1)
          ]),
          filter: _withCtx(({filterModel}) => [
            _createVNode(_component_InputNumber, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              mode: "currency",
              currency: "GBP",
              locale: "en-GB"
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "filters", "loading"])
  ]))
}