/* Amplify UI */
import AmplifyVue from '@aws-amplify/ui-vue';
import '@aws-amplify/ui-vue/styles.css';

/* AWS Amplify */
import { Amplify } from 'aws-amplify';
import awsConfig from './aws-exports';

//Amplify.Logger.LOG_LEVEL = 'DEBUG';

Amplify.configure(awsConfig);

/* Vue Router */
import router from './router';


/* create and mount root App instance */
import { createApp } from 'vue'
import App from './App.vue'

/* Prime Vue */
import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Tree from 'primevue/tree';
import TreeTable from 'primevue/treetable';
import Panel from 'primevue/panel';
import Card from 'primevue/card';
import TabMenu from 'primevue/tabmenu';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import Checkbox from 'primevue/checkbox';
import ToggleButton from 'primevue/togglebutton';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import Calendar from 'primevue/calendar';
import Divider from 'primevue/divider';
import FileUpload from 'primevue/fileupload';
import RadioButton from 'primevue/radiobutton';
import Textarea from 'primevue/textarea';
import Toolbar from 'primevue/toolbar';
import Message from 'primevue/message';


import "primevue/resources/primevue.min.css";
import "./assets/themes/flagship/theme.scss";
// import "primevue/resources/themes/lara-light-teal/theme.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css"; // Import the PrimeVue layout utility library.


// register custom global filters
import { FilterService } from 'primevue/api';
import { CustomFilterMatchMode, any, matchAnyId } from '@/composables/Filters';

FilterService.register(CustomFilterMatchMode.ANY, any);
FilterService.register(CustomFilterMatchMode.ANY_ID, matchAnyId);

const app = createApp(App);

app.use(router)
    .use(AmplifyVue)
    .use(PrimeVue)
    .component('Dialog', Dialog)
    .component('DataTable', DataTable)
    .component('Column', Column)
    .component('Button', Button)
    .component('Tree', Tree)
    .component('TreeTable', TreeTable)
    .component('Panel', Panel)
    .component('Card', Card)
    .component('TabMenu', TabMenu)
    .component('InputText', InputText)
    .component('InputMask', InputMask)
    .component('Checkbox', Checkbox)
    .component('ToggleButton', ToggleButton)
    .component('Dropdown', Dropdown)
    .component('InputNumber', InputNumber)
    .component('Calendar', Calendar)
    .component('Divider', Divider)
    .component('FileUpload', FileUpload)
    .component('RadioButton', RadioButton)
    .component('Textarea', Textarea)
    .component('Toolbar', Toolbar)
    .component('Message', Message)
    .mount('#app')
