
    import { defineComponent, PropType } from 'vue';
    import { triggerService, Trigger } from '@/services/TriggerService';
    import { periodService } from '@/services/PeriodService';
    import { Lookup } from '@/services/Types';
    import * as yup from "yup";
    import { Form, Field, ErrorMessage } from 'vee-validate';

    interface Data {
        loading: boolean,
        validationSchema: any,
        trigger: Trigger,
        commodityTypes: Lookup[],
        triggerTypes: Lookup[],
        periods: Lookup[],

    }

    export default defineComponent({

        props: {
            editTrigger: {
                type: Object as PropType<Trigger>,
                required: true
            },
        },
        components: {
            Form,
            Field,
            ErrorMessage,
        },
        data(): Data {

            const validationSchema = yup.object({
                //utilityTypeId: yup.number().required().label("Profile Type"),
                //baseUtilityTypeId: yup.number().required().label("Utility Type"),
                //supplierId: yup.number().required().label("Supplier"),
                //contractTypeId: yup.number().required().label("Contract Type"),
                //productName: yup.string().required().label("Product Name"),
                //isShapeFee: yup.boolean().required().label("Shape Fee"),
                //shapeFeeTypeId: yup.number().nullable().label("Shape Fee Charge Type"),
                //isNCC: yup.boolean().required().label("Non-Commodity Charge"),
                //nccTypeId: yup.number().nullable().label("Non-Commodity Charge Type"),
                //isCapacityCharge: yup.boolean().required().label("Capacity Charge"),
                //isDailyStandingCharge: yup.boolean().required().label("Daily Standing Charge"),
            });
            return {
                loading: false,
                validationSchema: validationSchema,
                trigger: this.editTrigger,
                commodityTypes: [],
                triggerTypes: [],
                periods: [],
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            onSubmit(values: any) {
                console.log("Submitting:", values);

                let editTrigger = {
                    ...this.trigger,
                }
                this.$emit('submit:trigger', editTrigger);

            },
            async fetchData() {
                this.commodityTypes = await triggerService.getCommodityTypes();
                this.triggerTypes = await triggerService.getTriggerTypes();
                var fetchedPeriods = await periodService.getCurrentAndFuturePeriods();

                this.periods = fetchedPeriods.map(period => ({
                    ...period,
                    name: `${period.name} (${this.formatDate(new Date(period.startDate))} to ${this.formatDate(new Date(period.endDate))})`
                }));

                this.loading = false;

            },
            formatDate(date: Date): string {
                const options = { day: '2-digit', month: 'short', year: '2-digit' } as Intl.DateTimeFormatOptions;
                return date.toLocaleDateString(undefined, options);
            },
        }
    });
