
import { Auth } from 'aws-amplify'
import { defineComponent } from 'vue';
import Menu from 'primevue/menu';

export default defineComponent({
        name: 'App',
        components: {
            Menu
        },
        data() {
            return {
                user: {},
                items: [
                    {
                        label: 'Customers',
                        icon: require('@/assets/images/icons/Customers-white.svg'),
                        to: '/customers'
                    },
                    {
                        label: 'Contacts',
                        icon: require('@/assets/images/icons/Contracts.svg'),
                        to: '/contacts'
                    },
                    {
                        label: 'Purchase Groups',
                        icon: require('@/assets/images/icons/PG-white.svg'),
                        to: '/purchase-groups'
                    },
                    {
                        label: 'Trading',
                        icon: require('@/assets/images/icons/Trading-white.svg'),
                        items: [
                            {
                                label: 'Market Trading',
                                to: '/market-trading'
                            },
                            {
                                label: 'Previous Trading',
                                to: '/previous-trading'
                            },
                        ]
                    },
                    {
                        label: 'Reports',
                        icon: require('@/assets/images/icons/Reporting-white.svg'),
                        to: '/reports'
                    },
                    {
                        label: 'Settings',
                        icon: require('@/assets/images/icons/Settings-white.svg'),
                        to: '/settings'
                    },

                ]
            }
        },
        methods: {
            async signUserOut() {
                try {
                    await Auth.signOut();
                } catch (error) {
                    console.log('error signing out: ', error);
                }
            }
        },
        beforeCreate() {
              Auth.currentAuthenticatedUser()
                  .then(user => {
                      this.user = user;
                  })
                  .catch(() => console.log('not signed in...'))
          }
});
