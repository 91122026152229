
    import { defineComponent, PropType } from 'vue';
    import * as yup from "yup";
    import { Form, Field } from 'vee-validate';
    import { FilterMatchMode } from 'primevue/api';
    import MultiSelect from 'primevue/multiselect';
    import { supplyPointService, SupplyPoint, ActiveSupplyPoint, GasSupplyPoint, ElectricitySupplyPoint } from '@/services/SupplyPointService';
    import { customerContractService, CustomerContract } from '@/services/ContractService';
    import { Product } from '@/services/ProductService';
    import { siteService, Site } from '@/services/SiteService';
    import { Lookup } from '@/services/Types';

    interface Data {
        loading: boolean,
        validationSchema: any,
        supplyPointIds: number[],
        customerSupplyPoints: SupplyPoint[],
        selectedSupplyPoints: SupplyPoint[],
        activeSupplyPoints: ActiveSupplyPoint[],
        sites: Site[],
        submitted: boolean,
        contract: CustomerContract,
        utilityTypes: Lookup[],
        supplyPointFilters: {}
    }

    export default defineComponent({
        props: {
            editContract: {
                type: Object as PropType<CustomerContract>,
                required: true
            },
            editProduct: {
                type: Object as PropType<Product>,
                required: false
            },
            editMode: Boolean,
            isActiveStep: Boolean,
            saveMe: {
                type: Boolean,
                required: false
            },
       },
        components: {
            Form,
            Field,
            MultiSelect,
        },
        data(): Data {

            const validationSchema = yup.object({
                customerContractId: yup.number().required(),
            })
                .typeError("Please select ${value} and ${type}");

            return {
                loading: true,
                validationSchema: validationSchema,
                supplyPointIds: [],
                customerSupplyPoints: [],
                selectedSupplyPoints: this.editContract.supplyPoints,
                activeSupplyPoints: [],
                sites: [],
                submitted: false,
                contract: this.editContract,
                utilityTypes: [],
                supplyPointFilters: {
                    siteId: { value: null, matchMode: FilterMatchMode.IN },
                    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    //meterRef: { value: null, matchMode: FilterMatchMode.CONTAINS },
                },
            };
        },
        created() {
            // fetch on init
        //    this.fetchData();
        },
        computed: {
        },
        watch: {
            editContract(newValue) {
                if (this.isActiveStep) {
                    console.log("contract changed");
                    this.contract = newValue;
                    this.fetchData();
                }
            },
            isActiveStep(newValue) {
                if (newValue) {
                    console.log("step loaded");
                    this.fetchData();
                }
            },
            saveMe(shouldSave) {
                //Forces save if in edit mode
                if (this.editMode && shouldSave) {
                    this.onSubmit(true);
                }
            },
        },
        methods: {
            async fetchData() {

                this.sites = await siteService.getSitesForCustomer(this.contract.customerId);

                this.utilityTypes = await customerContractService.getUtilityTypes();

                await this.getSupplyPointsForContract();
                await this.getSupplyPointsForCustomer();

                this.updateContractSupplyPointList();

                this.loading = false;
            },
            updateContractSupplyPointList() {
                console.log("Display list update.")
                this.selectedSupplyPoints = this.customerSupplyPoints.filter(sp => this.supplyPointIds.includes(sp.id.valueOf()) );
            },
            getSiteFromId(siteId: number) {
                let matchedSites = this.sites.filter(s => s.id == siteId);
                let site = matchedSites[0];
                return site;
            },
            async getSupplyPointsForContract() {

                this.selectedSupplyPoints = await customerContractService.getContractSupplyPoints(this.contract.id);
                this.supplyPointIds = this.selectedSupplyPoints ? this.selectedSupplyPoints.map(sp => sp.id.valueOf()) : [];
            },
            async getSupplyPointsForCustomer() {

                this.customerSupplyPoints = [];
                this.activeSupplyPoints = await supplyPointService.getContractedCustomerSupplyPoints({ customerId: this.contract.customerId, startDate: this.contract.startDate ?? new Date(), endDate: this.contract.endDate ?? new Date() });
                this.customerSupplyPoints = this.activeSupplyPoints.filter(asp => !asp.contracted || this.supplyPointIds.includes(asp.supplyPoint.id)).map(asp => asp.supplyPoint);

                //todo: Maybe this can be on the controller side but looked very complicated to do
                let productUtilityType = this.utilityTypes.find((ut: Lookup) => ut.id == this.editProduct?.utilityTypeId);
                this.customerSupplyPoints = this.customerSupplyPoints.filter(csp => this.utilityTypeMatchesSupplyPointType(productUtilityType, csp))

            },
            getSupplyPointIdentifier(supplyPoint: SupplyPoint) {
                switch (supplyPoint.SupplyPointType) {
                    case "Gas":
                        return (supplyPoint as GasSupplyPoint).meterPointReference;
                    case "HH":
                    case "NHH":
                    case "HH & NHH":
                        return (supplyPoint as ElectricitySupplyPoint).mpanCore;
                }
            },
            utilityTypeMatchesSupplyPointType(utilityType: Lookup | undefined, supplyPoint: SupplyPoint): boolean {                
                if (utilityType?.name == "Gas" && supplyPoint.SupplyPointType == "Gas") {
                    return true;
                }

                if (utilityType?.name == "Half Hourly" && supplyPoint.SupplyPointType == "HH") {
                    return true;
                }

                if (utilityType?.name == "Non Half Hourly" && supplyPoint.SupplyPointType == "NHH") {
                    return true;
                }

                if (utilityType?.name == "HH & NHH" && (supplyPoint.SupplyPointType == "HH" || supplyPoint.SupplyPointType == "NHH")) {
                    return true;
                }

                return false;
            },
            onRowSelect(node: any) {
                // update the list of ContractSupplyPoints
                console.log("Selected supply point", node);
                this.supplyPointIds.push(node.data.id);
            },
            onRowUnselect(node: any) {
                // update the list of ContractSupplyPoints
                console.log("Unselected supply point", node.data);
                this.supplyPointIds = this.supplyPointIds.filter(item => item !== node.data.id);
            },
            async onSubmit(values: any) {
                console.log("Submitting:", values);

                if (this.selectedSupplyPoints.length > 0) {
                    const editedContract = {
                        ...this.contract,
                        supplyPoints: this.selectedSupplyPoints,
                    }

                    console.log("Contract:", editedContract);

                    await customerContractService.updateCustomerContractSupplyPoints({ contractId: this.contract.id, supplyPointsIds: this.supplyPointIds });

                    this.$emit('submit:contract', editedContract);
                } else
                    alert("At least one supply point must be selected to continue.");
            },
        }
    });
