<template>
    <div class="p-fileupload p-fileupload-advanced p-component p-filelist">
        <div class="p-fileupload-content">
            <div v-for="(file, index) of files" :key="file.name + file.type + file.size" class="p-fileupload-file mb-2">
                
                <img v-if="showImagePreview" role="presentation" class="p-fileupload-file-thumbnail mr-2" :alt="file.name" :src="file.url" :width="previewWidth" />
                <div class="p-fileupload-file-details">
                    <div class="p-fileupload-file-name mb-1">
                        <i v-if="!showImagePreview" class="pi mr-2" :class="getIcon(file.type)" style="font-size: 2rem"></i>
                        <a v-if="!showImagePreview" :href="file.url">{{ file.name }}</a>
                        <Badge v-if="!hideBadges" :value="badgeValue" class="p-fileupload-file-badge ml-2" :severity="badgeSeverity" />
                    </div>
                    <!--<span class="p-fileupload-file-size">{{ formatSize(file.size) }}</span>-->
                </div>
                <div v-if="!disabled" class="p-fileupload-file-actions">
                    <Button icon="pi pi-times" @click="$emit('remove', index)" class="p-fileupload-file-remove p-button-text p-button-danger p-button-rounded"></Button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .p-filelist {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }
</style>

<script>
    import Badge from 'primevue/badge';
    import Button from 'primevue/button';

    export default {
        emits: ['remove'],
        props: {
            files: {
                type: Array,
                default: () => []
            },
            badgeSeverity: {
                type: String,
                default: 'warning'
            },
            badgeValue: {
                type: String,
                default: null
            },
            previewWidth: {
                type: Number,
                default: 50
            },
            showImagePreview: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            hideBadges: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                typesList: [
                    { value: "pi-file-pdf", type: ['application/pdf'] },
                    { value: "pi-file-word", type: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'] },
                    { value: "pi-file-excel", type: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] },
                ]
            };
        },
        methods: {
            formatSize(bytes) {
                if (bytes === 0) {
                    return '0 B';
                }
                let k = 1000,
                    dm = 3,
                    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                    i = Math.floor(Math.log(bytes) / Math.log(k));
                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            },
            getIcon(mimeType) {

                let icon = this.typesList.find((item) => {
                    let { type } = item;
                    return type.includes(mimeType);
                }) || {};

                return icon.value || "pi-file";
            }
        },
        components: {
            Button: Button,
            Badge: Badge
        },
      created() {
          console.log('hello');
          console.log(this.files); 
      }
    };
</script>

