
import {defineComponent, PropType} from 'vue';
import * as yup from "yup";
import {parse} from "papaparse";
import dayjs from "dayjs";
import {Form, Field} from 'vee-validate';
import Message from 'primevue/message';
import {formatDate} from '@/composables/UtilityFunctions';
import DocumentUpload from '@/components/Common/DocumentUpload.vue';
import {
  customerContractService,
  CustomerContract,
  VolumeRef,
  AddContractVolume,
  ContractVolume
} from '@/services/ContractService';
import {periodService, Period} from '@/services/PeriodService';

interface Data {
  loading: boolean,
  validationSchema: any,
  contract: CustomerContract,
  volumes: ContractVolume[],
  periods: Period[],
  csvUploads: any[],
  contents: VolumeRef[],
  dws: VolumeRef[],
  parsed: boolean,
  csvHeaders: string,
  csvContent: string,
  isError: boolean,
  parseErrorMsg: string,
}

export default defineComponent({

  props: {
    editContract: {
      type: Object as PropType<CustomerContract>,
      required: true
    },
    editMode: Boolean,
    units: {
      type: String,
      required: true
    },
    isActiveStep: Boolean,
    saveMe: {
      type: Boolean,
      required: false
    },
  },
  components: {
    Form,
    Field,
    Message,
    DocumentUpload,
  },
  data(): Data {

    const validationSchema = yup.object({
      customerContractId: yup.number().required(),
    })
        .typeError("Please select ${value} and ${type}");

    return {
      loading: true,
      validationSchema: validationSchema,
      contract: this.editContract,
      volumes: this.editContract.volumes,
      periods: [],
      csvUploads: [],
      contents: [],
      dws: [],
      parsed: false,
      csvHeaders: '',
      csvContent: '',
      isError: false,
      parseErrorMsg: '',
    };
  },
  created() {
    // fetch on init
    this.initData();
  },
  watch: {
    editContract(newValue) {
      if (this.isActiveStep) {
        this.contract = newValue;
        this.fetchData();
      }
    },
    saveMe(shouldSave) {
      //Forces save if in edit mode
      if (this.editMode && shouldSave) {
        this.onSubmit(null);
      }
    },
    isActiveStep(refresh) {
      if (refresh) {
        this.fetchData();
      }
    },
  },
  methods: {
    async initData() {
      this.periods = await periodService.getPeriods();
      this.parseVolumes();
    },
    async fetchData() {
      const startDate = dayjs(this.contract.startDate).startOf('month');
      const newDate = dayjs(this.contract.endDate);
          // .subtract(1, 'day').toDate(); //why does this happen...?
      var periods = this.periods.filter(p => (dayjs(p.startDate) >= dayjs(startDate)) && (dayjs(p.endDate) <= dayjs(newDate)))
          .map(({id, name, startDate, endDate}) => ({id, name, startDate, endDate}));
      //Add delivery window Id to each month entry and format dates
      
      var first = true;
      
      this.dws = periods.map(p => {
        var startPeriod = dayjs(p.startDate);
        var endPeriod = dayjs(p.endDate);

        //alter the first of to get the correct DW, quick & hacky.
        if(first) {
          startPeriod = dayjs(this.contract.startDate);  
          first = false;
        }
        
        var matchWindow = this.contract.schedule.find(dw => (startPeriod >= dayjs(dw.startDate)) && (startPeriod <= dayjs(dw.endDate)));
        return {
          deliveryWindowId: matchWindow ? matchWindow.id : 0,
          ...p,
          startDate: startPeriod.format("DD/MM/YYYY"),
          endDate: endPeriod.format("DD/MM/YYYY"),
          volume: 0,
        }
      });
      

      //Format array for CSV download
      this.buildCsvContent();

      this.loading = false;
    },
      buildCsvContent() {
        console.log(this.contents);
        console.log(this.dws);
          //merge the two arrays together to ensure all periods are returned as expected in the csv.
          if (this.contents && this.contents.length > 0) {
            const content = [...this.dws];
            content.forEach(c => {
              const matchingObject = this.contents.find(conObj => conObj.id === c.id);
              if(matchingObject) {
                c.volume = matchingObject.volume;
              }
            })

              this.csvContent = content.map(d =>
                  JSON.stringify(Object.values(d))
              )
                .join('\n')
                .replace(/(^\[)|(\]$)/mg, '');
              return;
          }


          this.csvContent = this.dws ? this.dws.map(d =>
              JSON.stringify(Object.values(d))
          )
              .join('\n')
              .replace(/(^\[)|(\]$)/mg, '') : "";
      },
      buildCsvHeaders() {
          if (this.contents && this.contents.length > 0) {
              this.csvHeaders = this.contents ? JSON.stringify(Object.keys(this.contents[0])).replace(/(^\[)|(\]$)/mg, '') : "";
              return;
          }
          this.csvHeaders = this.dws ? JSON.stringify(Object.keys(this.dws[0])).replace(/(^\[)|(\]$)/mg, '') : "";
          this.csvHeaders = this.csvHeaders + ',volumeId';
      },
    async onSubmit(event: any) {
      if (this.parsed) {

        const addedContractVolumes: AddContractVolume[] = this.contents.map(ct => ({
          customerContractId: this.contract.id,
          deliveryWindowId: ct.deliveryWindowId,
          periodId: ct.id,
          readVolume: ct.volume,
        }));
        const updatedContractVolumes: any[] = this.contents.map(ct => ({
          id: ct.volumeId,
          customerContractId: this.contract.id,
          deliveryWindowId: ct.deliveryWindowId,
          periodId: ct.id,
          readVolume: ct.volume,
        }));

        const alreadyVolumes: boolean = this.contract.volumes.length > 0;
        const editedContract = {
          ...this.contract,
          volumes: alreadyVolumes ? updatedContractVolumes : addedContractVolumes,
        }


        if (alreadyVolumes)
          await customerContractService.updateContractVolumes(updatedContractVolumes);
        else
          editedContract.volumes = await customerContractService.addContractVolumes(addedContractVolumes);

          this.$emit('submit:contract', editedContract, event !== null);
      } else {
        console.log("CSV file is not in correct format.");
      }
    },
    formatDate(dateToFormat: string) {
      return formatDate(dateToFormat);
    },
      onDownloadCsv() {
      this.buildCsvContent();

      var csvContent = "data:text/csv;charset=utf-8,";


      //Add header row here if required
          this.buildCsvHeaders();
      this.csvHeaders.replace("volume", this.units);

      if (this.editMode) {

        csvContent += this.csvHeaders + '\n';
        csvContent += this.csvContent;

      } else {
        csvContent += this.csvHeaders + '\n';
        csvContent += this.csvContent;
      }


      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `contract-${this.contract.id}-volumes.csv`);
      document.body.appendChild(link); // Required for FF
      link.click();
      document.body.removeChild(link);
    },


    onSelectUpload(files: File[]) {
      this.csvUploads = files.length > 0 ? files : [];
      this.parseFile();

    },

    parseFile() {
      parse(this.csvUploads[0], {
        download: true,
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: (header: string) => header.replace(this.units, 'volume'),
        complete: (result) => this.parseResults(result.data),
      });
    },

    parseResults(results: any[]) {
      if (this.editMode && (results.length != this.dws.length && results.length != this.contents.length)) {
        this.isError = true;
        this.parseErrorMsg = "The imported file does not have same number of rows to match the existing contract volumes. Please download  and re-submit the csv file.";
      } else if (!this.editMode && results.length != this.dws.length && results.length != this.contents.length) {
        this.isError = true;
        this.parseErrorMsg = "The imported file does not have sufficient rows to match the period of the contract. Please download the template and re-submit the csv file.";
      } else if (results.length == 0) {
        this.isError = true;
        this.parseErrorMsg = "No entries could be found. The imported file does not match the required format. Please download the template and re-submit the csv file.";
      } else {
        //validation that volume field contains a number

        this.contents = results.map(row => ({
          ...row,
          volume: this.validateNumberInput(String(row.volume)),
        }));
        this.parsed = true;
        this.isError = false;
        
        if(this.parsed && !this.isError) {
          //trigger onSubmit?
          this.onSubmit(null);
        }
      }

    },

    parseVolumes() {
      this.contents = this.volumes.map(v => {
        var matchedPeriod = this.periods.find(p => p.id == v.periodId);
        return {
          deliveryWindowId: v.deliveryWindowId,
          id: v.periodId,
          name: matchedPeriod ? matchedPeriod.name : "Period not found",
          startDate: dayjs(matchedPeriod?.startDate).format("DD/MM/YYYY"),
          endDate: dayjs(matchedPeriod?.endDate).format("DD/MM/YYYY"),
          volume: v.readVolume,
          volumeId: v.id
        }
      });

      this.parsed = this.contents.length > 0 ? true : false;
    },
    validateNumberInput(value: String) {
      let numberPattern = /-?\d*\.?\d+/g;
      let numbers = value.match(numberPattern);

      if (numbers === null) {
        return 0;
      }

      return parseFloat(numbers.join(''));
    },
    onCellEditComplete(event: any) {
      let {data, newValue, field} = event;

      switch (field) {
        case 'volume':
          data[field] = newValue;
          this.onSubmit(null);
          break;

        default:
          if (newValue.trim().length > 0) data[field] = newValue;
          else event.preventDefault();
          break;
      }
    },
  }
});
