
   
    import { defineComponent } from 'vue';
    import { Trade, tradeService } from '@/services/TradeService';
    import TradeList from '@/components/Trading/PreviousTrading/TradeList.vue';
    import AddBulkItem from '@/components/Trading/PreviousTrading/AddBulkItem.vue';
    import { Lookup } from '@/services/Types';

    interface Data {
        loading: boolean,
        trades: Trade[],
        commodityTypes: Lookup[],
        importing: boolean,
        addBulkImportDialog: boolean,
        notParsed: boolean,
    }

    export default defineComponent({
        props:  {
            commodity: {
                type: String,
                required: true
            },
        },
        components: {
            TradeList,
            AddBulkItem,
        },
        data():Data {
            return {
                loading: true,
                trades: [],
                commodityTypes: [],
                importing: false,
                addBulkImportDialog: false,
                notParsed: true,
            }
        },      
        created() {
            this.fetchData();
        },
        computed: {
            commodityTypeId() {

                const type = this.commodityTypes.find(ct => ct.name.toLowerCase().replaceAll(' ', '') === this.commodity);
                return type?.id ?? 0; // should never hit this...
            }
        },
        methods: {
            async fetchData() {

                this.commodityTypes = await tradeService.getCommodities();
                this.trades = await tradeService.getTradesForCommodity(this.commodityTypeId);

                this.loading = false;
            },
            async onTradeChange() {

                // refresh trade list - or can we just get updated trade and replace? impact on paging/ filtering?
                this.trades = await tradeService.getTradesForCommodity(this.commodityTypeId);
            },
            addNewBulkImport() {
                this.addBulkImportDialog = true;
            },
            async onAddBulkImport(addImportEvent: any) {
                console.log("Importing trades...");
                this.importing = true;

                await tradeService.addBulkTradeImport(addImportEvent);

                //this.$emit('add:import', addImportEvent);

                console.log("imported successfully!");

                this.importing = false;
                this.addBulkImportDialog = false;

                //refresh tradelist
                this.loading = true;
                this.fetchData();
            },
            async onUpdateBulkImport() {

                //await consumptionService.updateImport(updateImportEvent)

                //this.$emit('update:import', updateImportEvent);

                console.log("parsed successfully!");

                this.notParsed = false;
            },
    }
    });
