
   
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: {
            customerId: {
                type: Number,
                required: true
            },
        },
        data() {
            return {
                items: [
                    { label: 'Details', to: { name: 'editCustomer', params: { customerId: this.customerId }}},
                    { label: 'Sites', to: { name: 'sites', params: { customerId: this.customerId }}},
                    { label: 'Supply Points', to: { name: 'supplyPoints', params: { customerId: this.customerId }}},
                    { label: 'Contracts', to: { name: 'customerContracts', params: { customerId: this.customerId }}}
                ]
            }
        },      
        created() {
        }
    });
