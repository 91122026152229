
    import { defineComponent, PropType } from 'vue';
    import * as yup from "yup";
    import { Form, Field, ErrorMessage } from 'vee-validate';

    import { purchaseGroupService, PurchaseGroup, PurchaseGroupContract } from '@/services/PurchaseGroupService';
    import { customerContractService, CustomerContract } from '@/services/ContractService';
    import { customerService, Customer } from '@/services/CustomerService';

    interface Data {
        loading: boolean,
        validationSchema: any,
        purchaseGroup: null | PurchaseGroup,
        contract: PurchaseGroupContract,
        assignedContracts: null | PurchaseGroupContract[],
        customerContracts: null | CustomerContract[],
        customers: null | Customer[],
        //customerId: number | null,
    }

    export default defineComponent({

        props: {
            editContract: {
                type: Object as PropType<PurchaseGroupContract>,
                required: true
            },
        },
        components: {
            Form,
            Field,
            ErrorMessage,
        },
        data(): Data {

            const validationSchema = yup.object({
                contractId: yup.number().required(),
                customerId: yup.number().required().label("Customer"),
                customerContractId: yup.number().required().label("Contract"),
            })
                .typeError("Please select ${value} and ${type}");

            return {
                loading: true,
                validationSchema: validationSchema,
                contract: this.editContract,
                purchaseGroup: null,
                assignedContracts: null,
                customerContracts: null,
                customers: null,
                //customerId: null,
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        watch: {          
        },
        methods: {
            async fetchData() {
                this.customers = await customerService.getCustomers();
                this.assignedContracts = await purchaseGroupService.getPurchaseGroupAllContracts();
                this.purchaseGroup = await purchaseGroupService.getPurchaseGroupById(this.editContract.purchaseGroupId);
                await this.fetchCustomerContracts(this.contract?.customerId.toString());

                this.loading = false;

                console.log("customers data loaded");
            },
            async fetchCustomerContracts(customerId: string) {
                if (this.contract.customerId) {
                    this.customerContracts = await customerContractService.getContractsForCustomer(customerId);
                    this.customerContracts = this.customerContracts
                        .filter((cc) => (this.matchCommodityToUtilityType(this.purchaseGroup?.commodityTypeId, cc.utilityTypeId)))
                        .map((cc) => ({
                            ...cc,
                            inuse: //test whether contract is already in use and mark as disabled
                                this.assignedContracts
                                ? this.contract.customerContractId.valueOf() != cc.id
                                && this.assignedContracts.some(ac => ac.customerContractId.valueOf() == cc.id)
                                : false
                        }
                    ));
                }
            },
            async onCustomerSelect(event: any) {
                await this.fetchCustomerContracts(event.value);
            },
            onSubmit() {
                
                const editedContract = {
                    ...this.contract,
                }

                console.log("Pre-submitting:", editedContract);

                this.$emit('submit:purchaseGroupContract', editedContract);

            },
            matchCommodityToUtilityType(commodityId: number | undefined, utilityTypeId: number | undefined) {

                if (!(commodityId && utilityTypeId)) return false;
                //ToDo: improve rules around matching allowed utility types for given commodity type
                //Currently hard-coded IDs so that all electric commodity types (id 2 & 3) allow HH and NHH utilities (id 1 & 2 & 12)
                const typematches = [
                    { cid: 1, uids: [3] },
                    { cid: 2, uids: [1, 2, 12] },
                    { cid: 3, uids: [1, 2, 12] }
                ];

                let utilities = typematches.find(c => c.cid == commodityId);

                return utilities ? utilities.uids.includes(utilityTypeId) : false;
            },
        }
    });
