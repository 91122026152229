
    import { defineComponent, PropType } from 'vue';
   
    import { ElectricDataStore } from '@/services/TradeService';
    import { SupplyPoint } from '@/services/SupplyPointService';
    import { formatDate } from '@/composables/UtilityFunctions';
    //import { FilterMatchMode } from 'primevue/api';    
    import MultiSelect from 'primevue/multiselect';       
  
    interface Data {
        loading: boolean,
        supplyPoints: SupplyPoint[],
        electricDataStoreListFilters: {},        
    }

    export default defineComponent({
        props: {
            electricDataStore: {
                type: Array as PropType<Array<ElectricDataStore>>,
                required: true
            },
            commodityTypeId: {
                type: Number,
                required: true
            },
            customerId: {
                type: Number,
                required: false
            }
        },
        components: {
           // eslint-disable-next-line 
            MultiSelect,
        },
        data(): Data {
            return {
                loading: true,
                supplyPoints: [],
                electricDataStoreListFilters: {
                    // 'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    // 'purchaseGroups': { value: null, matchMode: CustomFilterMatchMode.ANY_ID },
                    //'periods': { value: null, matchMode: FilterMatchMode.IN },
                    // 'tradeDateTime': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                    // 'supplierOfferPrice': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    // 'totalTradeVolume': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    // 'user': { value: null, matchMode: FilterMatchMode.IN },  
                    // 'status': { value: null, matchMode: FilterMatchMode.IN }, 
                }
            };
        },
        created() {
            this.fetchData();
        },
        watch: {         
        },
        methods: {
            async fetchData() {     
                //this.supplyPoints = supplyPointService.getSupplyPointsForCustomer(this.customerId);
                console.log(this.electricDataStore);                           
                this.loading = false;            
            },
            async onRowSelect(event: any) {
                console.log(event);
            },            
            formatDate(dateToFormat: string) {
                return formatDate(dateToFormat, 'DD/MM/YYYY HH:mm');
            },
            formatCurrency(value: number) {
                return value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });
            },
        },
        computed: {
            dataWithInfo() {
                const dataWithInfo = this.electricDataStore ? this.electricDataStore.map(t => ({                    
                    ...t,                     
                    //supplyPointName: this.supplyPoints.find(sp => sp.id == t.supplyPointId)?.name,
                })) : [];

                return dataWithInfo;
            },
        }
    });
