
    import { defineComponent } from 'vue';
    import { contactService, Contact } from '@/services/ContactService';

    import ContactItem from '@/components/Contacts/ContactItem.vue';

    interface Data {
        loading: boolean,
        contact: Contact | null,
        isSaving: boolean,
    }

    export default defineComponent({

        props: {
            contactId: {
                type: Number,
                required: true
            },
        },
        components: {
            ContactItem,
        },
        data(): Data {

            return {
                loading: true,
                contact: null,
                isSaving: false,
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                this.contact = await contactService.getContactById(this.contactId)

                this.loading = false;
                
            },
            async onSubmit(values: any) {

                this.isSaving = true;

                await contactService.updateContact(values)
                    
                this.$emit('update:contact', values);

                this.isSaving = false;
                console.log("save successful!");
                    
            },
        }
    });
