
    import { defineComponent } from 'vue';
    import { Contact } from '@/services/ContactService';
    import { customerService } from '@/services/CustomerService';
    import { siteService } from '@/services/SiteService';

    interface Data {
        loading: boolean,
        siteContacts: Contact[],
        customerContacts: Contact[],
    }

    export default defineComponent({
        props: {
            customerId: {
                type: Number,
                required: true
            },
            siteId: {
                type: Number,
                required: true
            }
        },
        components: {
        },
        data(): Data {

            return {
                loading: true,
                siteContacts: [],
                customerContacts: [],
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        computed: {
        },
        methods: {
            async fetchData() {

                this.customerContacts = await customerService.getCustomerContactsById(this.customerId);

                this.siteContacts = await siteService.getSiteContactsById(this.siteId);

                this.loading = false;
            },
            onRowSelect() {

                const selectedContactIds = this.siteContacts.map(c => c.id);

                this.$emit('update:siteContacts', selectedContactIds);
            },
        //    onRowUnselect() {

        //        const selectedContactIds = this.siteContacts.map(c => c.id);

        //        this.$emit('update:siteContacts', selectedContactIds);
        //    }
        }
    });
