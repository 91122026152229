
    import { defineComponent, PropType } from 'vue';
    import { supplyPointService, GasSupplyPoint, MeterOperator } from '@/services/SupplyPointService';
    import * as yup from "yup";
    import { Form, Field, ErrorMessage } from 'vee-validate';

    interface Data {
        loading: boolean,
        editMode: boolean,
        validationSchema: any,
        supplyPoint: GasSupplyPoint
        meterOperators: MeterOperator[],
    }

    export default defineComponent({

        props: {
            editSupplyPoint: {
                type: Object as PropType<GasSupplyPoint>,
                required: true
            },
        },
        components: {
            Form,
            Field,
            ErrorMessage
        },
        data(): Data {

            const typeErrorMessage = "Please enter a value";

            const validationSchema = yup.object({
                siteId: yup.number().required(),
                name: yup.string().nullable().label('Supply Point Name'),
                annualConsumption: yup.number().typeError(typeErrorMessage).nullable().label('Annual Consumption'),
                budgetCost: yup.number().typeError(typeErrorMessage).nullable().label('Budget Cost'),
                //meterOperatorRenewalDate: yup.date().required().label('Meter Operator Renewal Date'),
                //meterOperatorId: yup.number().nullable(),
                amrEnabled: yup.boolean(),
                fivePercentVATEnabled: yup.boolean(),
                accountNumber: yup.string().nullable().label("Supplier Account Number"),
                meterPointReference: yup.string().nullable().label("Meter Point Reference"),
                meterSerialNumber: yup.string().nullable().label("Meter Serial Number"),
                gasLive: yup.boolean(),
                meterName: yup.string().required().label('Meter Name'),
                meterAssetManager: yup.string().nullable(),
            });

            return {
                loading: true,
                editMode: false,
                validationSchema: validationSchema,
                supplyPoint: this.editSupplyPoint,
                meterOperators: [],
            };
        },
        created() {

            this.editMode = this.supplyPoint.id > 0;

            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                this.meterOperators = await supplyPointService.getMeterOperators();

                this.loading = false;

            },
            onSubmit(values: any) {
                console.log(values);


                if (this.editMode) {

                    this.$emit('update:supplyPoint', this.supplyPoint);
                }
                else {

                    this.$emit('add:supplyPoint', this.supplyPoint);
                }
            },
        },
        
    });
