
    import { defineComponent, PropType } from 'vue';
    import { ElectricitySupplyPoint, GasSupplyPoint, SupplyPoint } from '@/services/SupplyPointService';
    import { siteService, Site } from '@/services/SiteService';

    interface Data {
        loading: boolean,
        sites: Site[],
    }

    export default defineComponent({
        props: {
            customerId: {
                type: Number,
                required: true
            },
            supplyPoints: {
                type: Array as PropType<Array<SupplyPoint>>,
                required: true
            }
        },
        data(): Data {

            return {
                loading: true,
                sites: [],
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        computed: {
            supplyPointsWithSiteInfo():SupplyPoint[] | null {

                let supplyPointsWithSiteInfo = this.supplyPoints.map(sp => ({...sp, siteName: this.sites.find(cus => cus.id === sp.siteId)?.name}));
                return supplyPointsWithSiteInfo;
            }
        },
        methods: {
            async fetchData() {

                this.sites = await siteService.getSitesForCustomer(this.customerId);
                //await this.getSupplyPointsForCustomer();

                this.loading = false;


            },
            onRowSelect(event:any) {
                // get the supply point type to determine which route to redirect to
                console.log(event.data);

                this.$router.push({ name: 'editSupplyPoint', params: { supplyPointId: event.data.id } })
            },
            getSupplyPointIdentifier(supplyPoint: SupplyPoint) {
                switch (supplyPoint.SupplyPointType) {
                    case "Gas":
                        return (supplyPoint as GasSupplyPoint).meterPointReference;
                    case "HH":
                    case "NHH":
                    case "HH & NHH":
                        return (supplyPoint as ElectricitySupplyPoint).mpanCore;
                }
            }
        }
    });
