
      import { Auth } from 'aws-amplify';
      import { defineComponent } from 'vue';
      export default defineComponent({
          data() {
              return {
                  user: {},
                  items: [
                      {
                          label: 'Customers',
                          icon: require('@/assets/images/icons/Customers-white.svg'),
                          to: '/customers'
                      },
                      {
                          label: 'Contacts',
                          icon: require('@/assets/images/icons/Contracts.svg'),
                          to: '/contacts'
                      },
                      {
                          label: 'Purchase Groups',
                          icon: require('@/assets/images/icons/PG-white.svg'),
                          to: '/purchase-groups'
                      },
                      {
                          label: 'Trading',
                          icon: require('@/assets/images/icons/Trading-white.svg'),
                          to: '/trading'
                      },
                      {
                          label: 'Reports',
                          icon: require('@/assets/images/icons/Reporting-white.svg'),
                          to: '/reports'
                      },
                      {
                          label: 'Settings',
                          icon: require('@/assets/images/icons/Settings-white.svg'),
                          to: '/settings'
                      },
                  ]
              }
          },
          beforeCreate() {
              Auth.currentAuthenticatedUser()
                  .then(user => {
                      this.user = user;
                  })
                  .catch(() => console.log('not signed in...'))
          }
      });
