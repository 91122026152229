import { useCRMApi } from '@/composables/CRMApi'
import { Address, Document } from '@/services/Types'
import { getFormData } from '@/composables/UtilityFunctions'
import { Contact } from '@/services/ContactService'
import { SupplyPoint } from './SupplyPointService'

// todo: move these to a separate file

export interface Site {
    id: number,
    name: string,
    customerId: number,
    costCentre?: string | null,
    customName?: string | null,
    customRef?: string | null,
    address?: Address | null,    
}

export interface SiteSupplyPoints {
    id: number,
    name: string,
    customerId: number,
    costCentre?: string | null,
    customName?: string | null,
    customRef?: string | null,
    address?: Address | null,    
    SupplyPoints: SupplyPoint[]
}

export interface YearlyAgreementInfo {
    agreements: YearlyAgreement[],
}

export interface YearlyAgreement {
    id: Number,
    siteId: Number,
    year: number,
    reduction: Number,
    document?: Document | null,
}

export default class SiteService {

    async getSites() {

        const data = await useCRMApi(`/crm/sites`) as Site[];
        return data ?? [];
    }

    async getSitesForCustomer(customerId: Number) {

        const data = await useCRMApi(`/crm/sites/customer/${customerId}`) as Site[];
        return data ?? [];
    }

    async getSiteSupplyPointsForCustomer(customerId: Number) {
        const data = await useCRMApi(`/crm/sites/customer/supplypoints/${customerId}`) as SiteSupplyPoints[];
        return data ?? [];
    }

    async getSite(siteId: Number) {

        const data = await useCRMApi(`/crm/sites/${siteId}`) as Site;
        return data ?? null;
    }

    async updateSite(updateSite: any | null) {

        const formData = getFormData(updateSite);

        const newOptions = {
            method: 'PUT',
            body: formData
        };

        const data = await useCRMApi(`/crm/sites`, newOptions);
        return data ?? null;
    }

    async addSite(addSite: any | null) {

        const formData = getFormData(addSite);

        const newOptions = {
            method: 'POST',
            body: formData
        };

        const data = await useCRMApi(`/crm/sites`, newOptions);
        return data ?? null;
    }

    async getClimateChangeAgreementInfoForSite(siteId: Number) {
        const data = await useCRMApi(`/crm/sites/${siteId}/climatechangeagreementinfo`) as YearlyAgreementInfo;
        return data ?? null;
    }

    async getClimateChangeAgreementById(id: Number) {
        const data = await useCRMApi(`/crm/sites/climatechangeagreement/${id}`) as YearlyAgreement;
        return data ?? null;
    }

    async updateClimateChangeAgreement(updateCCA: any | null) {

        const formData = getFormData(updateCCA);

        const newOptions = {
            method: 'PUT',
            body: formData
        };

        const data = await useCRMApi(`/crm/sites/climatechangeagreement`, newOptions);
        return data ?? null;
    }

    async addClimateChangeAgreement(addCCA: any | null) {

        const formData = getFormData(addCCA);

        const newOptions = {
            method: 'POST',
            body: formData
        };

        const data = await useCRMApi(`/crm/sites/climatechangeagreement`, newOptions) as YearlyAgreement;
        return data ?? null;
    }

    async deleteClimateChangeAgreement(id: Number) {
        const newOptions = {
            method: 'DELETE'
        };

        const data = await useCRMApi(`/crm/sites/climatechangeagreement/${id}`, newOptions);
        return data ?? null;
    }

    async getEnergyIntensiveIndustryInfoForSite(siteId: Number) {
        const data = await useCRMApi(`/crm/sites/${siteId}/energyintensiveindustryinfo`) as YearlyAgreementInfo;
        return data ?? null;
    }

    async getEnergyIntensiveIndustryById(id: Number) {
        const data = await useCRMApi(`/crm/sites/energyintensiveindustry/${id}`) as YearlyAgreement;
        return data ?? null;
    }

    async updateEnergyIntensiveIndustry(updateEII: any | null) {

        const formData = getFormData(updateEII);

        const newOptions = {
            method: 'PUT',
            body: formData
        };

        const data = await useCRMApi(`/crm/sites/energyintensiveindustry`, newOptions);
        return data ?? null;
    }

    async addEnergyIntensiveIndustry(addEII: any | null) {

        const formData = getFormData(addEII);

        const newOptions = {
            method: 'POST',
            body: formData
        };

        const data = await useCRMApi(`/crm/sites/energyintensiveindustry`, newOptions) as YearlyAgreement;
        return data ?? null;
    }

    async deleteEnergyIntensiveIndustry(id: Number) {
        const newOptions = {
            method: 'DELETE'
        };

        const data = await useCRMApi(`/crm/sites/energyintensiveindustry/${id}`, newOptions);
        return data ?? null;
    }

    async getSiteContactsById(id: Number) {

        const data = await useCRMApi(`/crm/sites/${id}/contacts`) as Contact[];
        return data ?? [];
    }
}


export const siteService = new SiteService();


