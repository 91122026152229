import { useCRMApi } from '@/composables/CRMApi'

interface IUser {
    userId: string,
    userName: string,
    email: string
    lastName?: string,
    firstName?: string,
    hidden: boolean | undefined,

    // get-only:
    readonly fullName: string;
}

export class User implements IUser {

    public firstName: string | undefined;
    public lastName: string | undefined;
    public email: string;
    public userId: string;
    public userName: string;
    public hidden: boolean | undefined;

    constructor(userId: string, userName: string, firstName: string | undefined, lastName: string | undefined, email: string, hidden: boolean | undefined) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.userId = userId;
        this.userName = userName;
        this.hidden = hidden;
    }

    // read-only property with getter function (this is not the same thing as a �function-property�)
    get fullName(): string {
        return this.firstName + " " + this.lastName;
    }
}

export default class UserService {
   
    async getUsers(): Promise<User[]> {

        const res = await useCRMApi(`/crm/users/`);
        const data = res.map((u: IUser) => new User(u.userId, u.userName, u.firstName, u.lastName, u.email, u.hidden));

        return data ?? [];
    }

    async getUser(userId: string): Promise<User> {

        const res = await useCRMApi(`/crm/users/${userId}`) as User;
        const data = new User(res.userId, res.userName, res.firstName, res.lastName, res.email, res.hidden)

        return data ?? null;
    }   
}

export const userService = new UserService();


