
    import { defineComponent, PropType } from 'vue';
    import dayjs from 'dayjs';
    import { FilterMatchMode, FilterOperator } from 'primevue/api';
    import { formatDate } from '@/composables/UtilityFunctions';
    import { GasSupplyPoint } from '@/services/SupplyPointService';
    import { consumptionService, ConsumptionReadLite } from '@/services/ConsumptionService';

    interface Data {
        loading: boolean,
        supplyPointReadings: ConsumptionReadLite[],
        filters: any,
    }

    export default defineComponent({
        props: {
            supplyPoint: {
                type: Object as PropType<GasSupplyPoint>,
                required: true
            },
            reportPeriod: {
                type: String,
                required: false,
                default: "Monthly",
            },
        },
        data(): Data {

            return {
                loading: true,
                supplyPointReadings: [],
                filters: {
                    'readTime': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                },
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        computed: {
            readingsByMonth() {
                var aggByMonth: ConsumptionReadLite[] = [];

                aggByMonth = this.supplyPointReadings.reduce((acc, spr) => {
                    const startOfMonth = dayjs(spr.readTime).startOf('month').toDate();
                    const existsMonth = acc.findIndex(item => item.readTime.getTime() == startOfMonth.getTime());

                    if (existsMonth !== -1) {
                        acc[existsMonth].usage += spr.usage; // Accumulate volume usage
                    } else {
                        acc.push({ ...spr, readTime: startOfMonth }); // Add new month entry
                    }

                    return acc;
                }, [...aggByMonth]);

                return aggByMonth;
            }
        },
        methods: {
            async fetchData() {

                this.supplyPointReadings = await consumptionService.getGasConsumptionByMeterRef(this.supplyPoint.meterPointReference);
                this.supplyPointReadings = this.supplyPointReadings.map(spr => ({ ...spr, readTime: new Date(spr.readTime) }));
                this.loading = false;


            },
            formatDate(dateToFormat: string, formatString: string) {
                return formatDate(dateToFormat, formatString);
            },
        }
    });
