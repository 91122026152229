import { useTradingApi } from '@/composables/TradingApi'
import { Lookup } from './Types';

export interface Trigger {
    id: number,
    name: string,
    customer: string,
    commodityTypeId: number,
    Value: number,
    TriggerTypeId: number,
}



export default class TriggerService {

    async getTriggers() {

        const data = await useTradingApi(`/trading/triggers`) as Trigger[];
        return data ?? [];
    }

    async getCommodityTypes() {

        const data = await useTradingApi(`/trading/commodities`) as Lookup[];
        return data ?? [];
    }
    async getTriggerTypes() {

        const data = await useTradingApi(`/trading/triggertypes`) as Lookup[];
        return data ?? [];
    }

    async getTriggerById(id: Number) {

        const data = await useTradingApi(`/trading/triggers/${id}`) as Trigger;
        return data ?? null;
    }

    async updateTrigger(trigger: any | null) {

        const newOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(trigger)
        };

        const data = await useTradingApi(`/trading/triggers/`, newOptions);
        return data ?? null;
    }

    async deleteTrigger(id: Number) {
        const newOptions = {
            method: 'DELETE'
        };

        const data = await useTradingApi(`/trading/triggers/${id}`, newOptions);
        return data ?? null;
    }
    //async getProductById(id: Number) {

    //    const data = await useCRMApi(`/crm/products/${id}`) as Product;
    //    return data ?? null;
    //}

    //async getProductsBySupplierId(id: Number) {

    //    const data = await useCRMApi(`/crm/products/supplier/${id}`) as Product[];
    //    return data ?? null;
    //}

    //async getProductChargeTypes() {

    //    const data = await useCRMApi(`/crm/productchargetypes`) as Lookup[];
    //    return data ?? [];
    //}

    //async getSuppliers() {

    //    const data = await useCRMApi('/crm/suppliers') as Lookup[];
    //    return data ?? [];
    //}

    //async getProfileClasses() {

    //    const data = await useCRMApi(`/crm/profileclasses`) as Lookup[];
    //    return data ?? [];
    //}

    //async getContractTypes() {

    //    const data = await useCRMApi(`/crm/contracttypes`) as Lookup[];
    //    return data ?? [];
    //}

    //async getUtilityTypes() {

    //    const data = await useCRMApi(`/crm/utilitytypes`) as Lookup[];
    //    return data ?? [];
    //}

    async addTrigger(trigger: any | null) {

        const newOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(trigger)
        };

        const data = await useTradingApi(`/trading/triggers/`, newOptions);
        return data ?? null;
    }

    //async updateProduct(product: any | null) {

    //    const newOptions = {
    //        method: 'PUT',
    //        headers: { 'Content-Type': 'application/json' },
    //        body: JSON.stringify(product)
    //    };

    //    const data = await useCRMApi(`/crm/products/`, newOptions);
    //    return data ?? null;
    //}

}


export const triggerService = new TriggerService();