
    import { defineComponent, PropType } from 'vue';
    import * as yup from "yup";
    import { Form, Field, ErrorMessage } from 'vee-validate';

    import { customerContractService, CustomerContract } from '@/services/ContractService';
    import { productService, Product } from '@/services/ProductService';
    import { Lookup } from '@/services/Types';

    interface Data {
        loading: boolean,
        validationSchema: any,
        contract: CustomerContract,
        supplierId: number | undefined,
        suppliers: null | Lookup[],
        products: null | Product[],
    }

    export default defineComponent({

        props: {
            editContract: {
                type: Object as PropType<CustomerContract>,
                required: true
            },
            editMode: Boolean,
        },
        components: {
            Form,
            Field,
            ErrorMessage,
        },
        data(): Data {

            const validationSchema = yup.object({
                productId: yup.number().required().label("Product"),
                supplierId: yup.number().required().label("Supplier"),
            })
                .typeError("Please select ${value} and ${type}");

            return {
                loading: true,
                validationSchema: validationSchema,
                contract: this.editContract,
                supplierId: this.editContract.supplierId,
                suppliers: null,
                products: null,
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        watch: {          
            async supplierId(newValue: number | undefined) {
                console.log("getting products for supplier id ", newValue);

                if (newValue) {
                    this.contract.productId = undefined;
                    this.products = await productService.getProductsBySupplierId(newValue);
                }
            },
        },
        methods: {
            async fetchData() {

                this.suppliers = await customerContractService.getSuppliers();
                this.products = await productService.getProducts();

                this.loading = false;

                console.log("contract data loaded");
            },
            onSubmit(values: any) {
                console.log("Submitting:", values);
                var utilityTypeId = this.products?.find(p => p.id == this.contract.productId)?.utilityTypeId;
                const editedContract = {
                    ...this.contract,
                    supplierId: this.supplierId,
                    utilityTypeId: utilityTypeId ?? 0,
                }

                console.log("Contract:", editedContract);

                this.$emit('submit:contract', editedContract);

            },
            async updateProducts() {
                if (this.contract.supplierId) {
                    this.contract.productId = undefined;
                    this.products = await productService.getProductsBySupplierId(this.contract.supplierId);
                }
            },
            onProductChange(event: any) {
                console.log("Product selected:", event);
                this.$emit('productSelect', event.value);
            }
        }
    });
