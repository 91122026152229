import { useCRMApi } from '@/composables/CRMApi'
import { Address, Lookup } from '@/services/Types'
import { Contact } from '@/services/ContactService'
import { getFormData } from '@/composables/UtilityFunctions'

// todo: move these to a separate file
export interface Customer {
    id: number,
    name: string,
    primaryContactId?: number,
    tradingContactId?: number,
    customerStatusId?: number,
    companyTypeId?: number,
    partnerId?: number,
    sectorId?: number,
    sicCodeId?: number,
    financialYearStartDate?: Date,
    ucid?: number,
    notes?: string,
    companyRegistrationNumber?: number,
    registeredOfficeAddress: Address,
    correspondenceAddress: Address,
    logo?: Document,
    authority?: Document,
    benchmarkRate?: number
}

export default class CustomerService {

    async getCustomers() {

        const data = await useCRMApi('/crm/customers') as Customer[];
        return data ?? [];
    }

    async getCustomerById(id: Number) {

        const data = await useCRMApi(`/crm/customers/${id}`) as Customer;
        return data ?? null;
    }

    async getCustomerContactsById(id: Number) {

        const data = await useCRMApi(`/crm/customers/${id}/contacts`) as Contact[];
        return data ?? [];
    }

    async getCustomerStatuses() {

        const data = await useCRMApi('/crm/customerstatus') as Lookup[];
        return data ?? [];
    }

    async getCompanyTypes() {

        const data = await useCRMApi('/crm/companytypes') as Lookup[];
        return data ?? [];
    }

    async getPartners() {

        const data = await useCRMApi('/crm/partners') as Lookup[];
        return data ?? [];
    }

    async getSICCodes() {

        const data = await useCRMApi('/crm/siccodes') as Lookup[];
        return data ?? [];
    }

    async getSectors() {

        const data = await useCRMApi('/crm/sectors') as Lookup[];
        return data ?? [];
    }

    async updateCustomer(customer: any | null) {

        const formData = getFormData(customer);

        const newOptions = {
            method: 'PUT',
            body: formData
        };

        const data = await useCRMApi(`/crm/customers`, newOptions);
        return data ?? null;
    }

    async addCustomer(customer: any | null) {

        const formData = getFormData(customer);

        const newOptions = {
            method: 'POST',
            body: formData
        };

        const data = await useCRMApi(`/crm/customers`, newOptions);
        return data ?? null;
    }
}


export const customerService = new CustomerService();
