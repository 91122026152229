
    import { defineComponent } from 'vue';
    import { formatDate } from '@/composables/UtilityFunctions';
    import { customerContractService, CustomerContract } from '@/services/ContractService';
    import ContractProcess from '@/components/Contracts/ContractProcess.vue';

    interface Data {
        loading: boolean,
        contract: any,
        contracts: CustomerContract[] | null,
        newCustomerContract: CustomerContract | null,
        addCustomerContractDialog: boolean,
        deleteContractDialog: boolean,
        isAdmin: boolean,
    }

    export default defineComponent({
        props: ['customerId'],
        data(): Data {
            return {
                loading: true,
                contract: {},
                contracts: [],
                newCustomerContract: null,
                addCustomerContractDialog: false,
                deleteContractDialog: false,
                isAdmin: true, //ToDo: Determine Cognito admin role of current user
            };
        },
        components: {
            ContractProcess
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                this.contracts = await customerContractService.getContractsForCustomer(this.customerId);

                this.loading = false;
            },
            onRowSelect(event: any) {

                this.$router.push({ name: 'editContract', params: { contractId: event.data.id } })
            },
            addNewCustomerContract() {
                console.log("add new contract!");

                this.newCustomerContract = {
                    id: 0,
                    description: "new contract",
                    customerId: this.customerId,
                    contractStatusId: 1,
                    minimumTerm: 12,
                    deliveryStart: 10,
                    supplyPoints: [],
                    schedule: [],
                    volumes: [],
               };

                this.addCustomerContractDialog = true;
            },
            async onAddCustomerContract(newContract: any) {

                //await customerContractService.addCustomerContract(newContract);
                
                this.$emit('add:contract', newContract);
                    
                console.log("save successful!");

                // now refresh main list of contracts...
                this.fetchData();
               
                this.addCustomerContractDialog = false;

            },
            confirmDeleteContract(contract: CustomerContract) {
                this.contract = contract;
                this.deleteContractDialog = true;
            },
            async deleteContract() {
                await customerContractService.deleteContract(this.contract.id);

                if (this.contracts) this.contracts = this.contracts.filter(val => val.id !== this.contract.id);

                this.deleteContractDialog = false;
                this.contract = {};
            },
            formatDate(dateToFormat: string) {
                return formatDate(dateToFormat);
            }
        }
    });
