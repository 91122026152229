
    import { defineComponent } from 'vue';
    import { productService, Product } from '@/services/ProductService';

    import ProductItem from '@/components/Settings/Products/ProductItem.vue';

    interface Data {
        loading: boolean,
        product: Product | null,
        isSaving: boolean,
    }

    export default defineComponent({

        props: {
            productId: {
                type: Number,
                required: true
            },
        },
        components: {
            ProductItem,
        },
        data(): Data {

            return {
                loading: true,
                product: null,
                isSaving: false,
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                this.product = await productService.getProductById(this.productId)

                this.loading = false;
                
            },
            async onSubmit(values: any) {

                this.isSaving = true;

                await productService.updateProduct(values)
                    
                //this.$emit('update:product', values);

                this.isSaving = false;
                console.log("save successful!");
                this.$router.push({ name: 'ProductHome' });
                   
            },
        }
    });
