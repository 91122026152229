
    import { defineComponent, PropType } from 'vue';
    import { Trade, tradeService } from '@/services/TradeService';
    import { Period } from '@/services/PeriodService';
    import { Field, ErrorMessage } from 'vee-validate';
    import { Lookup } from '@/services/Types';
    import TradePurchaseGroupPeriods from '@/components/Trading/MarketTrading/TradePurchaseGroupPeriods.vue';

    interface Data {
        loading: boolean,
        tradeToEdit: Trade,
        tradeTypes: Lookup[],
        tradeMethods: Lookup[],
        tradeStatuses: Lookup[],
        counterParties: Lookup[],
    }
    export default defineComponent({
        props: {
            trade: {
                type: Object as PropType<Trade>,
                required: true
            },
            displayPeriod: {
                type: Object as PropType<Period>,
                required: false
            },
        },
        components: {
            Field,
            ErrorMessage,
            TradePurchaseGroupPeriods,
        },
        data(): Data {

            return {
                loading: true,
                tradeToEdit: this.trade,
                tradeTypes: [],
                tradeMethods: [],
                tradeStatuses: [],
                counterParties: [],
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                this.tradeTypes = await tradeService.getTradeTypes();
                this.tradeMethods = await tradeService.getTradeMethods();
                this.tradeStatuses = await tradeService.getTradeStatuses();
                this.counterParties = await tradeService.getCounterParties();

                this.loading = false;
            },
            unitSuffix() {
                switch (this.tradeToEdit.commodityTypeId) {
                    case 1:
                        return " pence/therm"
                    default:
                        return " pounds/MWh";
                }
            }
        },
        computed: {
            isDisabled(): boolean {
                return this.tradeToEdit.id > 0;
            },            
        }
    });
