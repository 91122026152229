
    import { defineComponent, PropType } from 'vue';
    import { Document } from '@/services/Types'

    import Badge from 'primevue/badge';
    import Message from 'primevue/message';
    import FileList from '@/components/Common/FileList.vue';

    interface Data {
        resetKey: number,
        loading: boolean,
        newDocuments: File[],
        existingDocuments: Document[],
    }

    export default defineComponent({
        emits: ['select:Document', 'remove:Document', 'remove:ExistingDocument', 'upload:Document'],
        props: {
            documents: {
                type: Array as PropType<Array<Document>>,
                default: () => []
            },
            accept: {
                type: String,
                default: '.doc,.pdf,.svg,.msg'
            },
            auto: {
                type: Boolean,
                default: false
            },
            multiple: {
                type: Boolean,
                default: true
            },
            fileLimit: {
                type: Number,
                default: null
            },
            showImagePreview: {
                type: Boolean,
                default: false
            },
            chooseLabel: {
                type: String,
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            },
            hideBadges: {
                type: Boolean,
                default: false
            }
        },
        components: {
            Badge,
            FileList,
            Message
        },
        data(): Data {

            return {
                resetKey: 0,
                loading: true,
                newDocuments: [],
                existingDocuments: this.documents,
            };
        },
        watch: {
            documents(newValue) {
                this.existingDocuments = newValue;
                this.newDocuments = [];
                this.resetKey++;
            },
        },
        methods: {
            formatSize(bytes: number) {
                if (bytes === 0) {
                    return '0 B';
                }
                let k = 1000,
                    dm = 3,
                    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                    i = Math.floor(Math.log(bytes) / Math.log(k));
                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            },
            onSelectDocument(event: any) {

                this.newDocuments = [...event.files];

                this.$emit('select:Document', this.newDocuments);
            },
            onRemoveDocument(event: any) {

                this.newDocuments = [...event.files];

                this.$emit('select:Document', this.newDocuments);
            },
            onRemoveExistingDocument(index: number) {

                const removedFile = this.existingDocuments.splice(index, 1)[0];
                this.existingDocuments = [...this.existingDocuments];

                this.$emit('remove:Document', removedFile);
            },
            onUploaded(event: any) {
                this.$emit('upload:Document', this.newDocuments);
                event.files = [];
            }
        },
        computed: {
            chooseDisabled() {
                return this.disabled || (this.fileLimit && this.fileLimit <= this.newDocuments.length + this.existingDocuments.length);
            },
            hideUpload() {
                return this.disabled || (this.fileLimit && this.fileLimit <= this.existingDocuments.length);
            },
        },
      created() {
          console.log('test');
          console.log(this.documents);
      }
    });
