
   
    import { defineComponent } from 'vue';
    import { Lookup } from '@/services/Types';
    import { tradeService } from '@/services/TradeService';
    import ReportFilters from '@/components/Reports/ReportFilters.vue';

    interface Data {
        commodities: Lookup[],
        purchaseGroupId: number | null,
        dateRange: Date[] | null,
    }

    export default defineComponent({
        props: {
            commodity: {
                type: String,
                required: true,
                default: 'gas'
            },
        },
        data(): Data {
            return {
                commodities: [],
                purchaseGroupId: null,
                dateRange: null,
            }
        },
        components: {
            ReportFilters
        },
        computed: {
            items() {
                // get commodities from database
                return this.commodities.map((c: Lookup) => ({ label: c.name, to: { name: "tradeHistoryReport", params: { commodity: c.name.toLowerCase().replaceAll(' ', '') } } }));
            },
        },
        async created() {
            this.commodities = await tradeService.getCommodities();
        },
        methods: {
            onUpdatePurchaseGroup(event: any) {
                console.log("Updated PG:", event);
                this.purchaseGroupId = event?.id ?? null;
            },
            onUpdateDates(dates: Date[]) {
                console.log("Updated dates:", dates);
                this.dateRange = dates;
            },
            onCompleteReporting() {
                this.$router.push({ name: 'reportsHome', /*params: { commodity: this.commodity }*/ })
            },
        }
    });
