
    import { defineComponent } from 'vue';
    import { supplyPointService, SupplyPoint } from '@/services/SupplyPointService';
    import { Site } from '@/services/SiteService';
    import SupplyPointComponent from '@/components/SupplyPoints/SupplyPoint.vue';
    import SupplyPointList from '@/components/SupplyPoints/SupplyPointList.vue';

    interface Data {
        loading: boolean,
        supplyPoints: SupplyPoint[],
        sites: Site[],
        addSupplyPointDialog: boolean,
        chooseSupplyPointTypeDialog: boolean,
        newSupplyPointType: string,
        newSupplyPoint: SupplyPoint | null,
        submitted: boolean,
    }

    export default defineComponent({
        props: {
            customerId: {
                type: Number,
                required: true
            },
            siteId: {
                type: Number,
                required: true
            }
        },
        components: {
            SupplyPointComponent,
            SupplyPointList
        },
        data(): Data {

            return {
                loading: true,
                supplyPoints: [],
                sites: [],
                addSupplyPointDialog: false,
                chooseSupplyPointTypeDialog: false,
                newSupplyPoint: null,
                newSupplyPointType: "",
                submitted: false,
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        computed: {
        
            gasSupplyPointCount() {
                return this.supplyPoints.filter(sp => sp.SupplyPointType === "Gas").length;
            },
            hhElectricSupplyPointCount() {
                return this.supplyPoints.filter(sp => sp.SupplyPointType === "HH").length;
            },
            nhhElectricSupplyPointCount() {
                return this.supplyPoints.filter(sp => sp.SupplyPointType === "NHH").length;
            }
        },
        methods: {
            async fetchData() {

                this.supplyPoints = await supplyPointService.getSupplyPointsForSite(this.siteId);

                this.loading = false;
            },
            openNew() {
                this.submitted = false;
                this.chooseSupplyPointTypeDialog = true;
            },
            createSupplyPoint(event: any) {

                this.newSupplyPointType = event.target.value;
                this.chooseSupplyPointTypeDialog = false;

                console.log(this.newSupplyPointType);

                this.newSupplyPoint = {
                    SupplyPointType: this.newSupplyPointType,
                    id: 0,
                    siteId: this.siteId
                }
                this.addSupplyPointDialog = true;
            },
            async onAddSupplyPoint() {

                this.addSupplyPointDialog = false;
                this.newSupplyPoint = null;

                // now refresh main list of supply points...
                this.supplyPoints = await supplyPointService.getSupplyPointsForSite(this.siteId);
            },
        }
    });
