
    import { defineComponent } from 'vue';
    import { customerService, Customer } from '@/services/CustomerService';
    import { contactService, Contact } from '@/services/ContactService';
    import { Document } from '@/services/Types';

    import CustomerItem from '@/components/Customer/CustomerItem.vue';
    import ContactItem from '@/components/Contacts/ContactItem.vue';
    import ContactList from '@/components/Contacts/ContactList.vue';
    import Editor from 'primevue/editor';
    import DocumentUpload from '@/components/Common/DocumentUpload.vue';

    interface Data {
        loading: boolean,
        customer: Customer | null,
        contacts: Contact[],
        newDocuments: File[],
        documentsToRemove: string[],
        removeExistingLogo: boolean,
        removeExistingAuthority: boolean,
        newContact: null | Contact,
        addContactDialog: boolean,
        isSaving: boolean,
    }

    export default defineComponent({

        props: {
            customerId: {
                type: Number,
                required: true
            },
        },
        components: {
            CustomerItem,
            ContactList,
            Editor,
            DocumentUpload,
            ContactItem
        },
        data(): Data {

            return {
                loading: true,
                customer: null,
                contacts: [],
                newDocuments: [],
                documentsToRemove: [],
                removeExistingLogo: false,
                removeExistingAuthority: false,
                newContact: null,
                addContactDialog: false,
                isSaving: false,
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                this.customer = await customerService.getCustomerById(this.customerId);
                this.contacts = await customerService.getCustomerContactsById(this.customerId);

                this.loading = false;
                
            },
            async onSubmit(event: any) {
                this.isSaving = true;

                // overwrite ell property
                const updateCustomer = {
                    ...event,
                    notes: this.customer?.notes,
                    documents: this.newDocuments,
                    documentsToRemove: this.documentsToRemove,
                    removeExistingLogo: this.removeExistingLogo,
                    removeExistingAuthority: this.removeExistingAuthority
                };

                await customerService.updateCustomer(updateCustomer);
                
                this.$emit('update:customer', updateCustomer);

                     // refresh customer data
                this.customer = await customerService.getCustomerById(this.customerId);
                        
                this.isSaving = false;

                console.log("save successful!");
            },
            onSelectDoc(event: File[]) {
                this.newDocuments = event;
            },
            onRemoveDoc(document: Document) {
                this.documentsToRemove.push(document.id);
            },
            onRemoveLogo() {
                this.removeExistingLogo = true;
            },
            onRemoveAuthority() {
                this.removeExistingAuthority = true;
            },
            addNewContact() {
                console.log("add new contact!");

                this.newContact = {
                    id: 0,
                    customerId: this.customerId,
                    firstName: "",
                    lastName: "",
                    email: "",
                    contactTypes: []
                };

                this.addContactDialog = true;
            },
            onAddContact(newContact: Contact) {


                contactService.addContact(newContact)
                    .then(() => {

                        this.$emit('add:contact', newContact);

                        // now refresh main list of contacts...
                        customerService.getCustomerContactsById(this.customerId).
                            then(result => {
                                
                                this.contacts = result;

                                console.log("save successful!")
                            });
                        
                    })

                this.addContactDialog = false;

            }
        }
    });
