import { Lookup } from '@/services/Types'
import { getFormData } from '@/composables/UtilityFunctions'
import { useTradingApi } from '@/composables/TradingApi'
import { useCRMApi } from '@/composables/CRMApi'
import { Period } from './PeriodService';
import { Customer } from './CustomerService';

export interface PurchaseGroup {
    id: number,
    name: string,
    supplierId?: number,
    defaultIndexId?: number,
    statusId?: number,
    commodityTypeId?: number,
    startDate?: Date,
    endDate?: Date,
    parentPurchaseGroupId?: number,
    minimumClipsSize?: number,
    sellbackFee?: number,
    indexFee?: number,
    nonTradingDaysAtMonthEnd?: string,
    notes?: string,
    volumesOverride: boolean,
    contractDocument?: Document,
    allowedTradeTypes: Lookup[],
    volumes: Volume[],
    contracts: PurchaseGroupContract[],
    benchmarkRate?: number
}

export interface PurchaseGroupPeriod {
    supplyPointId: Number,
    contractId: Number
}

export interface PurchaseGroupVolumesOverride {
    id: Number,
    volumesOverride: boolean
}

export interface PurchaseGroupContract {
    id: Number,
    purchaseGroupId: Number,
    customerContractId: Number,
    customerId: Number,
}

export interface Volume {
    id: Number,
    purchaseGroupId: Number,
    periodId: Number,
    tradeableVolume: number,
    tradedVolume: number,
    exposedVolume: number,
    calculatedVolume: number,
}

export interface VolumeHistory {
    id?: Number,
    volumeId: Number,
    periodId: Number,
    tradeableVolume: number,
    justification: string,
    fileUpload: Document | null,
    creationTime?: Date | null,
    creatorUserId?: string | null,
}

export default class PurchaseGroupService {

    async getPurchaseGroups() {

        const data = await useTradingApi('/trading/purchasegroups') as PurchaseGroup[];
        return data ?? [];
    }

    async getPurchaseGroupById(id: Number) {

        const data = await useTradingApi(`/trading/purchasegroups/${id}`) as PurchaseGroup;
        return data ?? null;
    }

    async getPurchaseGroupStatuses() {

        const data = await useTradingApi('/trading/purchasegroupstatuses') as Lookup[];
        return data ?? [];
    }

    async getPurchaseGroupContracts(id: Number) {

        const data = await useTradingApi(`/trading/purchasegroups/${id}/customercontracts`) as PurchaseGroupContract[];
        return data ?? [];
    }

    async getPurchaseGroupAllContracts() {

        const data = await useTradingApi(`/trading/purchasegroups/allcontracts`) as PurchaseGroupContract[];
        return data ?? [];
    }

    async getPurchaseGroupContractById(id: Number) {

        const data = await useTradingApi(`/trading/purchasegroups/customercontracts/${id}`) as PurchaseGroupContract;
        return data ?? [];
    }

    async getCustomersByPurchaseGroupId(id: Number) {

        const data = await useTradingApi(`/trading/purchasegroups/customers/${id}`) as Customer[];
        return data ?? [];
    }

    async getCommodities() {

        const data = await useTradingApi('/trading/commodities') as Lookup[];
        return data ?? [];
    }

    async getPurchaseGroupByContractId(id: Number) {
        const data = (await useTradingApi(`/trading/purchasegroups/customercontract/${id}`)) as PurchaseGroup;
        return data ?? null;
    }

    async getSuppliers() {

        const data = await useCRMApi('/crm/suppliers') as Lookup[];
        return data ?? [];
    }

    async getDefaultIndexes() {

        const data = await useTradingApi('/trading/defaultindexes') as Lookup[];
        return data ?? [];
    }

    async updatePurchaseGroup(purchaseGroup: any | null) {

        const formData = getFormData(purchaseGroup);

        const newOptions = {
            method: 'PUT',
            body: formData
        };

        const data = await useTradingApi(`/trading/purchasegroups`, newOptions);
        return data ?? null;
    }

    async addPurchaseGroup(purchaseGroup: any | null) {

        const formData = getFormData(purchaseGroup);

        const newOptions = {
            method: 'POST',
            body: formData
        };

        const data = await useTradingApi(`/trading/purchasegroups`, newOptions);
        return data ?? null;
    }

    async updatePurchaseGroupContract(purchaseGroupContract: any | null) {

        const formData = getFormData(purchaseGroupContract);

        const newOptions = {
            method: 'PUT',
            body: formData
        };

        const data = await useTradingApi(`/trading/purchasegroups/customercontract`, newOptions);
        return data ?? null;
    }

    async addPurchaseGroupContract(purchaseGroupContract: any | null) {

        const formData = getFormData(purchaseGroupContract);

        const newOptions = {
            method: 'POST',
            body: formData
        };

        const data = await useTradingApi(`/trading/purchasegroups/customercontract`, newOptions);
        return data ?? null;
    }

    async deletePurchaseGroupContract(id: Number) {
        const newOptions = {
            method: 'DELETE'
        };

        const data = await useTradingApi(`/trading/purchasegroups/customercontract/${id}`, newOptions);
        return data ?? null;
    }

    async getPurchaseGroupsForCommodity(commodityTypeId: number | null) {

        const data = await useTradingApi(`/trading/purchasegroups/commodity/${commodityTypeId}`) as PurchaseGroup[];
        return data ?? [];
    }

    async getOpenPurchaseGroupsForCommodity(commodityTypeId: number | null) {

        const data = await useTradingApi(`/trading/purchasegroups/commodity/${commodityTypeId}/open`) as PurchaseGroup[];
        return data ?? [];
    }

    async getVolumesByPurchaseGroupId(id: Number) {

        const data = await useTradingApi(`/trading/purchasegroups/${id}/volumes`) as Volume[];
        return data ?? [];
    }

    async getOpenVolumes(purchaseGroups: PurchaseGroup[], periods: Period[]) {

        const purchaseGroupIds = purchaseGroups.map(pg => pg.id);
        const periodIds = periods.map(p => p.id);

        const formData = getFormData({ purchaseGroupIds, periodIds });

        const newOptions = {
            method: 'POST',
            body: formData
        };

        const data = await useTradingApi(`/trading/purchasegroups/openvolumes`, newOptions) as Volume[];
        return data ?? null;
    }

    async addVolumes(volumes: Volume[]) {
        const newOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(volumes)
        };

        const data = await useTradingApi('/trading/purchasegroups/volumes', newOptions);
        return data ?? null;
    }

    async addVolume(volume: Volume) {

        const formData = getFormData(volume);

        const newOptions = {
            method: 'POST',
            body: formData
        };

        const data = await useTradingApi(`/trading/purchasegroups/volume`, newOptions) as Volume;
        return data ?? null;
    }

    async updateVolume(volume: VolumeHistory) {

        const formData = getFormData(volume);

        const newOptions = {
            method: 'PUT',
            body: formData
        };

        const data = await useTradingApi(`/trading/purchasegroups/volume`, newOptions);
        return data ?? null;
    }

    async updateVolumes(volumes: VolumeHistory[]) {
        //  const formData = getFormData(volumes);
        const newOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(volumes)
        };

        const data = await useTradingApi('/trading/purchasegroups/volumes', newOptions);
        return data ?? null;
    }

    async deleteVolume(id: Number) {
        const newOptions = {
            method: 'DELETE'
        };

        const data = await useTradingApi(`/trading/purchasegroups/volume/${id}`, newOptions);
        return data ?? null;
    }

    async deleteVolumes(ids: Number[]) {

        const formData = getFormData({ ids: ids });

        const newOptions = {
            method: 'DELETE',
            body: formData
        };

        const data = await useTradingApi(`/trading/purchasegroups/volume/`, newOptions);
        return data ?? null;
    }

    async getAllVolumeHistoriesByPurchaseGroup(id: Number) {

        const data = await useTradingApi(`/trading/purchasegroups/${id}/volumehistories/`) as VolumeHistory[];
        return data ?? [];
    }

    async getVolumesOverrideByPurchaseGroupId(id: Number) {

        const data = await useTradingApi(`/trading/purchasegroups/${id}/volumesoverride/`) as PurchaseGroupVolumesOverride;
        return data ?? null;
    }

    async setVolumesOverride(id: Number, override: boolean) {

        const payload = { id: id, volumesOverride: override };

        const newOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };

        const data = await useTradingApi(`/trading/purchasegroups/volumesoverride`, newOptions);
        return data ?? null;
    }

}

export const purchaseGroupService = new PurchaseGroupService();