
    import { defineComponent } from 'vue';
    import { Customer, customerService } from '@/services/CustomerService';
    import { User, userService } from '@/services/UserService';
    import { Lookup } from '@/services/Types';
    import CustomerItem from '@/components/Customer/CustomerItem.vue';
    import { FilterMatchMode } from 'primevue/api';
    import MultiSelect from 'primevue/multiselect';

    interface Data {
        loading: boolean,
        customers: Customer[],
        statuses: Lookup[],
        companytypes: Lookup[],
        partners: Lookup[],
        users: User[],
        sectors: Lookup[],
        newCustomer: Customer | null,
        addCustomerDialog: boolean,
        customerListFilters: {}
    }

    export default defineComponent({
        data(): Data {
            return {
                loading: true,
                customers: [],
                statuses: [],
                companytypes: [],
                partners: [],
                users: [],
                sectors: [],
                newCustomer: null,
                addCustomerDialog: false,
                customerListFilters: {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'customerStatusId': { value: null, matchMode: FilterMatchMode.IN }, 
                    'companyTypeId': { value: null, matchMode: FilterMatchMode.IN }, 
                    'accountManagerId': { value: null, matchMode: FilterMatchMode.IN }, 
                    'partnerId': { value: null, matchMode: FilterMatchMode.IN }, 
                    'sectorId': { value: null, matchMode: FilterMatchMode.IN }, 
                },
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        components: {
            CustomerItem,
            MultiSelect
        },
        //watch: {
        //    // call again the method if the route changes
        //    '$route': 'fetchData' //todo: what does this do?
        //},
        methods: {
            async fetchData() {

                this.companytypes = await customerService.getCompanyTypes();
                this.partners = await customerService.getPartners();
                this.statuses = await customerService.getCustomerStatuses();
                this.sectors = await customerService.getSectors();
                this.users = await userService.getUsers();

                this.customers = await customerService.getCustomers();

                this.loading = false;

            },
            onRowSelect(event: any) {

                this.$router.push({ name: 'editCustomer', params: { customerId: event.data.id } })
            },
            addNewCustomer() {

                this.newCustomer = {
                    id: 0,
                    name: "",
                    registeredOfficeAddress: {},
                    correspondenceAddress: {},
                };

                this.addCustomerDialog = true;
            },
            async onAddCustomer(addCustomerEvent: any) {

                await customerService.addCustomer(addCustomerEvent)

                this.$emit('add:customer', addCustomerEvent);

                console.log("save successful!");

                // now refresh main list of sites...
                this.customers = await customerService.getCustomers();

                this.addCustomerDialog = false;
            },
        }
    });
