
    import { defineComponent } from 'vue';
    import { triggerService, Trigger } from '@/services/TriggerService';
    import TriggerItem from '@/components/Settings/Triggers/TriggerItem.vue';

    interface Data {
        loading: boolean,
        trigger: Trigger | null,
        isSaving: boolean,
    }

    export default defineComponent({

        props: {
            triggerId: {
                type: Number,
                required: true
            },
        },
        components: {
            TriggerItem,
        },
        data(): Data {

            return {
                loading: true,
                trigger: null,
                isSaving: false,
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                this.trigger = await triggerService.getTriggerById(this.triggerId)

                this.loading = false;

            },
            async onSubmit(values: any) {

                this.isSaving = true;

                  await triggerService.updateTrigger(values)

                this.$emit('update:trigger', values);

                this.isSaving = false;
                console.log("save successful!");
                this.$router.push({ name: 'TriggerHome' });

            },
            onCancel() {
                this.$router.push({ name: 'TriggerHome' });
            },
        }
    });
