
    import { defineComponent } from 'vue';
    import { siteService, YearlyAgreement, YearlyAgreementInfo } from '@/services/SiteService';
    import { Document } from '@/services/Types'
    import EnergyIntensiveIndustryItem from '@/components/Sites/EnergyIntensiveIndustryItem.vue';

    interface Data {
        loading: boolean,
        agreementInfo: YearlyAgreementInfo | null,
        newAgreement: YearlyAgreement | null,
        removeExistingDocument: boolean,
        energyIntenseEnabled: boolean,
        addAgreementDialog: boolean,
        deleteAgreementDialog: boolean,
        submitted: boolean,
    }

    export default defineComponent({
        props: {
            siteId: {
                type: Number,
                required: true
            }
        },
        components: {
            EnergyIntensiveIndustryItem,
        },
        data(): Data {

            return {
                loading: true,
                agreementInfo: null,
                newAgreement: null,
                removeExistingDocument: false,
                energyIntenseEnabled: false,
                addAgreementDialog: false,
                deleteAgreementDialog: false,
                submitted: false,
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                await this.getEnergyIntensiveIndustryInfoForSite();

                this.energyIntenseEnabled = this.agreementInfo ? this.agreementInfo.agreements.length > 0 : false;

                this.loading = false;
            },
            async getEnergyIntensiveIndustryInfoForSite() {

                await siteService.getEnergyIntensiveIndustryInfoForSite(this.siteId)
                    .then((json: any) => {
                        //console.log(json);
                        this.agreementInfo = json;
                    })
                    .catch((err: any) => {
                        console.log(err);
                    });
            },
            hideDialog() {
                this.addAgreementDialog = false;
                this.submitted = false;
            },
            openNewClimateChangeDialog() {
                this.submitted = false;
                this.newAgreement = {
                    id: 0,
                    siteId: this.siteId,
                    year: 2023,
                    reduction: 10.0,
                    document: null
                };
                this.addAgreementDialog = true;
            },
            editAgreement(agreement: YearlyAgreement) {
                this.submitted = false;
                this.newAgreement = agreement;
                this.addAgreementDialog = true;
            },
            confirmDeleteAgreement(agreement: YearlyAgreement) {
                this.newAgreement = agreement;
                this.deleteAgreementDialog = true;
            },
            async saveAgreement(event: YearlyAgreement) {
                this.submitted = true;
                console.log(event);

                if (event.id) {
                    const editedAgreement = {
                        ...event,
                        removeExistingDocument: this.removeExistingDocument,
                    }

                    await siteService.updateEnergyIntensiveIndustry(editedAgreement);
                    if (this.agreementInfo)
                        this.agreementInfo.agreements = this.agreementInfo.agreements.filter(a => a.id !== event.id);
                    this.agreementInfo?.agreements.push(event);
                }
                else {
                    var addedAgreement = await siteService.addEnergyIntensiveIndustry(event);
                    this.agreementInfo?.agreements.push(addedAgreement);
                }
                this.agreementInfo?.agreements.sort((a, b) => a.year - b.year);
                this.hideDialog();
            },
            async deleteAgreement() {
                if (this.newAgreement)
                    await siteService.deleteEnergyIntensiveIndustry(this.newAgreement.id);

                if (this.agreementInfo)
                    this.agreementInfo.agreements = this.agreementInfo.agreements.filter(val => val.id !== this.newAgreement?.id);

                this.deleteAgreementDialog = false;
            },
            onRemoveDocument() {
                this.removeExistingDocument = true;
            },
            async downloadItem(download: Document) {
                await fetch(download.url, {
                    headers: {
                        'Content-Type': download.type
                    },
                })
                .then(response => {
                    console.log(response.type);
                    response.blob()
                    .then((myBlob) => {
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(myBlob);
                        link.download = download.name;
                        link.click();
                        URL.revokeObjectURL(link.href);
                    });
                });
            },
        }
    });
