
    import { defineComponent, PropType } from 'vue';
    import { Site } from '@/services/SiteService';
    import { customerService, Customer } from '@/services/CustomerService';
    import * as yup from "yup";
    import { Form, Field, ErrorMessage } from 'vee-validate';
    import { RegEx } from '@/composables/RegularExpressions';

    interface Data {
        editMode: boolean,
        validationSchema: any,
        customer: Customer | null,
        site: Site,
    }

    export default defineComponent({

        props: {
            editSite: {
                type: Object as PropType<Site>,
                required: true
            },
        },
        components: {
            Form,
            Field,
            ErrorMessage,
        },
        data(): Data {

            const validationSchema = yup.object({
                customerId: yup.number().required(),
                name: yup.string().required().label("Name"),
                costCentre: yup.string().nullable().label("Cost Centre"),
                customRef: yup.string().nullable().label("Customer Reference"),
                customName: yup.string().nullable().label("Customer Name"),
                address1: yup.string().nullable().label("Address 1"),
                address2: yup.string().nullable().label("Address 2"),
                locality: yup.string().nullable().label("Locality"),
                town: yup.string().nullable().label("Town"),
                county: yup.string().nullable().label("County"),
                postcode: yup.string().nullable().label("Postcode").matches(RegEx.POSTCODE, { message: 'Must be a valid postcode', excludeEmptyString: true }),
            });

            return {
                editMode: false,
                validationSchema: validationSchema,
                customer: null,
                site: this.editSite,
            };
        },
        created() {
            this.editMode = this.site.id > 0;

            // fetch on init
            this.fetchData();
        },
        watch: {
            editSite(newValue) {
                this.site = newValue;
            },
        },
        methods: {
            async fetchData() {
                this.customer = await customerService.getCustomerById(this.site.customerId);
                this.site.customName = this.site.customName ? this.site.customName : (this.customer ? this.customer.name : "");
            },
            onSubmit(values: any) {
                console.log(values);

                const editedSite = {
                    ...this.site,
                }

                this.$emit('submit:site', editedSite);
            },
        }
    });
