import { useCRMApi } from '@/composables/CRMApi'

// todo: move these to a separate file
export interface SupplyPoint {
    SupplyPointType: string,
    id: number,
    name?: string | null
    siteId: number,
    annualConsumption?: number | null,
    budgetCost?: number | null,
    meterOperatorRenewalDate?: Date | null,
    meterOperatorId?: number | null,
    amrEnabled?: Boolean | null,
    fivePercentVATEnabled?: Boolean | null,
    accountNumber?: string | null,
    benchmarkRate?: number
}

export interface ContractSupplyPoint {
    supplyPointId: number,
    contractId: number
}

export interface ActiveSupplyPoint {
    supplyPoint: SupplyPoint,
    contracted: boolean
}

export interface SupplyPointQuery {
    customerId: number,
    startDate: Date,
    endDate: Date,
}

export interface GasSupplyPoint extends SupplyPoint {
    meterPointReference?: string | null,
    meterSerialNumber?: string | null,
    gasLive?: boolean | null,
    meterName?: string | null,
    meterAssetManager?: string | null,
}


export interface ElectricitySupplyPoint extends SupplyPoint {
    profileClassId?: number,
    llf?: string | null,
    mts?: string | null,
    mpanCore?: string | null,
    supplyCapacity?: number | null,
    tcrBandingId?: number | null,
    isEnergised?: boolean | null,
    dnoId?: number | null,
}

export interface HHSupplyPoint extends ElectricitySupplyPoint {
    voltageId?: number,
    otherMPANS?: string | null,
    aggregatedMPANS?: boolean | null,
}

export interface NHHSupplyPoint extends ElectricitySupplyPoint {
}


export interface MeterOperator {
    id: number,
    name: string
}

export interface ProfileClass {
    id: number,
    name: string
}

export interface TCRBanding {
    id: number,
    name: string
}

export interface Voltage {
    id: number,
    name: string
}

export interface MeterType {
    id: number,
    name: string
}

export interface DNO {
    id: number,
    name: string
}

export default class SupplyPointService {
   
    async getSupplyPointsForCustomer(customerId: Number) {

        const data = await useCRMApi(`/crm/supplypoints/customer/${customerId}`) as SupplyPoint[];
        return data ?? [];
    }

    async getSupplyPointsForSite(siteId: Number) {

        const data = await useCRMApi(`/crm/supplypoints/site/${siteId}`) as SupplyPoint[];
        return data ?? [];
    }

    async getSupplyPoint(supplyPointId: Number) {

        const data = await useCRMApi(`/crm/supplypoints/${supplyPointId}`) as SupplyPoint;
        return data ?? null;
    }

    async updateSupplyPoint(supplyPoint: SupplyPoint) {

        const newOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(supplyPoint)
        };

        const data = await useCRMApi(`/crm/supplypoints`, newOptions);
        return data ?? null;
    }

    async addSupplyPoint(supplyPoint: SupplyPoint) {

        const newOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(supplyPoint)
        };

        const data = await useCRMApi(`/crm/supplypoints`, newOptions);
        return data ?? null;
    }

    async getContractedCustomerSupplyPoints(supplyPointQuery: SupplyPointQuery) {

        const newOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(supplyPointQuery)
        };

        const data = await useCRMApi(`/crm/supplypoints/contractedcustomer`, newOptions) as ActiveSupplyPoint[];
        return data ?? [];
    }

    async getMeterOperators() {

        const data = await useCRMApi('/crm/meteroperators') as MeterOperator[];
        return data ?? [];
    }

    async getProfileClasses() {

        const data = await useCRMApi('/crm/profileclasses') as ProfileClass[];
        return data ?? [];
    }

    async getTCRBandings() {

        const data = await useCRMApi('/crm/tcrbandings') as TCRBanding[];
        return data ?? [];
    }

    async getVoltages() {

        const data = await useCRMApi('/crm/voltages') as Voltage[];
        return data ?? [];
    }

    async getMeterTypes() {

        const data = await useCRMApi('/crm/metertypes') as MeterType[];
        return data ?? [];
    }

    async getDNOs() {

        const data = await useCRMApi('/crm/dnos') as DNO[];
        return data ?? [];
    }
}


export const supplyPointService = new SupplyPointService();


