
    import { defineComponent } from 'vue';
    import { consumptionService, ConsumptionImport } from '@/services/ConsumptionService';
    import ConsumptionList from '@/components/Settings/Consumption/ConsumptionList.vue';

    interface Data {
        loading: boolean,
        imports: ConsumptionImport[],
    }

    export default defineComponent({
        data(): Data {
            return {
                loading: true,
                imports: [],
            };
        },
        components: {
            ConsumptionList
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                this.imports = await consumptionService.getImports();

                this.loading = false;

            },
            async refreshList() {
                await this.fetchData();
            }
        }
    });
