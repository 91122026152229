
    import { defineComponent, PropType } from 'vue';
    import * as yup from "yup";
    import { Form, Field } from 'vee-validate';
    import { formatDate } from '@/composables/UtilityFunctions';
    import { SupplyPoint, GasSupplyPoint, ElectricitySupplyPoint } from '@/services/SupplyPointService';
    import { customerContractService, CustomerContract, DeliveryWindow, TermCharge, TermChargeXd } from '@/services/ContractService';
    import { siteService, Site } from '@/services/SiteService';
    import { Lookup } from '@/services/Types';
    import TabView from 'primevue/tabview';
    import TabPanel from 'primevue/tabpanel';
    import DataTable from 'primevue/datatable';

    interface Data {
        loading: boolean,
        validationSchema: any,
        supplyPointIds: number[],
        sites: Site[],
        submitted: boolean,
        contract: CustomerContract,
        utilityTypes: Lookup[],
        activeIndex: number,
        extendedTerms: TermChargeXd[][],
    }

    export default defineComponent({
        props: {
            editContract: {
                type: Object as PropType<CustomerContract>,
                required: true
            },
            editMode: Boolean,
            isActiveStep: Boolean,
            saveMe: {
                type: Boolean,
                required: false
            },
        },
        components: {
            Form,
            Field,
            TabView,
            TabPanel
        },
        data(): Data {

            const validationSchema = yup.object({
                customerContractId: yup.number().required(),
            })
                .typeError("Please select ${value} and ${type}");

            return {
                loading: true,
                validationSchema: validationSchema,
                supplyPointIds: [],
                sites: [],
                submitted: false,
                contract: this.editContract,
                utilityTypes: [],
                activeIndex: 0,
                extendedTerms: [],
            };
        },
        created() {
            // fetch on init
            this.initData();
        },
        computed: {
        },
        watch: {
            editContract(newValue) {
                if (this.isActiveStep) {
                    console.log("contract changed");
                    this.contract = newValue;
                    this.fetchData();
                }
            },
            isActiveStep(newValue) {
                if (newValue) {
                    console.log("step loaded");
                    this.fetchData();
                }
            },
            saveMe(shouldSave) {
                //Forces save if in edit mode
                if (this.editMode && shouldSave) {
                    this.onSubmit(true);
                }
            },
        },
        methods: {
            async initData() {
                this.sites = await siteService.getSitesForCustomer(this.contract.customerId);
                console.log("this.sites: ", this.sites)

                this.loading = false;
            },
            async fetchData() {
                var schedule: DeliveryWindow[] = [];
                if (this.contract.id > 0) schedule = await customerContractService.getScheduleByContractId(this.contract.id);
                if (schedule.length && schedule[0].termCharges.length == 0)
                    this.createTermCharges();
                //Check that term charges have corresponding contract supply points
                else {
                    var dws: DeliveryWindow[] = [];
                    var spids: number[] = this.contract.supplyPoints.map(sp => sp.id);
                    schedule.forEach(dw => {
                        dws.push({
                            ...dw,
                            termCharges: dw.termCharges.filter(tc => spids.includes(tc.supplyPointId))
                        })
                    });
                    this.contract.schedule = dws;
                }
            },
            validSPs(terms: TermCharge[], dwIndex: number) {
                var spids: number[] = this.contract.supplyPoints.map(sp => sp.id);
                var validTerms = terms.filter(tc => spids.includes(tc.supplyPointId));

                //Populate extended terms array with values to ensure fields get exported correctly
                let extendedTerms = validTerms.map(vt => {
                    let sp = this.getSupplyPointFromId(vt.supplyPointId);
                    return {
                        meterRef: this.getMeterIdentifier(vt.supplyPointId),
                        supplyPointName: sp?.name,
                        siteName: this.getSiteNameFromSupplyPointId(vt.supplyPointId),
                        annualConsumption: sp?.annualConsumption,
                        termCharge: vt,
                    }
                });
                this.extendedTerms[dwIndex] = extendedTerms;
                return extendedTerms;
            },
            getSiteNameFromSupplyPointId(supplyPointId: number) {
                let matchedSp = this.contract.supplyPoints.find(s => s.id == supplyPointId);
                let matchedSite = matchedSp && this.sites.length ? this.sites.find(s => s.id == matchedSp?.siteId) : null;
                return matchedSite ? matchedSite.name : "Not found";
            },
            getSupplyPointFromId(supplyPointId: number) {
                let matchedSp = this.contract.supplyPoints.find(s => s.id == supplyPointId);
                return matchedSp;
            },
            getMeterIdentifier(supplyPointId: number) {
                let supplyPoint = this.getSupplyPointFromId(supplyPointId);

                switch (supplyPoint?.SupplyPointType) {
                    case "Gas":
                        return (supplyPoint as GasSupplyPoint).meterPointReference;
                    case "HH":
                    case "NHH":
                    case "HH & NHH":
                        return (supplyPoint as ElectricitySupplyPoint).mpanCore;
                    default:
                        return "Not set";
                }
            },
            utilityTypeMatchesSupplyPointType(utilityType: Lookup | undefined, supplyPoint: SupplyPoint): boolean {
                if (utilityType?.name == "Gas" && supplyPoint.SupplyPointType == "Gas") {
                    return true;
                }

                if (utilityType?.name == "Half Hourly" && supplyPoint.SupplyPointType == "HH") {
                    return true;
                }

                if (utilityType?.name == "Non Half Hourly" && supplyPoint.SupplyPointType == "NHH") {
                    return true;
                }

                if (utilityType?.name == "HH & NHH" && (supplyPoint.SupplyPointType == "HH" || supplyPoint.SupplyPointType == "NHH")) {
                    return true;
                }

                return false;
            },
            utilityIsElectric(): boolean {
                const utilityId = this.contract?.utilityTypeId;
                // utilityId 3 is for gas
                if (utilityId !== null && utilityId !== undefined && utilityId === 3) {
                    return false;
                }
                return true;
            },
            async onSubmit(values: any) {
                console.log("Submitting Term Charges:", values);

                const editedContract = {
                    ...this.contract,
                }

                console.log("Contract:", editedContract);

                var editedCharges: TermCharge[] = [];
                this.contract.schedule.forEach(dw => editedCharges = [ ...editedCharges, ...dw.termCharges ]);
                const newCharges = editedCharges.filter(tc => tc.id == 0);
                editedCharges = editedCharges.filter(tc => tc.id > 0);

                if (newCharges.length) await customerContractService.addContractTermCharges(newCharges);
                if (editedCharges.length) await customerContractService.updateContractTermCharges(editedCharges);

                this.$emit('submit:contract', editedContract);
            },
            formatHeader(dateToFormat1: string, separator: string, dateToFormat2: string, format: string | null) {
                format = format ?? "DD/MM/YYYY";
                return formatDate(dateToFormat1, format) + separator + formatDate(dateToFormat2, format);
            },
            createTermCharges() {
                console.log("Creating Term Charges");
                const newTC: TermCharge = {
                    id: 0,
                    deliveryWindowId: 0,
                    supplyPointId: 0,
                    shapeFee: 0,
                    nonCommodityCost: 0,
                    capacityChargeApr: 0,
                    capacityChargeOct: 0,
                    dailyCharge: 0,
                };

                if (this.contract.schedule.length) {
                    this.contract.schedule.forEach(dw => {
                        var newTCs: TermCharge[] = this.contract.supplyPoints.map(sp => (
                            {
                                ...newTC,
                                deliveryWindowId: dw.id,
                                supplyPointId: sp.id,
                                shapeFee: dw.shapeFee ?? 0,
                            }
                        ));
                        dw.termCharges = newTCs;
                    });
                }
            },
            onCellEditComplete(event: any) {
                let { data, newValue, field } = event;

                switch (field) {
                    case 'nonCommodityCost':
                    case 'dailyCharge':
                    case 'capacityChargeApr':
                    case 'capacityChargeOct':
                    case 'shapeFee':
                        data.termCharge[field] = newValue;
                        break;

                    default:
                        if (newValue.trim().length > 0) data[field] = newValue;
                        else event.preventDefault();
                        break;
                }
            },
            exportCSV() {
                //console.log("Exporting...", this.$refs);
                let exportOptions = { selectionOnly: false };

                //Flatten extendedTerms termCharge object to ensure charge fields get exported
                let exportData = this.extendedTerms[this.activeIndex].map(et => ({
                    ...et,
                    ...et.termCharge
                }));
                (this.$refs.exporttable as DataTable[])[this.activeIndex].exportCSV(exportOptions, exportData);
            }
        }
    });
