
    import { defineComponent } from 'vue';
    import { purchaseGroupService, PurchaseGroup, Volume } from '@/services/PurchaseGroupService';
    import { /*periodService, PeriodPrice,*/ Period } from '@/services/PeriodService';
    import { /*Trade,*/ tradeService } from '@/services/TradeService';
    import { Lookup } from '@/services/Types';
    import { formatDate } from '@/composables/UtilityFunctions';
    
    interface Data {
        loading: boolean,
        purchaseGroupId: number,
        purchaseGroups: PurchaseGroup[],
        selectedPurchaseGroups: PurchaseGroup[],
        selectedPG: PurchaseGroup | null,
        commodityTypes: Lookup[],
        dateRange: Date[] | null,
    }
    export default defineComponent({
        props: {
            commodity: {
                type: String,
                required: true
            },
        },
        components: {
        },
        data(): Data {
            return {
                loading: true,
                purchaseGroupId: 0,
                purchaseGroups: [],
                selectedPurchaseGroups: [],
                selectedPG: null,
                commodityTypes: [],
                dateRange: null,
            }
        },
        created() {
            this.fetchData();
        },
        watch: {
            async selectedPG(newPG: PurchaseGroup) {
                if (newPG != null) {
                    console.log("Selected:", newPG);
                    newPG.volumes = await purchaseGroupService.getVolumesByPurchaseGroupId(newPG.id);
                    this.selectedPurchaseGroups = [newPG];
                }
                this.$emit('update:purchasegroup', newPG);
            },
            dateRange(newDates: Date[]) {
                this.$emit('update:daterange', newDates);
            },
        },
        computed: {
            commodityTypeId() {

                const type = this.commodityTypes.find(ct => ct.name.toLowerCase().replaceAll(' ', '') === this.commodity);
                return type?.id ?? 0; // should never hit this...
            }
        },
        methods: {
            async fetchData() {
                
                this.commodityTypes = await tradeService.getCommodities();

                this.purchaseGroups = await purchaseGroupService.getOpenPurchaseGroupsForCommodity(this.commodityTypeId) as PurchaseGroup[];
                this.loading = false;
            },
            filterPeriodsArray(periods:Period[], volumes:Volume[]) {
                return periods.filter((period:Period) =>  volumes.some((volume:Volume) => period.id === volume.periodId));
            },
            formatDate(dateToFormat: string) {
                return formatDate(dateToFormat);
            },
            onClearFilters() {
                this.selectedPG = null;
                this.dateRange = null;
            }
        },
    });
