
    import { defineComponent, PropType } from 'vue';
    import { Trade } from '@/services/TradeService';
    import { Period } from '@/services/PeriodService';
    import * as yup from "yup";
    import dayjs from "dayjs";
    import { Form, Field, ErrorMessage } from 'vee-validate';
    import TradeDetails from '@/components/Trading/MarketTrading/TradeDetails.vue';

    import Editor from 'primevue/editor';
    import {VolumeService} from "../../../services/VolumeService";
    interface Data {
        loading: boolean,
        validationSchema: any,
        tradeToEdit: Trade,
        toTradeVolume: number,
        openVolume: number,
    }
    export default defineComponent({
        props: {
            trade: {
                type: Object as PropType<Trade>,
                required: true
            },
            tradedVolume: {
                type: Number,
                default: 0
            },
            sellTradeSelected: {
                type: Boolean,
                required: true
            },
            displayPeriod: {
                type: Object as PropType<Period>,
                required: false
            },
       },
        components: {
            Form,
            Field,
            ErrorMessage,
            Editor,
            TradeDetails
        },
        data(): Data {
            const validationSchema = (maxTradeValue: number) => yup.object({
                commodityTypeId: yup.number().required().label("Commodity Type"),
                tradeTypeId: yup.number().required().label("Trade Type"),
                tradeStatusId: yup.number().required().label("Trade Status"),
                tradeDateTime: yup.date().required().label("Trade DateTime"),
                tradeMethodId: yup.number().required().label("Trade Method"),
                counterPartyId: yup.number().nullable().label("CounterParty"),
                indexCharge: yup.number().nullable(),
                sellBackCharge: yup.number().nullable(),
                counterPartySleevingPrice: yup.number().nullable(),
                notes: yup.string().nullable(),
                totalTradeVolume: yup.number().required().min(0.001).max(maxTradeValue).label("Total Trade Volume"),
                supplierOfferPrice: yup.number().required().label("Price Per Unit")
            });
            return {
                loading: true,
                validationSchema: validationSchema,
                tradeToEdit: this.trade,
                toTradeVolume: this.sellTradeSelected ? this.tradedVolume : this.trade.totalOpenVolume ?? 0,
                openVolume: (this.trade.totalOpenVolume != undefined) && (this.trade.totalTradeVolume != undefined) ? this.trade.totalOpenVolume + this.trade.totalTradeVolume : 0
            };
        },
        created() {
            this.validationSchema = this.sellTradeSelected ? this.validationSchema(this.tradedVolume) : this.validationSchema(this.trade.totalOpenVolume);
        },
        computed: {
          VolumeService() {
            return VolumeService
          },
            tradeValue(): number {
                const totalDays = this.trade.periods.reduce((totalDays, p) => totalDays + dayjs(p.startDate).daysInMonth(), 0);
                return this.tradeToEdit.supplierOfferPrice
                    ? (this.tradeToEdit.supplierOfferPrice * this.commodityValueConvert(this.toTradeVolume) * totalDays)
                    : 0;
            },
            totalTradeableVolume(): number {
                // this is the sum of all the origional volumes for a period
                let totalTradeableVolume = 0;
                for (const period of this.trade.periods) {
                    const matchingVolume = this.trade.purchaseGroups[0].volumes.find((v: { periodId: number }) => v?.periodId === period.id);
                    if (matchingVolume) {
                        totalTradeableVolume += matchingVolume.tradeableVolume;
                    }
                }
                return totalTradeableVolume;
            },
            hedgedValue(): number {
                const alreadyTraded = this.trade.totalTradeVolume ?? 0;
                const totalTradeableVolume = this.totalTradeableVolume ?? 0;
                return alreadyTraded / totalTradeableVolume * 100;
            },
            postTradeValue(): number {
                const alreadyTraded = this.trade.totalTradeVolume ?? 0;
                const totalTradeableVolume = this.totalTradeableVolume ?? 0;
                const toTradeVolume = this.toTradeVolume * this.trade.periods.length ?? 0;
                return this.sellTradeSelected ? ((alreadyTraded - toTradeVolume) / totalTradeableVolume * 100) : ((alreadyTraded + toTradeVolume) / totalTradeableVolume * 100);

            },
            fixedVolumeLabel() {
                return this.tradeToEdit.commodityTypeId === 1 ? 'Fixed volume (therms/day)' : 'Fixed volume (MW)';
            },
             openVolumeLabel() {
                return this.tradeToEdit.commodityTypeId === 1 ? 'Total open/available volume (therms/day)' : 'Total open/available volume (MW)';
            },
        },
        methods: {
            onSubmit(values: any) {
                console.log(values);

                //todo: need to add validation if trying to trade more than current open/available volume

                //Make timezone adjustment for tradeDateTime
                var offset = dayjs().utcOffset();
                this.tradeToEdit.tradeDateTime = dayjs(this.tradeToEdit.tradeDateTime).add(offset, 'minute').toDate();

                const tradeToMake = {
                    ...this.tradeToEdit,
                    totalTradeVolume: this.toTradeVolume,
                    tradeValue: this.tradeValue
                }
                this.$emit('submit:trade', tradeToMake);
            },

            unitSuffix() {
                switch (this.tradeToEdit.commodityTypeId) {
                    case 1:
                        return " pence/therm"
                    default:
                        return " pounds/MWh";
                }
            },
            commodityValueConvert(tradeValue: number) {
                switch (this.tradeToEdit.commodityTypeId) {
                    case 1:
                        return tradeValue / 100;
                    default:
                        return tradeValue * 24;
                }
            }

        }
    });
