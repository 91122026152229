import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SupplyPointList = _resolveComponent("SupplyPointList")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SupplyPointList, {
      customerId: _ctx.customerId,
      supplyPoints: _ctx.supplyPoints
    }, null, 8, ["customerId", "supplyPoints"])
  ]))
}