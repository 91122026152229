
    import { defineComponent, PropType } from 'vue';
    import { Trade, tradeService } from '@/services/TradeService';
    import * as yup from "yup";
    import { Form, Field, ErrorMessage } from 'vee-validate';
    import { Lookup } from '@/services/Types';
    import TradeDetails from '@/components/Trading/MarketTrading/TradeDetails.vue';
    import { formatDate } from '@/composables/UtilityFunctions';
    import DocumentUpload from '@/components/Common/DocumentUpload.vue';
    import { User, userService } from '@/services/UserService';
    import Textarea from 'primevue/textarea';

    interface Data {
        loading: boolean,
        validationSchema: any,
        tradeToEdit: Trade,
        tradeTypes: Lookup[],
        tradeMethods: Lookup[],
        tradeStatuses: Lookup[],
        counterParties: Lookup[],
        newDocuments: File[],
        isSaving: boolean,
        users: User[],
        showDeclineTradeDialog: boolean,
        declineTradeValidationSchema: any,
        declineTradeReason: string | null,
        declineTradeNotes: string | null,
        reasons: string[]
    }
    export default defineComponent({
        props: {
            trade: {
                type: Object as PropType<Trade>,
                required: true
            },
        },
        components: {
            Form,
            Field,
            ErrorMessage,
            TradeDetails,
            DocumentUpload,
            Textarea
        },
        data(): Data {
            const validationSchema = yup.object({
                id: yup.number().required(),
            });

            const declineTradeValidationSchema = yup.object({
                declineReason: yup.string().required().label("Reason").typeError("Please select a Reason"),
                declineAdditionalNotes: yup.string().nullable().label("Additional Notes"),
            });

            //todo: need to add validation if trying to trade more than current open/available volume
            return {
                loading: true,
                validationSchema: validationSchema,
                tradeToEdit: this.trade,
                tradeTypes: [],
                tradeMethods: [],
                tradeStatuses: [],
                counterParties: [],
                newDocuments: [],
                isSaving: false,
                users: [],
                showDeclineTradeDialog: false,
                declineTradeValidationSchema: declineTradeValidationSchema,
                declineTradeReason: null,
                declineTradeNotes: null,
                reasons: ["Reason 1", "Reason 2", "Other"]
            };
        },
        created() {
            // fetch on init
            this.fetchData();
        },
        methods: {
            async fetchData() {

                this.tradeTypes = await tradeService.getTradeTypes();
                this.tradeMethods = await tradeService.getTradeMethods();
                this.tradeStatuses = await tradeService.getTradeStatuses();
                this.counterParties = await tradeService.getCounterParties();
                this.users = await userService.getUsers();

                this.loading = false;
            },
            formatDate(dateToFormat: string) {
                return formatDate(dateToFormat, 'DD/MM/YYYY HH:mm');
            },
            formatCurrency(value: number) {
                return value.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });
            },
            onSelectFile(event: File[]) {

                this.newDocuments = event;

                this.$emit('select:acceptance-document', this.newDocuments);
            },
            async onConfirmTrade() {

                const payload = {
                    documents: this.newDocuments
                };
                await tradeService.acceptTrade(this.trade.id, payload);

                this.$emit('update:trade-status', this.trade.id);
            },
            async onApproveTrade() {
                await tradeService.approveTrade(this.trade.id);

                this.$emit('update:trade-status', this.trade.id);
            },
            declineTrade() {

                this.declineTradeReason = null;
                this.declineTradeNotes = null;
                
                this.showDeclineTradeDialog = true;
            },
            async onDeclineTrade(event: any) {


                await tradeService.declineTrade(this.trade.id, event);

                this.showDeclineTradeDialog = false;

                this.$emit('update:trade-status', this.trade.id);
            }
        },
        computed: {
            hideApprovalButton(): boolean {
                return this.newDocuments.length === 0;
            },
            tradeHasBeenApproved() {
                return this.tradeToEdit.tradeStatusId > 1
            },
            approvalUser() {
                return this.users.find(u => u.userId === this.tradeToEdit.lastModifierUserId);
            },
            createdUser() {
                return this.users.find(u => u.userId === this.tradeToEdit.creatorUserId);
            },
            tradeVolumes() {

                const periodVolume = (this.tradeToEdit.totalTradeVolume && this.tradeToEdit.totalTradeVolume) ?? 0;
                const periodVolumePrice = (this.tradeToEdit.tradeValue && this.tradeToEdit.tradeValue) ?? 0;
                console.log("this.tradeToEdit: ", this.tradeToEdit);
                console.log("this.tradeToEdit supplierOfferPrice: ", this.tradeToEdit.supplierOfferPrice);
                console.log("this.tradeToEdit totalTradeVolume: ", this.tradeToEdit.totalTradeVolume);
                console.log("this.tradeToEdit periods: ", this.tradeToEdit.periods);


                const tradeVolumes = this.tradeToEdit.periods.map(p => ({
                    period: p.name,
                    volume: periodVolume,
                    totalprice: periodVolumePrice
                }));

                return tradeVolumes;
            },
            volumeHeader() {
                return this.tradeToEdit.commodityTypeId === 1 ? 'Volume (therm/day)' : 'Volume (MW)';
            },
        }
    });
