import {useTradingApi} from "@/composables/TradingApi";
import ProductService from "@/services/ProductService";
import {useCRMApi} from "@/composables/CRMApi";


interface ReportEmbedSettings {
    embedUrl: string;
}

export interface DashboardDto {
    name: string;
    dashboardId: string;
}

export interface ReportSettingsDto {
    id: number,
    mtmPrecision: number,
    gasBenchmarkRate: number,
    electricBenchmarkRate: number,
    vatRate: number
}

export default class ReportingService {
    async getReportEmbed(dashboardId: string): Promise<ReportEmbedSettings> {

        const data = await useTradingApi('/trading/reports/GetReportEmbed?dashboardId=' + dashboardId, {
            method: "GET"
        }) as ReportEmbedSettings;
        return data ?? {};
    }

    async runTradingCalculations(): Promise<any> {
        const data = await useTradingApi('/trading/reports/customers/runjob', {
            method: "GET"
        });
        return data ?? {};
    }

    async runFullDeliveryReportCalculations(): Promise<any> {
        const data = await useTradingApi('/trading/reports/customers/fullDeliveryRunJob', {
            method: "GET"
        });
        return data ?? {};
    }

    async getDashboards(): Promise<DashboardDto[]> {
        const data = await useTradingApi('/trading/dashboard', {method: "GET"}) as DashboardDto[];
        return data;
    }

    async getReportSettings(): Promise<ReportSettingsDto> {
        const data = await useTradingApi('/trading/report-settings', {method: "GET"}) as ReportSettingsDto;
        return data;
    }

    async updateReportSettings(updatedReportSettings: ReportSettingsDto): Promise<ReportSettingsDto> {
        const newOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updatedReportSettings)
        };

        const data = await useTradingApi(`/trading/report-settings`, newOptions) as ReportSettingsDto;
        return data ?? null;
    }
}

export const reportingService = new ReportingService();
