
    import { defineComponent, PropType } from 'vue';
    import { productService, Product } from '@/services/ProductService';
    import { Lookup } from '@/services/Types';
    import * as yup from "yup";
    import { Form, Field, ErrorMessage } from 'vee-validate';

    interface Data {
        loading: boolean,
        validationSchema: any,
        product: Product,
        suppliers: Lookup[],
        utilityTypes: Lookup[],
        baseUtilityTypeId: number,
        baseUtilityTypes: Lookup[],
        contractTypes: Lookup[],
        productChargeTypes: Lookup[],
    }

    export default defineComponent({

        props: {
            editProduct: {
                type: Object as PropType<Product>,
                required: true
            },
        },
        components: {
            Form,
            Field,
            ErrorMessage,
        },
        data(): Data {

            const validationSchema = yup.object({
                utilityTypeId: yup.number().required().label("Profile Type"),
                baseUtilityTypeId: yup.number().required().label("Utility Type"),
                supplierId: yup.number().required().label("Supplier"),
                contractTypeId: yup.number().required().label("Contract Type"),
                productName: yup.string().required().label("Product Name"),
                isShapeFee: yup.boolean().required().label("Shape Fee"),
                shapeFeeTypeId: yup.number().nullable().label("Shape Fee Charge Type"),
                isNCC: yup.boolean().required().label("Non-Commodity Charge"),
                nccTypeId: yup.number().nullable().label("Non-Commodity Charge Type"),
                isCapacityCharge: yup.boolean().required().label("Capacity Charge"),
                isDailyStandingCharge: yup.boolean().required().label("Daily Standing Charge"),
           });
            return {
                loading: false,
                validationSchema: validationSchema,
                product: this.editProduct,
                suppliers: [],
                utilityTypes: [],
                baseUtilityTypeId: this.editProduct.utilityTypeId == 3 ? 3 : 2,
                baseUtilityTypes: [{ id: 3, name: "Gas" }, { id: 2, name: "Electricity" }],
                contractTypes: [],
                productChargeTypes: [],
            };
        },
        created() {
            // fetch on init
            this.fetchData();
       },
        methods: {
            onSubmit(values: any) {
                console.log("Submitting:", values);

                let editProduct = {
                    ...this.product,
                } 
                this.$emit('submit:product', editProduct);

            },
            async fetchData() {

                //this.utilityTypes = await productService.getUtilityTypes();
                this.utilityTypes = this.lookupUtilityTypes(this.baseUtilityTypeId);
                this.suppliers = await productService.getSuppliers();
                this.contractTypes = await productService.getContractTypes();
                this.productChargeTypes = await productService.getProductChargeTypes();

                this.loading = false;

            },
            onUtilityChange(event: any) {
                console.log("Utility selected:", event.value);
                this.utilityTypes = this.lookupUtilityTypes(event.value)
                if (event.value == 3) {
                    this.product.shapeFeeTypeId = 3;
                    this.product.nccTypeId = 3;
                }
                else {
                    this.product.shapeFeeTypeId = this.product.shapeFeeTypeId != 3 ? this.product.shapeFeeTypeId : 1;
                    this.product.nccTypeId = this.product.nccTypeId != 3 ? this.product.nccTypeId : 1;
                }
                //this.$emit('productSelect', event.value);
            },
            lookupUtilityTypes (utilId: number) {
                if (utilId == 2) {
                    this.product.utilityTypeId = 1;
                    return [
                        { id: 1, name: "HH" },
                        { id: 2, name: "NHH" },
                        { id: 12, name: "HH & NHH" },
                    ];
                }
                else {
                    this.product.utilityTypeId = 3;
                    return [
                        { id: 3, name: "Gas" },
                    ];
                }
            }
        }
    });
