
    import * as yup from "yup";
    import { Form, Field, ErrorMessage } from 'vee-validate';   
    import { defineComponent, PropType } from 'vue';
    import { customerService, Customer,  } from '@/services/CustomerService';
    import { Contact } from '@/services/ContactService';
    import { userService, User } from '@/services/UserService';
    import { Lookup } from '@/services/Types';
    import DocumentUpload from '@/components/Common/DocumentUpload.vue';
    import { RegEx } from '@/composables/RegularExpressions';
    import { formatDate } from '@/composables/UtilityFunctions';

    interface Data {
        loading: boolean,
        editMode: boolean,
        copyAddressEnabled: boolean,
        validationSchema: any,
        customer: Customer | null,
        statuses: Lookup[],
        companytypes: Lookup[],
        partners: Lookup[],
        siccodes: Lookup[],
        sectors: Lookup[],
        newLogo: File | null,
        logoDocuments: any[],
        newAuthority: File | null,
        authorityDocuments: any[],
        accountManagers: User[]
    }

    export default defineComponent({
        props:  {
            editCustomer: {
                type: Object as PropType<Customer>,
                required: true
            },
            contacts: {
                type: Object as PropType<Array<Contact>>,
                required: false
            }
        },
        data(): Data {
            const validationSchema = yup.object().shape({
                id: yup.number().required(),
                customerName: yup.string().required().label('Company Name'),
                primaryContactId: yup.number().nullable().label('Primary Contact'),
                tradingContactId: yup.number().nullable().label('Trading Contact'),
                companyRegistrationNumber: yup.string().nullable().label("Company Registration Number"),
                ucid: yup.string().nullable().label("UCID"),
                financialYearStartDate: yup.date().nullable().label("Financial Year Start Date"),
                customerStatusId: yup.number().required().label('Status'),
                companyTypeId: yup.number().required().label('Company Type'),
                partnerId: yup.number().required().label('Partner'),
                address1: yup.string().nullable().label("Address 1"),
                address2: yup.string().nullable().label("Address 2"),
                locality: yup.string().nullable().label("Locality"),
                town: yup.string().nullable().label("Town"),
                county: yup.string().nullable().label("County"),
                postcode: yup.string().nullable().label("Postcode").matches(RegEx.POSTCODE, { message: 'Must be a valid postcode', excludeEmptyString: true }),
                correspondaddress1: yup.string().nullable().label("Address 1"),
                correspondaddress2: yup.string().nullable().label("Address 2"),
                correspondlocality: yup.string().nullable().label("Locality"),
                correspondtown: yup.string().nullable().label("Town"),
                correspondcounty: yup.string().nullable().label("County"),
                correspondpostcode: yup.string().nullable().label("Postcode").matches(RegEx.POSTCODE, { message: 'Must be a valid postcode', excludeEmptyString: true }),
                isMicroBusiness: yup.boolean().label('Is Micro Business'),
                accountManagerId: yup.string().nullable().label('Account Manager'),
                sicCodeId: yup.number().nullable().label('SIC Code'),
                sectorId: yup.number().required().label('Sector'),
            });

            return {
                loading: true,
                editMode: false,
                copyAddressEnabled: false,
                customer: this.editCustomer,
                statuses: [],
                companytypes: [],
                partners: [],
                siccodes: [],
                sectors: [],
                validationSchema: validationSchema,
                newLogo: null,
                logoDocuments: this.editCustomer.logo ? [this.editCustomer.logo] : [],
                newAuthority: null,
                authorityDocuments: this.editCustomer.authority ? [this.editCustomer.authority] : [],
                accountManagers: [],
            };
        },
        components: {
            Form,
            Field,
            ErrorMessage,
            DocumentUpload
        },
        created() {
            // set edit mode based on customer id
            this.editMode = this.editCustomer.id > 0;

            // fetch on init
            this.fetchData();
        },
        watch: {          
            editCustomer(newValue) {

                this.customer = newValue;
                this.newLogo = null;
                this.logoDocuments = newValue.logo ? [newValue.logo] : [];
                this.newAuthority = null;
                this.authorityDocuments = newValue.authority ? [newValue.authority] : [];
            },
        },
        methods: {
            async fetchData() {

                this.companytypes = await customerService.getCompanyTypes();
                this.partners = await customerService.getPartners();
                this.siccodes = await customerService.getSICCodes();
                this.sectors = await customerService.getSectors();
                this.statuses = await customerService.getCustomerStatuses();
                this.accountManagers = await this.getAccountManagers();

                this.loading = false;

                console.log("customer data loaded");
            },
            async getSICCodes() {

                const json = await customerService.getSICCodes();
                const sortedSICCodes = json.sort((a,b) => a.id - b.id);

                return sortedSICCodes;
            },
            async getAccountManagers() {
                const allAccountManagers = await userService.getUsers();
                const validAccountManagers: User[] = allAccountManagers.filter(user => user.hidden !== true)
                return validAccountManagers;
            },
            onSubmit(values: any) {
                console.log("Submitting:", values);

                const updateCustomer = {
                    ...this.customer,
                    logo: this.newLogo,
                    authority: this.newAuthority
                };

                this.$emit('submit:customer', updateCustomer);
            },
            onSelectLogo(event: File[]) {

                this.newLogo = event.length > 0 ? event[0] : null;
                this.$emit('select:logo', this.newLogo);
            },
            onCopyAddress() {
               if (this.customer?.registeredOfficeAddress) this.customer.correspondenceAddress = this.customer?.registeredOfficeAddress;
            },
            onRemoveLogo(index: number) {
                let removedFile = this.logoDocuments.splice(index, 1)[0];
                this.logoDocuments = [...this.logoDocuments];
                this.$emit('remove:logo', removedFile);
            },
            onSelectAuthority(event: File[]) {
                this.newAuthority = event.length > 0 ? event[0] : null;
                this.$emit('select:authority', this.newAuthority);
            },
            onRemoveAuthority(index: number) {
                let removedFile = this.authorityDocuments.splice(index, 1)[0];
                this.authorityDocuments = [...this.authorityDocuments];
                this.$emit('remove:authority', removedFile);
            },
            formatDate(dateToFormat: string) {
                return formatDate(dateToFormat);
            }
        }
    });
